import React from 'react';
import { Field } from 'react-final-form';
import Multiselect from 'react-widgets/lib/Multiselect'
import classNames from 'classnames';
import { ValidationError } from '../../../components';

import css from './ReloveEditListingForm.css';

let ColorTag = ({ item }) => {
  const iconStyle = /^#[0-9A-Fa-f]{6}$/i.test(item.color) ? { backgroundColor: item.color } : { backgroundImage: item.color, backgroundSize: 'cover' };
  return (
    <div className={css.colorOption} style={iconStyle}></div>
  );
};

let ColorItem = ({item}) => {
  const iconStyle = /^#[0-9A-Fa-f]{6}$/i.test(item.color) ? { backgroundColor: item.color } : { backgroundImage: item.color, backgroundSize: 'cover' };
  return (
    <div className={css.colorItem}>
      <div className={css.colorOption} style={iconStyle}></div>
      <span style={{marginLeft: '5px'}}>{item.label}</span>
    </div>
  )
};

const ReloveColorSelectComponent = props => {
  const {
    className,
    id,
    label,
    options,
    placeholder,
    input,
    meta,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(css.color, {
    [css.colorSuccess]: valid,
    [css.colorError]: hasError,
  });
  const inputProps = { className: selectClasses, id, ...input, ...rest };

  return options ? (
    <section className={classNames(className, css.colorContainer)} >
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Multiselect
        value={input.value || []}
        containerClassName={selectClasses} 
        data={options}
        tagComponent={ColorTag}
        itemComponent={ColorItem}
        valueField="key"
        textField="label"
        placeholder={placeholder}
        inputProps={inputProps}
        messages={{
          emptyFilter: 'No encontramos ese color'
        }}
        onChange={items => {
          if(items.length <= 3)
            input.onChange(items)
        }}
      />
      <ValidationError fieldMeta={meta} />
    </section>
  ) : null;
}

const ReloveColorSelectField = props => {
  return <Field component={ReloveColorSelectComponent} {...props} />;
};

export default  ReloveColorSelectField;
