import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelectSimple,
} from '../../components'

import config from '../../config';

import css from './ProfileAddressForm.css';

class ProfileAddressFormComponent extends Component {
  constructor(props) {
    super(props);

    this.submittedValues = {};
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            invalid,
            pristine,
            formId,
            rootClassName,
            updateInProgress,
            updateProfileError,
            zipIsUnavailable,
            values,
          } = fieldRenderProps;

          // Address
          const addressLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.addressLabel',
          })
          const addressPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.addressPlaceholder',
          })
          const addressRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.addressRequired',
          });
          const addressRequired = validators.required(addressRequiredMessage);

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : zipIsUnavailable ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.zipCodeUnavailable" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || submitInProgress;

          const mexicoStates = config.states;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.address}
                    type="text"
                    id="address"
                    name="address"
                    label={addressLabel}
                    placeholder={addressPlaceholder}
                    validate={addressRequired}
                  />
                  <FieldTextInput
                    className={css.addressExtNumber}
                    type="text"
                    id="addressExtNumber"
                    name="addressExtNumber"
                    label="Número Exterior"
                    placeholder="Número Exterior"
                    validate={addressRequired}
                  />
                  <FieldTextInput
                    className={css.addressIntNumber}
                    type="text"
                    id="addressIntNumber"
                    name="addressIntNumber"
                    label="Número Interior"
                    placeholder="Número Interior"
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.addressNeighborhood}
                    type="text"
                    id="addressNeighborhood"
                    name="addressNeighborhood"
                    label="Colonia"
                    placeholder="Colonia"
                    validate={addressRequired}
                  />
                  <FieldTextInput
                    className={css.addressZip}
                    type="text"
                    id="addressZip"
                    name="addressZip"
                    label="C.P."
                    placeholder="C.P."
                    validate={addressRequired}
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldSelectSimple
                    className={css.addressState}
                    id="addressState"
                    name="addressState"
                    label="Estado"
                    validate={addressRequired}
                  >
                    <option disabled value="">
                      Estado
                    </option>
                    {mexicoStates.map(state => {
                      return (
                        <option key={state.clave} value={state.clave}>
                          {state.nombre}
                        </option>
                      );
                    })}
                  </FieldSelectSimple>
                  <FieldTextInput
                  className={css.addressCity}
                    type="text"
                    id="addressCity"
                    name="addressCity"
                    label="Municipio"
                    placeholder="Municipio"
                    validate={addressRequired}
                  />
                </div>
              </div>

              <div className={css.actionsContainer}>
                {submitError}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit && !submitError}
                >
                  <FormattedMessage id="ProfileAddressForm.saveAddress" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileAddressFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
};

ProfileAddressFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileAddressForm = compose(injectIntl)(ProfileAddressFormComponent);

ProfileAddressForm.displayName = 'ProfileAddressForm';

export default ProfileAddressForm;
