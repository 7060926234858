import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Versión vigente: Enero 13, 2021</p>

      <p>
        Conforme a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión
        de los Particulares, su Reglamento y demás disposiciones aplicables de la legislación
        mexicana vigente en materia de Protección de Datos, GLOSET pone a disposición del USUARIO
        del sitio web <a href="http://www.gloset.mx/">www.gloset.mx</a> el presente AVISO DE PRIVACIDAD
        respecto al tratamiento de DATOS PERSONALES.
      </p>

      <p>
        Tras la lectura del AVISO DE PRIVACIDAD, si el USUARIO continúa utilizando el SITIO WEB,
        estará manifestando la aceptación expresa y voluntaria respecto al tratamiento de sus
        DATOS PERSONALES. Si el USUARIO está en desacuerdo con el AVISO DE PRIVACIDAD deberá salir
        del SITIO WEB.
      </p>

      <h2>I. DEFINICIONES</h2>
      <p>
        <strong>AVISO DE PRIVACIDAD</strong>: el presente documento.
      </p>
      <p>
        <strong>GLOSET</strong>: GLOSET
      </p>
      <p>
        <strong>COOKIES</strong>: Archivos de texto que son descargados automáticamente y almacenados
        en el disco duro del equipo de cómputo del USUARIO al navegar en el SITIO WEB y que permiten
        recordar al servidor de Internet algunos datos sobre el USUARIO, entre ellos, sus preferencias
        para la visualización de las páginas en ese servidor, nombre y contraseña.
      </p>
      <p>
        <strong>DATOS PERSONALES</strong>: datos de identificación del USUARIO, así como los que éste
        ingrese voluntariamente cuando le sean requeridos por el SITIO WEB.
      </p>
      <p>
        <strong>LEY</strong>: la Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares.
      </p>
      <p>
        <strong>SITIO WEB</strong>: <a href="http://www.gloset.mx/">www.gloset.mx</a>
      </p>
      <p>
        <strong>USUARIO</strong>: persona física identificable que acceda al SITIO WEB y comunique sus
        datos personales a través del mismo o de los servicios disponibles.
      </p>
      <p>
        <strong>WEB BEACONS</strong>: Imágenes insertadas en el SITIO WEB que pueden ser utilizadas para
        monitorear el comportamiento del USUARIO, como almacenar información sobre la dirección IP del
        mismo, el tiempo de interacción el SITIO WEB y el tipo de navegador utilizado, entre otros.
      </p>

      <h2>II. IDENTIDAD Y DOMICILIO DE GLOSET</h2>
      <p>
        GLOSET es responsable del tratamiento de los DATOS PERSONALES del USUARIO a partir del momento
        en que éste voluntariamente se los comunica.
      </p>

      <h2>III. FINALIDAD DE RECABAR Y UTILIZAR LOS DATOS PERSONALES</h2>
      <p>
        Los DATOS PERSONALES solicitados y recabados a través del SITIO WEB serán utilizados para las
        siguientes finalidades necesarias del servicio solicitado por el USUARIO:
      </p>
      <ul>
        <li>Publicidad y promociones.</li>
        <li>Pagos o transferencias electrónicas derivadas de los servicios solicitados.</li>
        <li>Encuestas de servicio y calidad.</li>
        <li>Contenido de la base de datos de control interno.</li>
        <li>Seguimiento para el proceso de venta.</li>
        <li>Entre otras actividades relacionadas.</li>
      </ul>
      <p>
        Adicionalmente, los DATOS PERSONALES del USUARIO serán utilizados para brindar una mejor
        atención en cuanto a la oferta, calidad, cambios, dar cumplimiento a obligaciones contraídas
        con clientes o proveedores y en general informar de los productos y servicios de GLOSET.
      </p>
      <p>
        En caso de que el USUARIO no desee que los DATOS PERSONALES sean tratados para los fines adicionales,
        deberá solicitar la limitación de su uso vía correo electrónico de CONTACTO.
      </p>

      <h2>IV. FORMA DE RECABAR LOS DATOS PERSONALES</h2>
      <p>
        Para las finalidades señaladas en el punto III del presente AVISO DE PRIVACIDAD, los DATOS
        PERSONALES pueden recabarse cuando el USUARIO los proporciones directamente, o bien utilice
        los servicios en línea contenidos en el SITIO WEB o a través de otras fuentes permitidas por
        la LEY.  Los DATOS PERSONALES recabados pudieran ser:
      </p>
      <ul>
        <li>Nombre.</li>
        <li>Domicilio.</li>
        <li>Empresa donde labora o actividad preponderante.</li>
        <li>Puestos.</li>
        <li>Teléfonos.</li>
        <li>Correo electrónico.</li>
      </ul>

      <h2>V. DATOS PERSONALES SENSIBLES</h2>
      <p>
        En aras de dar cumplimiento al punto III del AVISO DE PRIVACIDAD, es probable que además se
        recaben diversos DATOS SENSIBLES del USUARIO tales como información financiera, salud, planeación
        y competencia.
      </p>
      <p>
        GLOSET se compromete a que los mismos serán tratados bajo las más estrictas medidas de seguridad
        que garanticen su confidencialidad.
      </p>

      <h2>VI. LIMITACIÓN DE DATOS PERSONALES</h2>
      <p>
        El USUARIO puede limitar los mensajes promocionales o publicitarios en caso de no desear recibirlos.
        Para ello, el USUARIO deberá enviar un correo electrónico a <a href="mailto:ayuda@gloset.mx">ayuda@gloset.mx</a> especificando
        el tipo de mensajes que no desea recibir
      </p>

      <h2>VII. DERECHOS ARCO</h2>
      <p>
        El USUARIO tiene derecho a acceder, rectificar y cancelar los DATOS PERSONALES, así como a oponerse
        al tratamiento de los mismos o bien, revocar el consentimiento que para tal fin haya otorgado
        conforme a lo establecido la parte introductoria del AVISO DE PRIVACIDAD.
      </p>
      <p>
        El USUARIO puede realizar su petición enviando un correo electrónico a <a href="mailto:ayuda@gloset.mx">ayuda@gloset.mx</a>. En
        la petición se deberá especificar el derecho ARCO (<u>a</u>cceder, <u>r</u>ectificar, <u>c</u>ancelar u <u>o</u>posición) requerido.
      </p>

      <h2>VIII. TRANSFERENCIA DE DATOS PERSONALES</h2>
      <p>
        GLOSET se compromete a no transferir su información a terceros, salvo las excepciones previstas en el artículo
        37 de la LEY y en su caso, realizar las citadas transferencias respetando las condiciones especificadas en la
        misma.
      </p>
      <p>
        Artículo 37.- Las transferencias nacionales o internacionales de datos podrán llevarse a cabo sin el consentimiento
        del titular cuando se dé alguno de los siguientes supuestos:
      </p>
      <ol style={{listStyleType: 'upper-roman'}}>
        <li>
          Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte;
        </li>
        <li>
          Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de
          asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios;
        </li>
        <li>
          Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el
          control común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo del
          responsable que opere bajo los mismos procesos y políticas internas;
        </li>
        <li>
          Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés
          del titular, por el responsable y un tercero;
        </li>
        <li>
          Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público,
          o para la procuración o administración de justicia;
        </li>
        <li>
          Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un
          proceso judicial, y
        </li>
        <li>
          Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre
          el responsable y el titular.
        </li>
      </ol>
      <p>
        Si el USUARIO no manifiesta oposición para que los DATOS PERSONALES sean transferidos, <u>se entenderá que
        ha otorgado su consentimiento</u> para ello.
      </p>

      <h2>IX. ACTUALIZACIÓN Y MODIFICACIÓN DEL AVISO DE PRIVACIDAD</h2>
      <p>
        GLOSET se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al AVISO DE
        PRIVACIDAD, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la
        prestación u ofrecimiento de los servicios o productos.
      </p>
      <p>
        Las actualizaciones estarán a disposición del USUARIO a través del presente AVISO DE PRIVACIDAD o vía correo
        electrónico al último que haya proporcionado directamente en el SITIO WEB.
      </p>

      <h2>X. COOKIES Y WEB BEACONS</h2>
      <p>
        Le informamos que el SITIO WEB utiliza COOKIES y WEB BEACONS para obtener información del USUARIO tal y como:
      </p>
      <ul>
        <li>Tipo de navegador y sistema operativo.</li>
        <li>Las páginas de Internet previamente visitadas por el USUARIO.</li>
        <li>Los vínculos que el USUARIO sigue.</li>
        <li>La dirección IP.</li>
        <li>Entre otras relacionadas.</li>
      </ul>

      <h2>QUEJA POR USO INDEBIDO DE DATOS PERSONALES</h2>
      <p>
        Si el USUARIO considera que su derecho de protección de DATOS PERSONALES ha sido lesionado por alguna conducta
        o presume que en el tratamiento de los DATOS PERSONALES existe alguna violación a las disposiciones previstas
        en la LEY, el USUARIO podrá interponer la queja o denuncia correspondiente ante el IFAI
      </p>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
