import React from 'react'
import {Modal} from '../../components';
import { ClabeForm } from '../../forms';

import css from './TransactionPanel.css'

const ClabeSection = props => {

  const {
    title,
    authorDisplayName,
    isClabeModalOpen,
    onCloseClabeModal,
    updateClabeError,
    updateClabeInProgress,
    onSubmitClabe,
    onManageDisableScrolling,
    isOwnListing
  } = props;

  return (
    <div  className={isOwnListing ? css.iconsHidden : css.icons}>
        <Modal
        id="ListingPage.clabe"
        contentClassName={css.enquiryModalContent}
        isOpen={isClabeModalOpen}
        onClose={onCloseClabeModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ClabeForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          updateClabeError={updateClabeError}
          onSubmit={onSubmitClabe}
          inProgress={updateClabeInProgress}
        />
      </Modal>
    </div>
  )
}

export default ClabeSection