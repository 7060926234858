import React from 'react';
import cls from './skeleton.css';

const Card = () => {
  return (
    <div className={cls["skeleton-card"]}>
      <div className={cls["skeleton-image"]}></div>
      <div className={cls["skeleton-text"]}></div>
    </div>
  );
};

export default Card;
