/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './TopbarMobileMenu.css';
import { ReloveIconFavorite } from '../../components';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUser,
    notificationCount,
    messageCount,
    onLogout,
  } = props;
  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const messageCountBadge =
    messageCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={messageCount} />
    ) : null;
  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };


  const privateData = user ? user.attributes.profile.privateData : {};
  const hasNewNotifs = privateData && privateData.notifications && Array.isArray(privateData.notifications) ? privateData.notifications.reduce((carr, element) => !element.isRead ? carr + 1 : carr, 0) : 0;

  const notificationsDot = hasNewNotifs ? (
    <NotificationBadge className={css.notificationBadge} count={hasNewNotifs} />
  ) : null;

  return (
    <div className={css.root}>
      <div className={css.containerHeaderMenu}>
        <AvatarLarge className={css.avatar} user={currentUser} renderSizes={'73px'} />
        <div className={css.containerTextHeader}>
          <span className={css.greeting}>
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </span>
          {!!user.id ? (
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ProfilePage'))}
              name="ProfilePage"
              params={{ id: user.id.uuid }}
            >
              <FormattedMessage id="TopbarMobileMenu.profileLink" />
            </NamedLink>
          ) : null}
        </div>

      </div>


      <div className={css.content}>


        <hr className={css.separator} />

        <NamedLink
          className={classNames(css.textColorDark, currentPageClass('NotificationsPage'))}
          name="NotificationsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.notificationsLink" />
          {notificationsDot}
        </NamedLink>

        <NamedLink
          className={classNames( css.textColorDark)}
          name="MessagePage"
          params={{ tab: 'messages' }}
        >
          <FormattedMessage id="TopbarMobileMenu.messagesLink" />
          {messageCountBadge}
        </NamedLink>

        <NamedLink
          className={classNames(css.textColorDark, currentPageClass('MyFavoritesPage'))}
          name="MyFavoritesPage"
        >
          <FormattedMessage id="TopbarMobileMenu.favoritesLink" />
          <ReloveIconFavorite className={css.favoritesIcon} />
        </NamedLink>

        <NamedLink
          className={css.textColorDark}
          name="ReferralProgram"
        >
          <FormattedMessage id="TopbarMobileMenu.referralLink" /> <span style={{color:'#FD7C67'}}>Gana $</span>
        </NamedLink>

        <hr className={css.separator} />


        <NamedLink
          className={classNames( css.textColorDark, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={css.textColorDark}
          name="InboxPage"
          params={{ tab: 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.ordersLink" />
        </NamedLink>

        <NamedLink
          className={css.textColorDark}
          name="InboxPage"
          params={{ tab: 'sales' }}
        >
          <FormattedMessage id="TopbarMobileMenu.salesLink" />
          {notificationCountBadge}
        </NamedLink>

        <hr className={css.separator} />


        <NamedLink
          className={classNames(css.textColorDark, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>



        <NamedLink
          className={css.textColorDark}
          name="MyBalance"
        >
          <FormattedMessage id="TopbarMobileMenu.balanceLink" />
        </NamedLink>

        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, messageCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  messageCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
