export class LoggingAnalyticsHandler {
  trackPageView(url) {
    (process.env.NODE_ENV === 'development') && console.log('Analytics page view:', url)
  }
  trackSearchEvent(searchParams) {
    (process.env.NODE_ENV === 'development') && console.log('Analytics search event:', searchParams);
  }
}

export class GoogleAnalyticsHandler {
  constructor(ga) {
    if (typeof ga !== 'function') {
      throw new Error('Variable `ga` missing for Google Analytics');
    }
    this.ga = ga;
  }
  trackPageView(url) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
    this.ga('set', 'page', url);

    if (window.location.href.indexOf('/reset-password') === -1) {
      this.ga('send', 'pageview');
    }
  }
  trackSearchEvent(searchParams) {
    const searchType = searchParams.keywords ? 'keywords' : 'category';
    const searchTerm = searchType === 'keywords' ? searchParams.keywords : searchParams.pub_category;
    this.ga('send', 'event', 'search', searchType, searchTerm);
  }
}

export class GoogleTagManagerHandler {
  constructor(dataLayer) {
    if (!Array.isArray(dataLayer)) {
      throw new Error('Variable `dataLayer` missing for Google Tag Manager');
    }
    this.dataLayer = dataLayer;
  }
  trackPageView(url) {
    this.dataLayer.push({ 'page': url })
  }
  trackSearchEvent(searchParams) {
    const { keywords, pub_category, fields, include, page, perPage, ...otherParams } = searchParams;
    const searchType = keywords ? 'keywords' : 'category';
    const searchTerm = searchType === 'keywords' ? keywords : pub_category;
    this.dataLayer.push({ event: 'search', searchType, searchTerm, ...otherParams });
  }
}

export class FacebookPixelHandler {
  constructor(fbq) {
    if (typeof fbq !== 'function') {
      throw new Error('Variable `fbq` missing for Facebook Pixel');
    }
    this.fbq = fbq;
  }
  trackPageView(url) {
    if (window.location.href.indexOf('/reset-password') === -1) {
      this.fbq('track', 'PageView');
    } 
  }
}
