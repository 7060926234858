import React, { useState } from 'react';
import { FieldSelect } from '../../../components';
import classnames from 'classnames';
import css from './ReloveEditListingForm.css';

const ReloveFeatureSelectField = props => {
  const {
    name,
    id,
    label,
    placeholder,
    options,
    className,
    valueField = 'key',
    selectProps,
    defaultValue,
    errorMessage,
    isUpdate
  } = props;

  const [touched, setTouched] = useState(false);

  const handleChange = event => {
    if (!touched) setTouched(true);
    if (selectProps && selectProps.onChange) selectProps.onChange(event);
  };
  const showCustomError = (isUpdate || touched) && errorMessage;

  return options ? (
    <div className={classnames(className, css.comboSelect)}>
      <label htmlFor={id}>{label}</label>
      <FieldSelect
        name={name}
        id={id}
        defaultValue={defaultValue}
        options={options}
        valueField={valueField}
        selectProps={{ ...selectProps, onChange: handleChange }}
      >
        <option value="" className={css.placeholderOption}>
          {placeholder}
        </option>
        {options.map(c => (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        ))}
      </FieldSelect>
      {showCustomError && <div className={css.validationError}>{errorMessage}</div>}
    </div>
  ) : null;
};

export default ReloveFeatureSelectField;
