import classNames from 'classnames';
import { array, bool, func, object, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Page, AuthenticationPageModalWrapper, LayoutWrapperTopbar, LayoutWrapperMain  } from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import { searchListings, setActiveListing } from './SearchPage.duck';
import {
  createSearchResultSchema, pickSearchParamsOnly,
  validURLParamsForExtendedData
} from './SearchPage.helpers';


// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 50;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
      authContainerOpen: false,
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.handleLoginRequest = this.handleLoginRequest.bind(this);
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  handleLoginRequest(listing){
    this.setState({authContainerOpen: true})
  }


  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      handleSearchListings,
      searchInProgress,
      appendInProgress,
      searchListingsError,
      searchParams,
      onActivateListing,
      activeListingId,
      // csvReport,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { page, ...searchInURL } = parse(location.search);

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const keywords = searchInURL ? searchInURL : {};
    const { title, description, schema } = createSearchResultSchema(listings, keywords, intl);




    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const loadMoreListings = (params) => {
      let loadMoreParams ={
        ...params,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images', 'author.profileImage'],
        'fields.listing': ['title', 'price', 'publicData', 'metadata'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', ],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        'limit.images': 1,
      };

      return handleSearchListings(loadMoreParams);
    };

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={topbarClasses}
            currentPage="SearchPage"
            currentSearchParams={urlQueryParams}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <div className={css.container}>
          <MainPanel
            urlQueryParams={validQueryParams}
            listings={listings}
            searchInProgress={searchInProgress}
            appendInProgress={appendInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            pagination={pagination}
            loadListingsHandler={loadMoreListings}
            activeListingId={activeListingId}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            history={history}
            handleLoginRequest={this.handleLoginRequest}
          />
          <AuthenticationPageModalWrapper
            authContainerOpen={this.state.authContainerOpen}
            closeAuthModal={() => this.setState({authContainerOpen: false})}
          //  onLoginCompleted={() => {this.handleLoginRedirecting()}}
          //  requiresRedirection={this.state.requiresRedirection}
            // extraAction={() => this.triggerExtraAction(this.state.neededAction)}
            // requiresExtraAction={this.state.requiresExtraAction}
          />
        </div>


        </LayoutWrapperMain>

      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    appendInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    listings: pageListings,
    // csvReport:csvReport,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    appendInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  handleSearchListings: params => dispatch(searchListings(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const { page = 1, ...rest } = queryParams;
  return searchListings({
    ...rest,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images', 'author.profileImage'],
    'fields.listing': ['title', 'price', 'description', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', ],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    'limit.images': 1,
  });
};

export default SearchPage;
