import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconPhotos.css';

const ReloveIconPhotos = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill="#494c4e" d="M20.79 5H17s-1.71-1.7-1.71-1.71c-.19-.18-.43-.29-.71-.29H9.42c-.26 0-.5.1-.68.27L7 5H6c0-.55-.45-1-1-1s-1 .45-1 1h-.79C1.44 5 0 6.44 0 8.21v9.58C0 19.56 1.44 21 3.21 21h17.58c1.77 0 3.21-1.44 3.21-3.21V8.21C24 6.44 22.56 5 20.79 5zM22 17.79c0 .67-.54 1.21-1.21 1.21H3.21C2.54 19 2 18.46 2 17.79V13h5c0 2.76 2.24 5 5 5s5-2.24 5-5h5v4.79zM9 13c0-1.65 1.35-3 3-3s3 1.35 3 3-1.35 3-3 3-3-1.35-3-3zm13-2h-5.41c-.78-1.76-2.54-3-4.59-3s-3.81 1.24-4.59 3H2V8.21C2 7.54 2.54 7 3.21 7H7c.53 0 1.04-.21 1.41-.58 0 0 .92-.92 1.43-1.42h4.33l1.42 1.41c.37.38.88.59 1.41.59h3.79c.67 0 1.21.54 1.21 1.21V11z"/>
    </svg>
  );
};

ReloveIconPhotos.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconPhotos.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconPhotos;
