import React, { useMemo } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { Modal, Button, InlineTextButton, IconClose, ReloveIconWarning, IconArrowHead, PaginationSteps } from '../../components';
import classnames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';


import css from './EarningsSection.css'
import useToggle from '../../hooks/useToggle';

// Define the formatting options as a constant
const NUMBER_FORMAT_SETTINGS = {
  locale: 'es-MX',
  options: {
    maximumFractionDigits: 2,
  }
};

const InformationModal = ({ isOpen, onClose, text, id, onManageDisableScrolling }) => {
  return <Modal
    id={id}
    isOpen={isOpen}
    onClose={onClose}
    onManageDisableScrolling={onManageDisableScrolling}
    closeButtonClassName={css.closeButtonClassName}
    isOnlyCloseIcon={true}
    usePortal
    containerClassName={css.containerModal}
  >
    <div className={css.containerModalInformation}>
      <ReloveIconWarning size={'25px'} />
      <p className={css.textInformation}>
        {text}
      </p>
    </div>
  </Modal>
}


const GeneratePayoutModal = ({isGeneratePayoutModal, setIsGeneratePayoutModal, onManageDisableScrolling,
  onHandlePayoutRequest,initiatePayoutInProgess, stripeAccountDataExternalAccount})=>{

    const onClose= () => { setIsGeneratePayoutModal(false) }

    const handleOnHandlePayoutRequest = async () => {
      try {
        // Call the parent function to handle the payout request
        await onHandlePayoutRequest();
    
        // Close the modal only after the payout request completes successfully
        onClose();
      } catch (error) {
        console.error("Payout failed:", error);
        // Optionally, you could display an error message to the user in the modal
      }
    };
    

    
return  (
<Modal
   id="GeneratePayoutModal"
   isOpen={isGeneratePayoutModal}
   onClose={onClose}
   onManageDisableScrolling={onManageDisableScrolling}
   isOnlyCloseIcon={true}
   closeButtonClassName={css.closeButtonClassName}

 >
   <div className={css.containerModalPayout}>
     <div className={css.payoutText}>{initiatePayoutInProgess?'Tranferencia en proceso' : <FormattedMessage id="EarningsSection.payoutMessage" values={{ last4: stripeAccountDataExternalAccount?.last4 }} />}</div>
     <Button
       className={classnames(css.payoutButton, 'cursor')}
       onClick={handleOnHandlePayoutRequest}
       inProgress={initiatePayoutInProgess}
       disabled={initiatePayoutInProgess}
     >
       Continuar
     </Button>
   </div>
 </Modal>)
}


const EarningsSection = props => {

  const [isGeneratePayoutModal, setIsGeneratePayoutModal] = React.useState(false);
  const [ispayoutHistoryContainer, setIspayoutHistoryContainer] = React.useState(false);
  const [openCreditInformation, toggleCreditInformation] = useToggle();
  const [openPayoutRedimibles, toggleOpenPayoutRedimibles] = useToggle();
  const [currentPage, setCurrentPage] = React.useState(1);
  const {
    userEarnings,
    userCredits,
    pendingCredits,
    onManageDisableScrolling,
    userCounter,
    userStripeAccountMaybe,
    stripeAccountDataExternalAccount,
    onHandlePayoutRequest,
    depositHistoryData = [],
    initiatePayoutInProgess,
    initiatePayoutError,
    totalPages,
    onLoadNewPage
  } = props;

  const [animatedCounter, setAnimatedCounter] = React.useState(0);


  React.useEffect(() => {
    let interval;

    if (props.userCounter !== null) {
      // Determine the increment step based on userCounter
      const incrementStep = Math.max(1, Math.floor(props.userCounter / 100));
      const intervalDuration = Math.max(10, 1000 / props.userCounter); // Adjust the speed

      interval = setInterval(() => {
        setAnimatedCounter(prevCounter => {
          const nextCounter = prevCounter + incrementStep;
          if (nextCounter >= props.userCounter) {
            clearInterval(interval);
            return props.userCounter;
          }
          return nextCounter;
        });
      }, intervalDuration);
    }

    // Clear interval on component unmount
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [props.userCounter]);


  const clickEvent = () => {
    window.location.href = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/referral`;
  }

  const loadNewPage = (newPage) => {
    onLoadNewPage(currentPage, newPage)

    setCurrentPage(newPage)
  }

  const closeBtn = ispayoutHistoryContainer ? (
    <InlineTextButton
      onClick={() => { setIspayoutHistoryContainer(false) }}
      rootClassName={css.closeButtonClasses}
    >
      <IconArrowHead rootClassName={css.closeIcon} direction='left' size={'small'} />
      <span className={css.closeText}>
        {<FormattedMessage id="Modal.closeMenu" />}
      </span>

    </InlineTextButton>
  ) : null;




  const EarningsSection = !ispayoutHistoryContainer && (
    <div>
      <div className={css.balanceContainer}>
        <div
          className={css.balanceRow}
        >
          <span className={classnames(css.containerWarning, 'cursor')} onClick={() => toggleCreditInformation()} >
            Créditos Gloset <ReloveIconWarning />
          </span>
          <span>
            ${(userCredits / 100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}
          </span>
        </div>
        <div
          className={css.balanceRow}
        >
          <span>
            Ganancias Pendientes
          </span>
          <span>
            ${(pendingCredits / 100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}
          </span>
        </div>
        <div
          className={css.balanceRow}
        >
          <span className={classnames(css.containerWarning,'cursor')} onClick={()=> toggleOpenPayoutRedimibles()} >
            Ganancias Redimibles <ReloveIconWarning />
          </span>
          <span>
            ${(userEarnings / 100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}
          </span>
        </div>
      </div>
      <button
        className={css.transferBalanceButton}
        onClick={() => { setIsGeneratePayoutModal(true) }}
        disabled={!stripeAccountDataExternalAccount || userEarnings === 0 }
      >
        Transferir a mi cuenta
      </button>
    </div>
  )

  const statusLabel = {
    pending: 'En Transito',
    paid: ' Depositado',
    failed: '      Falló'
  }

  const optionsdad = [
    'En Transito',
    ' Depositado',
    '      Falló'
  ]

 

  const payoutHistoryContainer = ispayoutHistoryContainer && (
    <div>
      {closeBtn}
      <div>
        <h1 className={css.payoutHistoryTitle}>Historial de depósitos </h1>
      </div>
      <div className={css.payoutHistoryDataContainer}>
        {depositHistoryData.map(payout => {
          const date = new Date(payout.created * 1000); // Remove * 1000 if your timestamp is already in milliseconds
          // Format the date as DD-MM-YYYY
          const formattedDate = [
            date.getDate().toString().padStart(2, '0'),
            (date.getMonth() + 1).toString().padStart(2, '0'), // +1 because months are 0-indexed
            date.getFullYear()
          ].join('-');

          return (
            <div className={css.payoutDataRow}>
              <span className={css.textHistoryData}>{formattedDate}</span>
              <span className={css.textHistoryData}>${(payout.amount/100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}</span>
              <span className={classnames(css.textHistoryData, css[`textStatus-${payout.status}`])}>{statusLabel[payout.status]}</span>
            </div>
          )
        })}
      </div>
      <div >
        <PaginationSteps  
          className={css.containerPagination}
          currentPage={currentPage}
          totalCount={totalPages}
          onPageChange={page => loadNewPage(page)} 
        />
      </div>
    </div>
  )

  const clickableContentSection = !ispayoutHistoryContainer && (
    <>
      <div className={css.historyAndPayoutContainer}>
        <div className={css.referredUsersContainer}>
          <span>Usuarias que han usado mi código</span>
          <span>{animatedCounter}</span>
        </div>
        <div className={css.payoutDataContainer} onClick={() => setIspayoutHistoryContainer(true)}>
          <div>Historial de depósitos</div>
          <KeyboardArrowRightIcon />
        </div>
      </div>
      <div className={css.referralContainer} onClick={() => clickEvent()}>
        <div >INVITA AMIGAS, <span style={{ color: '#FD7C67' }}>GANA DINERO</span></div>
        <KeyboardArrowRightIcon />
      </div>
    </>
  )
  return (
    <div style={{ fontFamily: 'CircularStd' }} >
      {EarningsSection}
      {clickableContentSection}
      {payoutHistoryContainer}
      <GeneratePayoutModal onManageDisableScrolling={onManageDisableScrolling} {...{isGeneratePayoutModal, setIsGeneratePayoutModal,
       onHandlePayoutRequest, initiatePayoutInProgess, stripeAccountDataExternalAccount
      }}/>
      <InformationModal
        id={'CreditInformation'}
        isOpen={openCreditInformation}
        onClose={toggleCreditInformation}
        text={<FormattedMessage id="EarningsSection.creditInformation" />}
        onManageDisableScrolling={onManageDisableScrolling}
      />

      <InformationModal
        id={'payoutRedimible'}
        isOpen={openPayoutRedimibles}
        onClose={toggleOpenPayoutRedimibles}
        text={<FormattedMessage id="EarningsSection.payoutRedimibles" />}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
}

export default EarningsSection;