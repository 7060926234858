import { createReferralCodeLink, updateUser, createUsername } from '../../util/api'
import { fetchCurrentUser } from '../../ducks/user.duck';
// ================ Action types ================ //
export const GENERATE_REFERRAL_CODE_REQUEST = 'app/BalancePage/GENERATE_REFERRAL_CODE_REQUEST';
export const GENERATE_REFERRAL_CODE_SUCCESS = 'app/BalancePage/GENERATE_REFERRAL_CODE_SUCCESS';
export const GENERATE_REFERRAL_CODE_ERROR = 'app/BalancePage/GENERATE_REFERRAL_CODE_ERROR';

export const GENERATE_NEW_USER_REQUEST = 'app/BalancePage/GENERATE_NEW_USER_REQUEST';
export const GENERATE_NEW_USER_SUCCESS = 'app/BalancePage/GENERATE_NEW_USER_SUCCESS';
export const GENERATE_NEW_USER_ERROR = 'app/BalancePage/GENERATE_NEW_USER_ERROR';
export const GENERATE_NEW_USER_TAKEN_ERROR = 'app/BalancePage/GENERATE_NEW_USER_TAKEN_ERROR';


// ================ Reducers ================ //
const initialState = {
	newUserName: null,
	generateReferralCodeInProgess: false,
	generateReferralCodeError: null,

	generateNewUserInProgress: false,
	generateNewUserTakenError: false,
	generateNewUserError: null,

	generateNewUserUnavailable: false,
};


export default function balancePageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
			case GENERATE_REFERRAL_CODE_REQUEST:
				return {...state, generateReferralCodeInProgess: true};
			case GENERATE_REFERRAL_CODE_SUCCESS:
				return {...state, generateReferralCodeInProgess: false};
			case GENERATE_REFERRAL_CODE_ERROR:
				return {...state, generateReferralCodeInProgess: false, generateReferralCodeError: payload};

			case GENERATE_NEW_USER_REQUEST:
				return {...state, generateNewUserInProgress: true};
			case GENERATE_NEW_USER_SUCCESS:
				return {...state, generateNewUserInProgress: false};
			case GENERATE_NEW_USER_ERROR:
				return {...state, generateNewUserInProgress: false, generateNewUserError: payload};
			case GENERATE_NEW_USER_TAKEN_ERROR:
				return {...state, generateNewUserInProgress: false, generateNewUserTakenError: true};
			
				default:
				return state;
    }
}


// ================ Action creators ================ //
export const generateReferralCodeRequest = userId => ({
	type: GENERATE_REFERRAL_CODE_REQUEST,
});

export const generateReferralCodeSuccess = () => ({
	type: GENERATE_REFERRAL_CODE_SUCCESS,
});

export const generateReferralCodeError = e => ({
	type: GENERATE_REFERRAL_CODE_ERROR,
	error: true,
	payload: e,
});

export const generateNewUserRequest = () => ({
	type: GENERATE_NEW_USER_REQUEST,
});

export const generateNewUserSuccess = () => ({
	type: GENERATE_NEW_USER_SUCCESS,
});

export const generateNewUserError = e => ({
	type: GENERATE_NEW_USER_ERROR,
	error: true,
	payload: e,
});

export const generateNewUserTakenError = e => ({
	type: GENERATE_NEW_USER_TAKEN_ERROR,
	error: true
})
// ================ Thunks ================ //

export const generateReferralCodeLink = (data) => async (dispatch, getState, sdk) => {
    const { referralCode, userId } = data

	dispatch(generateReferralCodeRequest());
    return createReferralCodeLink({ referralCode }).then(referralCodeLink=> {
        return updateUser({ id: userId, privateData: { referralCodeLink,  referralCode: referralCode.toLowerCase()} }).then(() => {
			dispatch(generateReferralCodeSuccess());
			dispatch(fetchCurrentUser())
			return referralCodeLink
        })
    }).catch(updateUserError => {
		dispatch(generateReferralCodeError(updateUserError));
    }).catch(createReferralCodeError => {
		dispatch(generateReferralCodeError(createReferralCodeError));	
    })
}

export const generateNewUser = (userId, username) => async (dispatch, getState, sdk) => {
	dispatch(generateNewUserRequest());
	return createUsername({userId, username}).then(createUsernameRes => {
		if(createUsernameRes.unavailable){
			dispatch(generateNewUserTakenError());
		}else{
			dispatch(generateNewUserSuccess());

		}
		return createUsernameRes
	}).catch(e => {
		dispatch(generateNewUserError(e));
	})
}