import React from 'react';

import { Form as FinalForm } from 'react-final-form';

import { FieldRadioButton,PrimaryButton } from '..';

import ShippingOptionsMessaging from './ShippingOptionsMessaging';

import css from './ShippingOptions.css';

const  ShippingOptions = props => {
  // const [isExpressDeliveryChosen, setIsExpressDeliveryChosen] = React.useState(false);

  return(
  <FinalForm
  {...props}
  render={fieldRenderProps => {
    const {
      onShippingPriceChange, 
      onNewShippingMethodSelection,
      listing,
      onSubmitEnquiry,
      sendEnquiryInProgress,
      sendEnquiryError,
      onContactUser,
      originalShippingPrice,
      isEnquiryModalOpen,
      setIsEnquiryModalOpen,
      handleSubmit,
      isShippingOptionBeingChanged,
      isExpressDeliveryChosen,
      setIsExpressDeliveryChosen
    } = fieldRenderProps;

    const enquirySubmission = (values) =>{
      setIsExpressDeliveryChosen(false);
      onSubmitEnquiry(values)
    }

    const chooseShippingOption = (option, price) => {
      onShippingPriceChange(option, price);
      onNewShippingMethodSelection(option)
    }

    return (
      <form className={css.heading} onSubmit={handleSubmit}>
      <div className={css.parentContainer}>
        <h1 className={css.title}>Método de Envío</h1>
        <FieldRadioButton
          id={`normalDelivery`}
          name="deliveryOption"
          key={`normalDelivery`}
          onClick={() => {
            chooseShippingOption("normalDelivery", originalShippingPrice);
            setIsExpressDeliveryChosen(false);
          }}
          disabled={isShippingOptionBeingChanged}
          label={<div>Envio Estándar (3-7 días hábiles)</div>}
          value={`normalDelivery`}
          showAsRequired={true}
          checked={!isExpressDeliveryChosen}
        />
        <FieldRadioButton
          id={`expressDelivery`}
          name="deliveryOption"
          key={`expressDelivery`}
          onClick={() => {
            chooseShippingOption("expressDelivery", 199);
            setIsExpressDeliveryChosen(true);
          }}
          disabled={isShippingOptionBeingChanged}
          label={<div>Envio Express (+$90 y 1-2 días hábiles)</div>}
          value={`expressDelivery`}
          showAsRequired={true}
          checked={isExpressDeliveryChosen}
        />
        <div className={isExpressDeliveryChosen ? css.expressShippingNote : css.expressShippingNoteInvisible}>
        <div className={css.expressShippingMessageBodyContainer}>
          <div className={css.expressShippingMessageTitle}>Recuerda confirmar con la vendedora.</div>
          <div className={css.expressShippingMessage}>No se garantiza que se envía el mismo día, envíale mensaje para confirmar.</div>
        </div>
        <PrimaryButton
          rootClassName={css.expressShippingMessageButton}
          onClick={event => {
            event.preventDefault();
            onContactUser(listing.id.uuid);
          }}
        >
        Enviar mensaje
        </PrimaryButton>
        </div>
        <ShippingOptionsMessaging
          title={listing.attributes.title}
          authorDisplayName={listing.author.attributes.profile.displayName}
          isEnquiryModalOpen={isEnquiryModalOpen}
          onCloseEnquiryModal={() => setIsEnquiryModalOpen(false)}
          // sendEnquiryError,
          sendEnquiryInProgress={sendEnquiryInProgress}
          sendEnquiryError={sendEnquiryError}
          onSubmitEnquiry={enquirySubmission}
          // onManageDisableScrolling,
        />
      </div> 
      </form>
      );
  }}
  />
  )
};



ShippingOptions.defaultProps = {

};

ShippingOptions.propTypes = {
  
};

export default ShippingOptions;