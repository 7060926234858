import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import {IconSpinner, PrimaryButton } from '..';
import FieldTextInput from '../../components/FieldTextInput/FieldTextInput'
import Modal from '../Modal/Modal';
import { Form as FinalForm } from 'react-final-form';


import modalCss from './../../components/BookingPanel/BookingPanel.css';
import cssField from '../../components/FieldTextInput/FieldTextInput.css'
import css from './BookingPanelActions.css';

export class BookingPanelActionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      focusedInput: null,
      isModalOpen: false,
      minAmountError: false,
      offerInProgress: false,
      buyInProgress: false
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.startOffer = this.startOffer.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  startOffer(){
    this.setState({isModalOpen : true} );
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    this.setState({buyInProgress : true} );
    let values = {
      listingId: this.props.listingId,
      isOwnListing: this.props.isOwnListing
    }
    this.props.onSubmit(values);
  }



  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { 
      rootClassName,
      className,
      price: unitPrice,
      onManageDisableScrolling,
      listing } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const price = listing.attributes.price.amount / 100;
    let customPrice = '';
    let max = parseInt(price);
    let min = config.minimumOffer;

    const handleOfferSubmit = (e) => {
      e.persist()
      this.setState({minAmountError : false} );
      let priceToSent = customPrice !== '' ? customPrice : price
      priceToSent = priceToSent / price
  
      if (customPrice < min) {
        this.setState({minAmountError : true, offerInProgress : false} );
      } else {
        this.setState({offerInProgress : true} );

        let values = {
          listingId: this.props.listingId,
          isOwnListing: this.props.isOwnListing,
          price: priceToSent
        }
        this.props.onSubmit(values);
      }
    }

    const inputProp = {
      onChange: (e) => {
        e.persist()
        customPrice = e.target.value
      },
      onKeyPress: (e) => {
        e.persist()
        if (e.which === 13) {
          handleOfferSubmit(e)
          return false;
        }
      },
      placeholder: 'Ingresa una cantidad a ofertar',
      type: 'number',
      name: 'offerQty',
      min: min,
      max: max,
      step: 1
    }



    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }
      const {
        submitButtonWrapperClassName,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        handleOffer,
        handleClose
      } = this.props;

      const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
        <IconSpinner className={css.spinner} />
      ) : null;

      const bookingInfoErrorMaybe = fetchLineItemsError ? (
        <span className={css.sideBarError}>
          <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
        </span>
      ) : null;
      const submitButtonClasses = classNames(
        submitButtonWrapperClassName || css.submitButtonWrapper
      );
    return (
      <div>
        <Modal
          id="UniqueIdForThisModal"
          isOpen={this.props.isModalOpen}
          onClose={handleClose}
          usePortal
          contentClassName={css.modalContent}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div>
            <h2 className={modalCss.title}>Haz una oferta</h2>
            <div>
              <FinalForm
                onSubmit={handleOfferSubmit}
                action=""
                method="GET"
                render={() => {
                    return (
                      <div>
                        <FieldTextInput
                          className={cssField.input}
                          input={inputProp}
                          customErrorText={this.state.minAmountError ? `El mínimo precio que se puede ofertar es de $${Number(min).toFixed(2)} MXN` : null}
                          id="offer"
                          name="offer"
                        />
                        <br/>
                        <div className={submitButtonClasses}>
                          <PrimaryButton 
                            type="button"
                            onClick={handleOfferSubmit}
                            inProgress={this.state.offerInProgress}
                            disabled={this.state.offerInProgress}
                           >
                            <FormattedMessage id="BookingDatesForm.requestToBookOffer" />
                          </PrimaryButton>
                        </div>
                      </div>
                    )
                  }
                }
              >
              </FinalForm>
              <div className={css.requestBookOfferMessage}><FormattedMessage id="BookingDatesForm.requestToBookOfferMessage"/></div>
            </div>
          </div>
        </Modal>
        {loadingSpinnerMaybe}
        {bookingInfoErrorMaybe}
        <div className={submitButtonClasses}>
          <PrimaryButton 
          type="submit"
          onClick={this.handleFormSubmit}
          inProgress={this.state.buyInProgress}
          disabled={this.state.buyInProgress || this.state.offerInProgress}
          >
            <FormattedMessage id="BookingDatesForm.requestToBook" />
          </PrimaryButton>
        </div>
        <div className={submitButtonClasses}>
          <PrimaryButton 
          type="submit"
          onClick={handleOffer}
          inProgress={this.state.offerInProgress}
          disabled={this.state.buyInProgress || this.state.offerInProgress}
          >
            <FormattedMessage id="BookingDatesForm.requestToBookOffer" />
          </PrimaryButton>
        </div>
        {/* <p className={css.smallPrint}>
          <FormattedMessage
            id={
              isOwnListing
                ? 'BookingDatesForm.ownListing'
                : 'BookingDatesForm.youWontBeChargedInfo'
            }
          />
        </p> */}
      </div>
    );
  }
}

BookingPanelActionsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanelActionsComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingPanelActions = compose(injectIntl)(BookingPanelActionsComponent);
BookingPanelActions.displayName = 'BookingPanelActions';

export default BookingPanelActions;
