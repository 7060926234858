import React from 'react'

import { Form as FinalForm } from 'react-final-form';

import Select from 'react-select';

import {
    IconArrowHead,
    IconCard,
    IconClose,
    IconCheckmark,
    InlineTextButton, 
    Menu,
    MenuLabel,
    MenuItem,
    MenuContent,
    FieldSelect
  } from '../../components';

import css from './MonthlyInstallments.css'

// Define the formatting options as a constant
const NUMBER_FORMAT_SETTINGS = {
    locale: 'es-MX',
    options: {
      maximumFractionDigits: 2,
    }
  };
  

const MonthlyInstallments = props => {

    const {
        onToggleActive,
        shouldBeOpened,
        setMonthlyInstallments,
        rootClassName,
        
        monthlyInstallmentsBy3Total,
        monthlyInstallmentsBy6Total,
        monthlyInstallmentsBy9Total,
        msiIntallmentsChange,
        chosenMsiInstallments
    } = props;

    const monthlyOptions = [
        { value: null, label: 'Pago en 1 exhibición' },
        { value: 3, label: `3 meses de $${(monthlyInstallmentsBy3Total/3/100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}  Total: $${(monthlyInstallmentsBy3Total/100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}` },
        { value: 6, label: `6 meses de $${(monthlyInstallmentsBy6Total/6/100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}  Total: $${(monthlyInstallmentsBy6Total/100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}` },
        { value: 9, label: `9 meses de $${(monthlyInstallmentsBy9Total/100/9).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}  Total: $${(monthlyInstallmentsBy9Total/100).toLocaleString(NUMBER_FORMAT_SETTINGS.locale, NUMBER_FORMAT_SETTINGS.options)}` },
    ]

        // Stop propagation method
        const stopPropagation = (e) => {
          e.preventDefault();
          e.stopPropagation();
      };

    return(
      <div className={css.MsiSelectContainer}onClick={stopPropagation}>
        <Select
          className={rootClassName}
          classNamePrefix={"Pago a meses"}
          placeholder={"Pago a meses"}
          name="color"
          defaultValue={monthlyOptions[chosenMsiInstallments === null ? 0 : chosenMsiInstallments/3]}
          options={monthlyOptions}
          onChange={(value) => {
              msiIntallmentsChange(value)
          }}
        />
      </div>
       
    )
}

export default MonthlyInstallments