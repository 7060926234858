import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Footer,
  IconClose,
  IconEmailSent,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LinkTabNavHorizontal,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  PrivacyPolicy,
  TermsOfService,
  VerificationSection,
  CheckVerificationSection,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import {
  authenticationInProgress,
  login,
  signup,
  sendVerif,
  checkVerif,
  validateReferredCode,
} from '../../ducks/Auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { LoginForm, SignupForm } from '../../forms';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './AuthenticationPage.css';
import { deleteSubscriberInSegment, upsertSubscriberInSegment } from '../../util/api';
import { AmplitudeAnalytics, logEvent, logEventInit } from '../../util/logsEvent';

import routeConfiguration from '../../routeConfiguration';

import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';

function getFirstName(fullName) {
  const parts = fullName.split(" ");
  
  const firstName = parts[0];
  
  return firstName;
}

function getLastName(fullName) {
  const partes = fullName.split(" ");
  
  const lastName = partes.slice(1).join(" ");
  
  return lastName;
}

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      privacyModalOpen: false,
      verificationModalClose: true,
      checkVerificationModalClose: true,
      signupValues: null,
      phoneNumber: null,
      success: true,
      maxAttempts: false,
      alreadyTakenPhone: false,
      referredCodeValid: true
    };
    this.handleUsernameValidation = this.handleUsernameValidation.bind(this);
  }

  async handleUsernameValidation(userName){
    this.props.onValidateReferredCode(userName)
  }

  submitLogin = async values => {
    try {
      await this.props.submitLogin(values);
    } catch (error) {}
  };

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitSignup,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      onSendVerification,
      onCheckVerification,
      validateUserNameInProgress,
      validateUserNameError,
      userNameAvailable
    } = this.props;
    const isLogin = tab === 'login';
    const from = location.state && location.state.from ? location.state.from : null;
    const searchParams = new URLSearchParams(this.props.location.search);
    const referredCode = searchParams.get('referred-code');
    const state = location.state || {};
    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const changePhoneNumber = val => {
      this.setState({ alreadyTakenPhone: false });
    };
    const maxAttemptsChange = val => {
      this.setState({ maxAttempts: false });
      this.setState({ success: true });
    };
    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

    // Already authenticated, redirect away from auth page

    // Already authenticated, check for redirection logic
    if (isAuthenticated) {
      // If there's a 'from' route (indicating where the user came from)
      if (state.from) {
        // Check if there's a target route and parameters in the state
        if (state.target && state.params) {
          // Construct the target route using provided 'target' and 'params'
          const targetRoute = createResourceLocatorString(
            state.target,
            routeConfiguration(),
            state.params,
            {}
          );
          // Redirect to the target route
          this.props.history.replace(targetRoute);
        } else {
          // No target route specified, redirect to 'from'
          return <Redirect to={state.from} />;
        }
      } else if (currentUserLoaded && !showEmailVerification) {
        // No 'from' or target route specified, default redirection to landing page
        return <NamedRedirect name="LandingPage" />;
      }
    }

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const fromState = { state: from ? { from } : null };
    const linkPropsVerification = showEmailVerification ? (
      <NamedRedirect name="SignupPageThankyou" />
    ) : null;

    const tabs = [
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.signupLinkText" />
          </h1>
        ),
        selected: !isLogin,
        linkProps: {
          name: 'SignupPage',
          to: {
            ...fromState, // Preserved from your original code
            state: { ...this.props.location.state }, // Include the entire current state
          },
          style: {
            borderBottom: 'none',
          },
        },
      },
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h1>
        ),
        selected: isLogin,
        linkProps: {
          name: 'LoginPage',
          to: {
            ...fromState, // Preserved from your original code
            state: { ...this.props.location.state }, // Include the entire current state
          },
          style: {
            borderBottom: 'none',
          },
        },
      },
    ];

    const handleSubmitSignup = async (values, type) => {
      const amplitude = new AmplitudeAnalytics();

      if (type === 'signup') {
        const validation = await this.props.onValidateReferredCode(values.referredCode)
        if(!validation && values.referredCode){
          this.setState({referredCodeValid: false})
          return 
        }
        this.setState({ signupValues: values });
        amplitude.trackEvent('Sign Up', { email: values.email });
        const { fname: firstName , email} = values;
        upsertSubscriberInSegment({
          email,
          firstName,
          segmentIds: [process.env.REACT_APP_FLODESK_NO_SIGN_UP],
        });
        this.setState({ verificationModalClose: false });
      } else if (type === 'send') {
        const phone = values.phoneNumber;
        onSendVerification({ phone })
          .then(response => {
            if (response.status === 'pending') {
              this.setState({ phoneNumber: phone });
              this.setState({ verificationModalClose: true });
              this.setState({ checkVerificationModalClose: false });
              logEvent('Phone Number Submitted', { ['phone number']: phone })
            } else if (response.status === 'alreadyTakenPhone') {
              this.setState({ alreadyTakenPhone: true });
            } else {
              this.setState({ success: false });
            }
          })
          .catch(e => {
            if (e.details && e.details.status === 'alreadyTakenPhone') {
              this.setState({ alreadyTakenPhone: true });
            } else {
              this.setState({ success: false });
            }
          });
      } else if (type === 'check') {
        const code = values.code;
        const phone = this.state.phoneNumber;
        onCheckVerification({ phone, code })
          .then(response => {
            if (response.status === 'approved') {
              const phoneNumber = phone;
              this.setState({ checkVerificationModalClose: true });
              const { fname,email,  ...rest } = this.state.signupValues;
              const firstName = getFirstName(this.state.signupValues.fname);

              const params = {
                firstName: firstName.trim(),
                lastName: getLastName(fname).trim() ? getLastName(fname).trim() : " ",
                phoneNumber,
                email,
                ...rest,
              };
              logEvent('Phone Verified', { ['phone number']: code + phone });
              amplitude.trackEvent('Successful Sign Up', { email });
              submitSignup(params);
              deleteSubscriberInSegment({
                email,
                segmentIds: [process.env.REACT_APP_FLODESK_NO_SIGN_UP]
              })
              
            } else if (response.status === 'Invalid') {
              this.setState({ success: false });
            } else if (response.status === 'maxAttempts') {
              this.setState({ maxAttempts: true });
            }
          })
          .catch(e => {
            console.log(e);
          });
      } else if (type === 'resend') {
        const phone = this.state.phoneNumber;
        onSendVerification({ phone })
          .then(response => {
            if (response.status === 'pending') {
              this.setState({ verificationModalClose: true });
              this.setState({ checkVerificationModalClose: false });
            } else {
              this.setState({ success: false });
            }
          })
          .catch(e => {
            this.setState({ success: false });
          });
      }
    };

    const formContent = (
      <div className={css.content}>
        {isLogin ? (
          <div className={css.label}>¡Bienvenida de vuelta Gloser!</div>
        ) : (
          <div className={css.label}>¡Usa el código "HOLAGLOSET" para $150 en tu primera compra!</div>
        )}
        <LinkTabNavHorizontal className={css.tabs} tabs={tabs} purgeHistory={true} />
        {loginOrSignupError}
        {isLogin ? (
          <LoginForm className={css.form} onSubmit={this.submitLogin} inProgress={authInProgress} />
        ) : (
          <SignupForm
            className={css.form}
            onSubmit={values => handleSubmitSignup(values, 'signup')}
            inProgress={authInProgress}
            onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
            onOpenPrivacyPolicy={() => this.setState({ privacyModalOpen: true })}
            referredCode={referredCode}
            initialValues={{referredCode: referredCode}}
            handleUsernameValidation={(username) => this.handleUsernameValidation(username)}
            validateUserNameInProgress={validateUserNameInProgress}
            userNameAvailable={userNameAvailable}
            validateUserNameError={validateUserNameError}
            referredCodeValid={this.state.referredCodeValid}
            resetReferredCodeStatus={() => this.setState({referredCodeValid: true})}
          />
        )}
        {!this.state.checkVerificationModalClose ? (
          <CheckVerificationSection
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmitEnquiry={values => handleSubmitSignup(values, 'check')}
            resendCode={values => handleSubmitSignup(values, 'resend')}
            onCloseEnquiry={() => this.setState({ checkVerificationModalClose: true })}
            success={this.state.success}
            maxAttempts={this.state.maxAttempts}
            maxAttemptsChange={maxAttemptsChange}
            useModal={true}
          />
        ) : null}
        {!this.state.verificationModalClose ? (
          <VerificationSection
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmitEnquiry={values => handleSubmitSignup(values, 'send')}
            onCloseEnquiry={() => this.setState({ verificationModalClose: true })}
            success={this.state.success}
            alreadyTakenPhone={this.state.alreadyTakenPhone}
            changePhoneNumber={changePhoneNumber}
            useModal={true}
          />
        ) : null}
      </div>
    );

    const name = user.attributes.profile.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <NamedLink className={css.verifyClose} name="LandingPage">
          <span className={css.closeText}>
            <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </NamedLink>
        <IconEmailSent className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </p>
        </div>
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

    const topbarClasses = classNames({
      [css.hideOnMobile]: showEmailVerification,
    });

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer className={topbarClasses} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-around"
                xs={12}
                className={css.heroForm}
              >
                <Grid item xs={0} md={5} className={css.heroContent}>
                  <h1 className={css.heroMainTitle}>
                    <FormattedMessage id="SectionHero.title" />
                  </h1>
                  <h2 className={css.heroSubTitle}>
                    <FormattedMessage id="SectionHero.subTitle" />
                  </h2>
                </Grid>
                <Grid item xs={12} md={6} className={css.heroForm}>
                  {linkPropsVerification}{' '}
                  {showEmailVerification ? emailVerificationContent : formContent}
                </Grid>
              </Grid>
            </div>
            <Modal
              id="AuthenticationPage.tos"
              isOpen={this.state.tosModalOpen}
              onClose={() => this.setState({ tosModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="AuthenticationPage.termsHeading" />
                </h2>
                <TermsOfService />
              </div>
            </Modal>
            <Modal
              id="AuthenticationPage.privacy"
              isOpen={this.state.privacyModalOpen}
              onClose={() => this.setState({ privacyModalOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="AuthenticationPage.privacyHeading" />
                </h2>
                <PrivacyPolicy />
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, status, phoneNumber, validateUserNameInProgress, userNameAvailable, validateUserNameError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    status,
    phoneNumber,
    validateUserNameInProgress,
    validateUserNameError,
    userNameAvailable
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendVerification: phone => dispatch(sendVerif(phone)),
  onCheckVerification: ({ phone, code }) => dispatch(checkVerif(phone, code)),
  onValidateReferredCode: (code) => dispatch(validateReferredCode(code)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
