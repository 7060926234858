import classNames from 'classnames';
import { func, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { NamedLink, ResponsiveImage } from '../../components';
import { toggleLike } from '../../containers/ListingPage/ListingPage.duck';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ensureListing } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { isMobile } from '../../util/userAgent';
import css from './ListingCard.css';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardHomeComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const isBought = currentListing.attributes.publicData.sold;

  const isAvailable = currentListing.attributes.publicData.available;

  const boughtLabelPicture = isBought ? (<div className={css.pictureSold}></div>) : null;

  const boughtLabel = isBought ? (<div className={css.pictureSoldTextHome}>VENDIDO</div>) : null;

  const availableLabelPicture = isAvailable === "No-Disponible" ? (<div className={css.pictureSold}></div>) : null;

  const availableLabel = isAvailable === "No-Disponible" ? (<div className={css.pictureNotAvailableTextHome}>NO DISPONIBLE</div>) : null;

  const onListingNaviagation = (e) => {
    setActiveListing(id);
  }
  // set target to _blank if on mobile
  const target = isMobile() ? '_blank' : null;
  return (
    <div className={classes} id={`ListingCard-${id}`}>
      <NamedLink
      draggable="false"
        onClick={onListingNaviagation}
        className={css.noUnderline}
        name="ListingPage"
        target={target}
        params={{ id, slug }}
      >
        <div
          className={css.imageWrapper}
        >
          <div className={css.aspectWrapper}>
          {boughtLabelPicture}
          {availableLabelPicture}
          {boughtLabel}
          {availableLabel}
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['square-small', 'square-small2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardHomeComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  isBought: false,
};

ListingCardHomeComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  const { likeInProgress } = state.ListingPage;
  const { currentUser } = state.user;
  return {
    currentUser,
    likeInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleLike: (user, listingId) => dispatch(toggleLike(user, listingId)),
});

const ListingCardHome = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingCardHomeComponent);

export default ListingCardHome;
