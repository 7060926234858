import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Versión vigente: Enero 13, 2021</p>

      <p>
        Los siguientes términos y condiciones (en adelante, los "Términos y Condiciones") describe
        el contenido del contrato que el usuario acepta desde el momento de ingresar al sitio www.gloset.mx
        (en adelante el Sitio) y son aplicables al uso de los servicios ofrecidos (en adelante, "los Servicios")
        por la compañía GLOSET.MX, S.A.P.I. de C.V. (en adelante GLOSET) dentro del Sitio www.gloset.mx y sus aplicaciones móviles.
        Cualquier persona que desee acceder y/o usar el sitio o los servicios podrá hacerlo sujetándose a los
        Términos y Condiciones.
      </p>

      <p>
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN
        CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
      </p>

      <p>
        El Usuario se obliga a leer, entender y aceptar todas las condiciones establecidas en los Términos
        y Condiciones y en el Aviso de Privacidad.
      </p>

      <p>
        La utilización de los Servicios implica la aceptación expresa por parte del Usuario de todas las
        políticas establecidas en las Condiciones Generales y sus Anexos, y demás políticas y principios
        incorporados a las mismas por referencia.
      </p>

      <h2>1.-OTROS TÉRMINOS APLICABLES</h2>
      <p>
        Estos Términos de uso hace referencia a y añaden los siguientes Términos adicionales, que también
        se aplican a tu uso de este Sitio.
      </p>

      <p>
        1.1. Nuestra Política de Privacidad establece los términos en los que procesamos cualquier información
        personal que recogemos sobre ti o que tú nos proporcionas. Al utilizar este Sitio, estás de acuerdo
        con dicho procesamiento y garantizas que toda la información que nos proporcionas es correcta.
      </p>

      <p>
        1.2. Nuestra Política de Cookies establece la información sobre las cookies en nuestro Sitio.
      </p>

      <p>
        1.3. Nuestras Normas se aplican a tu interacción con este Sitio, incluyendo lo que puedas registrar en el
        Sitio, cualquier contenido que te permitamos subir y cualquier contacto que puedas tener con otros
        usuarios de este Sitio.
      </p>

      <h2>2.-NUESTROS SERVICIOS</h2>
      <p>
        GLOSET únicamente pone a disposición de los Usuarios un espacio virtual que les permite ponerse
        en comunicación mediante Internet para encontrar una forma de vender o comprar bienes de segunda
        mano. GLOSET no es el propietario de los artículos ofrecidos, no tiene posesión de ellos ni los
        ofrece en venta. GLOSET no interviene en el perfeccionamiento de las operaciones realizadas entre
        los Usuarios ni en las condiciones por ellos estipuladas para las mismas, por ello no será
        responsable respecto de la existencia, calidad, cantidad, estado, integridad o legitimidad de los
        bienes ofrecidos, adquiridos o enajenados por los Usuarios, así como de la capacidad para contratar
        de los Usuarios o de la veracidad de los Datos Personales por ellos ingresados. Cada Usuario conoce
        y acepta ser el exclusivo responsable por los artículos que publica para su venta y por las ofertas
        y/o compras que realiza.
      </p>

      <h2>3.-CAPACIDAD LEGAL</h2>
      <p>
        Los Servicios prestados en el Sitio deberán ser utilizados por personas que tengan capacidad legal
        suficiente para obligarse en términos del presente contrato. No podrán utilizar los servicios las
        personas que no tengan esa capacidad ni los menores de edad. Tampoco podrán hacer uso de los servicios
        los usuarios bloqueados o suspendidos.
      </p>

      <h2>4.-REGISTRO</h2>
      <p>
        4.1. Para utilizar los Servicios debes registrarte con nosotros completando la solicitud de registro
        después de hacer clic en «Regístrate».
      </p>

      <p>
        4.2. Los Servicios no están destinados a su uso por parte de niños menores de 18 años. Si tienes
        menos de 18 años, un progenitor o tutor (que debe tener 18 años o más) deberá registrarse para
        utilizar los Servicios en tu nombre. Tu progenitor o tutor será responsable de supervisar tu uso
        de los Servicios en todo momento y de asegurarse de que cumples con estos Términos. GLOSET estará
        autorizado a hacer a tu progenitor o tutor totalmente responsable de cualquier incumplimiento de
        estos Términos y Condiciones que tú cometas.
      </p>
      <p>
        4.3. No se permite a las personas dedicadas al comercio la utilización de los Servicios. Cuando
        dichos comerciantes negocian con los demás usuarios están sujetos a más requisitos legales que
        los vendedores no comerciantes. Al utilizar los Servicios garantizas que no eres una persona
        dedicada al comercio en términos de la legislación mercantil vigente. Sin embargo, GLOSET no
        puede garantizar que los usuarios que se registran en el Sitio no sean comerciantes, al no poder
        comprobar tal situación.
      </p>
      <p>
        4.4. Te comprometes a mantener actualizada toda la información sobre ti en el Sitio.
      </p>
      <p>
        4.5. Confirmas que cualquier información que nos proporciones es verdadera, correcta y completa.
      </p>
      <p>
        4.6 La información que proporciones quedará protegida y será tratada de conformidad con nuestro
        Aviso de privacidad.
      </p>

      <h2>5.-MODIFICACIONES Y CAMBIOS</h2>
      <p>
        GLOSET podrá modificar en todo momento los Términos y Condiciones y sin necesidad de aviso previo,
        y surtirán efectos una vez que se hagan públicos en el Sitio. Dichas modificaciones serán comunicadas
        por GLOSET al correo electrónico registrado en el Sitio. Si usted no está de acuerdo con las
        modificaciones realizadas por GLOSET podrá solicitar la baja de la cuenta.
      </p>
      <p>
        El uso del sitio y/o sus servicios implica la aceptación de estos Términos y Condiciones de GLOSET.
      </p>

      <h2>6.-TU CUENTA Y CONTRASEÑA</h2>
      <p>
        6.1. Si eliges, o se te proporciona, un código de identificación de usuario, contraseña o cualquier
        otro tipo de información como parte de nuestros procedimientos de seguridad, deberás tratar dicha
        información como confidencial. No deberás divulgarla a terceros y deberás asegurarte de que cierras
        la sesión de tu cuenta al finalizar cada sesión.
      </p>
      <p>
        6.2. Debes notificarnos inmediatamente mediante el correo electrónico <a href="mailto:ayuda@gloset.mx">ayuda@gloset.mx</a> si
        sabes o sospechas que alguien aparte de ti conoce tu código de identificación de usuario o contraseña,
        o si se ha producido un uso no autorizado de tu contraseña o cuenta o cualquier otra violación de seguridad.
      </p>
      <p>
        6.3. Tenemos el derecho de desactivar cualquier código de identificación de usuario o contraseña,
        ya sea la elegida por ti o la que te hayamos proporcionado, en cualquier momento, si contamos
        con razones para sospechar que se ha producido una violación de seguridad o si en nuestra opinión
        razonable has incumplido cualquiera de las disposiciones de estos Términos.
      </p>

      <h2>7.-EL CONTENIDO QUE NOS PROPORCIONAS, COMENTARIOS Y MENSAJES PRIVADOS</h2>
      <p>
        7.1. Cualquier contenido que nos proporciones o cualquier contacto que tengas con otros usuarios,
        ya sea de forma privada o pública, deberá cumplir con nuestras Normas.
      </p>
      <p>
        7.2. Garantizas que ese contenido o contacto cumple con dichas Normas y serás responsable ante
        nosotros y nos indemnizarás por cualquier incumplimiento de esta garantía. Esto significa que
        serás responsable de cualquier pérdida o daño que suframos a causa de tu incumplimiento de
        dicha garantía.
      </p>
      <p>
        7.3. Nosotros no seremos responsables, ni deberemos responsabilidad a terceros, del contenido o
        la exactitud de cualquier contenido que publiques tú o cualquier otro usuario de nuestro Sitio.
      </p>
      <p>
        7.4. Nos otorgas una licencia no exclusiva, irrevocable, perpetua, transferible, sublicenciable,
        exenta de derechos, incondicional, e internacional para utilizar, copiar, distribuir y divulgar
        a terceros cualquier contenido que nos proporciones.
      </p>

      <h2>8.-NORMAS PARA REGISTRAR ARTÍCULOS</h2>
      <p>
        En lo que se refiere a todos los artículos que ofreces para su venta a través de los Servicios:
      </p>
      <p>
        8.1. Deberás asegurarte de que dichos artículos cumplen con nuestras Normas. Ten en cuenta que
        es probable que no puedas registrar algunas categorías de artículos como se establece en nuestras
        Normas. GLOSET tiene el derecho de dar de baja o eliminar artículos que considere que no entren en el catálogo.
      </p>
      <p>
        8.2. Deberás ser la propietaria y la beneficiaria legal de dichos artículos y tener el derecho a
        venderlos.
      </p>
      <p>
        8.3. La venta, la utilización o posesión de dichos artículos no podrán infringir los derechos de
        terceros; y no deberán incumplir ninguna ley o regulación local, nacional o internacional.
      </p>
      <p>
        8.4. Para registrar un artículo deberás completar un cuestionario de publicidad del artículo y
        proporcionar cualquier otro tipo de información que requiramos para todos los artículos que ofrezcas
        para su venta en el Sitio de acuerdo con nuestras Normas.
      </p>
      <p>
        8.5. Al registrar un artículo representas y confirmas que el artículo cumple con todos los aspectos
        del cuestionario del artículo que has enviado, incluyendo la categoría en la que registres dicho
        artículo y cualquier fotografía del artículo y que dicho cuestionario está completo. Los términos
        del cuestionario del artículo que envíes constituirán los términos en los que ofrezcas el artículo
        para su venta. Y esas fotografías se pueden usar para cualquier tipo de promoción hacía la página de Gloset, 
        con el fin de ayudar a las vendedoras a veder más.
      </p>
      <p>
        8.6. Cuando envíes el cuestionario del artículo el artículo se considerará un «Artículo del Catálogo».
      </p>
      <p>
        8.7. Podrás modificar los términos en los que ofreces tu Artículo del Catálogo para su venta en cualquier
        momento antes de que hayas suscrito el contrato de venta de dicho artículo.
      </p>
      <p>
        8.8. Podrás eliminar un Artículo del Catálogo del Sitio en cualquier momento antes de haber suscrito el
        contrato de venta de dicho artículo.
      </p>

      <h2>9.-COMPRAR Y VENDER</h2>
      <p>
        Si compras o vendes un Artículo del Catálogo deberás utilizar nuestros servicios de pago y de envío.
        Visita las secciones de la 10 a la 15 a continuación.
      </p>

      <h2>SERVICIOS DE PAGOS Y DE ENVíO</h2>
      <p>
        Las siguientes secciones de la 10 a la 15 se aplicarán cuando elijas utilizar nuestros servicios de
        pago y de envío.
      </p>

      <h2>10.-PAGO DE COMISIONES Y SERVICIOS DE ENVÍO</h2>
      <p>
        10.1. Cuando eliges los Servicios de pago y de envío aceptas que GLOSET reciba una comisión en relación
        a la venta de un Artículo del Catálogo de acuerdo con el importe establecido en la Lista de costes.
        GLOSET podrá modificar dicha Lista de costes periódicamente.
      </p>
      <p>
        10.2. GLOSET descontará la comisión del precio de compra abonado por la compradora.
      </p>

      <h2>11.-COMPRAR UTILIZANDO EL SITIO</h2>
      <p>
        Cuando utilices nuestros servicios de pago y de envío:
      </p>
      <p>
        11.1. Cuando haces clic en «Confirmar Compra», confirmas y aceptas que estás suscribiendo un contrato
        con la vendedora para comprar dicho Artículo del Catálogo.
      </p>
      <p>
        11.2. Cuando suscribes un contrato para comprar un Artículo del Catálogo garantizas y asumes que tienes
        el derecho legal para suscribir dicha transacción.
      </p>
      <p>
        11.3. Únicamente podrás rescindir el contrato con la vendedora cuando así te lo permita la ley.
      </p>
      <p>
        11.4. Cuando suscribas un contrato con la vendedora el importe se descontará de tu tarjeta de crédito o
        de débito de forma inmediata mediante Stripe. Se retendrá el importe en Stripe para permitir que corra
        el periodo de tiempo hasta que se valide la entrega exitosa y condición del artículo. Para obtener más
        información visita la sección de «Pagos» y «Aceptación» a continuación.
      </p>
      <p>
        Impuestos. Tal como lo establece la normativa fiscal vigente, el comprador debe exigir factura o ticket
        al vendedor como comprobante de la operación en su caso. El vendedor puede no estar obligado a emitir
        factura o ticket sólo en el caso de tratarse de una persona física que efectúa ventas ocasionalmente.
      </p>

      <h2>12.-VENDER UTILIZANDO EL SITIO DE GLOSET</h2>
      <p>
        Cuando utilices nuestros servicios de pago y de envío:
      </p>
      <p>
        12.1. Un Artículo del Catálogo a la venta supone una oferta por parte de la vendedora de dicho artículo,
        que podrá ser aceptada por una compradora. Para obtener más información dirígete a la sección anterior:
        «Comprar Utilizando el Sitio».
      </p>
      <p>
        12.2. Una vez que una usuaria haga clic en «Confirmar Compra» en la página de pagos, confirmas y asumes
        que estás suscribiendo un contrato con la compradora para vender ese Artículo del Catálogo.
      </p>
      <p>
        12.3. Antes de suscribir un contrato con la compradora de acuerdo con la sección 12.2 mencionada
        anteriormente, deberás considerar las ofertas del Artículo del Catálogo de otras usuarias del Sitio.
      </p>
      <p>
        12.4. Podrás rescindir el contrato con la compradora únicamente cuando así te lo permita la ley.
      </p>
      <p>
        El Usuario Vendedor debe tener capacidad legal para vender el bien objeto de su oferta. Dado que GLOSET
        es un punto de encuentro entre comprador y vendedor y no participa de las operaciones que se realizan
        entre ellos, el Vendedor será responsable por todas las obligaciones y cargas impositivas que
        correspondan por la venta de sus artículos, sin que pudiera imputársele a GLOSET algún tipo de responsabilidad
        por incumplimientos en tal sentido.
      </p>
      <p>
        Impuestos. Como se menciona anteriormente, GLOSET sólo pone a disposición de los Usuarios un espacio virtual
        que les permite comunicarse mediante Internet para encontrar una forma de vender o comprar artículos y/o servicios.
        GLOSET no tiene participación alguna en el proceso de negociación y perfeccionamiento del contrato definitivo
        entre las partes. Por eso, GLOSET no es responsable por el efectivo cumplimiento de las obligaciones fiscales
        o impositivas establecidas por la ley vigente.
      </p>
      <p>
        Impuesto Sobre la Renta (ISR): El Usuario Vendedor manifiesta que no realiza cabo actividades de enajenación
        de bienes o prestación de servicios a través de otras plataformas digitales por las que obtengan ingresos, y
        que en caso de desarrollarlas avisará GLOSET para llevar a cabo la retención en términos de lo dispuesto en
        la Regla Miscelánea Fiscal 12.2.8. vigente para 2020
      </p>
      <p>
        Asimismo el Usuario Vendedor se compromete a expedir el comprobante fiscal por las operaciones que lleve a
        cabo según lo dispuesto Regla Miscelánea Fiscal 12.3.6. vigente para 2020, liberando a GLOSET de cualquier
        responsabilidad por incumplimiento de esta norma.
      </p>

      <h2>13.-SERVICIOS DE PAGO</h2>
      <p>
        Cuando utilices nuestros servicios de pago:
      </p>
      <p>
        13.1. Las compradoras deberán abonar los Artículos del Catálogo a las vendedoras mediante tarjeta de crédito
        o de débito utilizando los servicios de pago en el Sitio.
      </p>
      <p>
        13.2. Los abonos deberán realizarse en la divisa que se especifique en el Sitio y todos los precios se exponen
        con IVA (cuando proceda) así como otros impuestos aplicables, si bien se excluyen los gastos de envío y de seguro.
      </p>
      <p>
        13.3. Las compradoras serán responsables de cualquier coste de envío, incluyendo cualquier gasto de seguro
        a menos que se convenga de forma diferente de mutuo acuerdo entre la compradora y la vendedora.
      </p>
      <p>
        13.4. Los abonos de los Artículos del Catálogo se realizarán por adelantado. La tarjeta de crédito o de
        débito de la compradora se cargará cuando ambas, la compradora y la vendedora, suscriban un contrato por
        la compra de un artículo de acuerdo con la sección 10. No enviaremos el importe abonado a la cuenta de
        la vendedora hasta que la compradora haya aceptado el artículo o se considere como aceptado. Para obtener
        más información, visita la sección titulada «Aceptación» a continuación.
      </p>
      <p>
        13.5. Utilizamos un proveedor de servicios externo para procesar los abonos y para almacenar la información
        de tu tarjeta de crédito o de débito. Cuando utilices nuestros servicios de pagos, estarás aceptando los
        términos y condiciones de dicho proveedor. No seremos responsables de los actos u omisiones de dicho proveedor.
        Tú serás la única responsable de la transmisión de información en conexión con tu tarjeta de crédito o de
        débito y por la presente excluimos cualquier responsabilidad en la medida en que lo permita la ley.
      </p>
      <p>
        13.6. El costo de nuestros servicios de pago está disponible en la Lista de costes y la vendedora lo abonará
        a través del pago de la comisión de venta calculado de acuerdo al precio del artículo vendido.
      </p>
      <p>
        13.7. El dinero que la vendedora obtenga por la venta de los artículos en GLOSET se guardará en una cuenta
        online. Dicho dinero se transferirá a la vendedora a su cuenta bancaria personal, automáticamente después
        de que la compradora haya aceptado el artículo.
      </p>
      <p>
        13.8. No llevarás a cabo ningún proceso para tergiversar de forma fraudulenta el precio acordado del Artículo
        del Catálogo para evitar o reducir el importe de la comisión pagadera por la vendedora a Gloset.
      </p>
      <p>
        13.9. Si sospechamos que estás incumpliendo nuestros Términos y/o estás tergiversando de forma fraudulenta
        el precio acordado del Artículo del Catálogo tendremos derecho a calcular la comisión total aplicable del
        precio original registrado del Artículo del Catálogo.
      </p>

      <h2>14.-SERVICIOS DE ENVÍO</h2>
      <p>
        14.1. Actualmente el servicio de envío de los artículos es Estafeta.
      </p>
      <p>
        14.2. El coste del servicio de envío está disponible en la Lista de costes y será la compradora del Artículo
        del Catálogo quien lo abone.
      </p>
      <p>
        14.3. Después de haber suscrito el contrato con la compradora por la compra de uno de tus Artículos del
        Catálogo te enviaremos la confirmación de dicha compra. Deberás enviar el Artículo del Catálogo que has
        vendido a través de Estafeta a no más tardar después de 15 días de la compra.
      </p>
      <p>
        14.4. Si no envías el artículo a la compradora en los siguientes 15 días tras el recibo de los datos de envío,
        contaremos con la autorización para reembolsar el importe de la compra a la cuenta de la compradora.
      </p>
      <p>
        14.5. Declinamos cualquier responsabilidad por el envío de paquetes, cajas y artículos que se extravíen o
        sean destruidos por parte del servicio de mensajería por no respetar las restricciones de envío de paquetes
        del servicio de mensajería. Es responsabilidad del miembro informarse sobre dichas restricciones antes de
        enviar los artículos.
      </p>

      <h2>15.-ACEPTACIÓN</h2>
      <p>
        Cuando utilices nuestros servicios de pago y de envío:
      </p>
      <p>
        15.1. Para poder proteger a las compradoras, guardaremos el precio de compra del artículo durante un mínimo
        de 3 días tras la fecha en la que dicho artículo sea entregado o se debiera haber entregado.
      </p>
      <p>
        15.2. Si la vendedora no envía el artículo a la compradora en los 15 días siguientes al recibo de los datos
        de envío, contaremos con la autorización para reembolsar el precio de compra a la cuenta de la compradora.
        El reembolso del precio de compra será el único recurso de la compradora en cuanto a su contrato con la vendedora.
      </p>
      <p>
        15.3. Si la compradora recibe un artículo que no coincide materialmente con su descripción en el Sitio,
        la compradora deberá notificárnoslo utilizando el formulario de "Abrir un Caso" en máximo 3 días siguientes
        a su recibo.
      </p>
      <p>
        15.4. Si la compradora no recibe un artículo que ha adquirido de la vendedora, la compradora deberá notificarlo
        utilizando el formulario de "Abrir un Caso".
      </p>
      <p>
        15.5. Si la compradora no contacta con nosotros en los 3 días siguientes a la entrega de un artículo de acuerdo
        con las secciones 15.3 y 15.4 anteriormente mencionadas, se considerará que la compradora ha aceptado el artículo.
        Al aceptar el artículo, enviaremos el precio de compra del artículo a la cuenta de la vendedora menos cualquier
        comisión que se nos debiera.
      </p>
      <p>
        15.6. Cuando la compradora se ponga en contacto con nosotros de acuerdo con las secciones 15.3 o 15.4 anteriormente
        mencionadas, mantendremos el precio de compra menos cualquier comisión que se nos debiera hasta que recibamos las
        instrucciones de mutuo acuerdo de la compradora y la vendedora en cuanto a la cuenta a la que dicho importe deberá
        ser enviado.
      </p>

      <h2>GENERAL</h2>

      <h2>16.-ELIMINACIÓN, AVISOS, BLOQUEOS, SUSPENSIONES Y RESCISIONES</h2>
      <p>
        Podremos, según estimemos necesario, enviar avisos, suspender, bloquear o rescindir tu acceso al Sitio y/o los
        Servicios (o parte de ellos) o eliminar cualquier contenido, incluyendo, entre otros, cualquier Artículo del
        Catálogo si:
      </p>
      <p>
        16.1. Estás incumpliendo estos Términos o sospechamos que puedes estar incumpliendo estos Términos.
      </p>
      <p>
        16.2. Actúas de una manera que consideremos que pueda dañar nuestra reputación.
      </p>
      <p>
        16.3. Estás utilizando el Sitio o los Servicios de una forma que podamos considerar fraudulenta.
      </p>

      <h2>17.-ACCESO A ESTE SITIO, A SUS SERVICIOS Y AL CONTENIDO</h2>
      <p>
        17.1. No garantizamos que este Sitio o cualquiera de los Servicios o el contenido del mismo, esté siempre
        disponible o no se interrumpa.
      </p>
      <p>
        17.2. Nos reservamos el derecho en cualquier momento y sin aviso previo para mejorar, modificar, alterar,
        suspender o interrumpir de manera permanente todo el Sitio o algunas partes del mismo o cualquier Servicio
        o el contenido del mismo, así como a restringir o prohibir el acceso al mismo.
      </p>

      <h2>18.-VIRUS</h2>
      <p>
        18.1. No garantizamos que este Sitio sea seguro o esté libre de errores o virus.
      </p>
      <p>
        18.2. Eres responsable de configurar tu tecnología de la información, programas informáticos y
        plataformas para poder acceder a este Sitio. Deberás utilizar tu propio software de protección anti
        virus.
      </p>
      <p>
        18.3. No deberás hacer un mal uso de este Sitio introduciendo deliberadamente virus, troyanos,
        gusanos, bombas lógicas u otro material que sea malicioso o tecnológicamente dañino.
      </p>
      <p>
        18.4. No deberás intentar conseguir acceso no autorizado a este Sitio, el servidor donde se
        almacena este Sitio o cualquier otro servidor, ordenador o base de datos conectados con este
        Sitio.
      </p>
      <p>
        18.5. No deberás atacar este Sitio por medio de ataques de denegación de servicio o por medio
        de ataques de denegación de servicio distribuido.
      </p>
      <p>
        18.6. Denunciaremos cualquier violación a las autoridades policiales competentes y colaboraremos
        con dichas autoridades divulgando tu identidad. En el caso de dicha violación, tu derecho al
        uso de este Sitio cesará inmediatamente.
      </p>

      <h2>19.-ENLACES DE TERCEROS Y RECURSOS EN ESTE SITIO</h2>
      <p>
        19.1. Cuando este Sitio contenga enlaces a otros sitios y recursos proporcionados por terceros,
        dichos enlaces se proporcionarán únicamente para tu información. No ejercemos ningún control
        sobre el contenido de dichos sitios o recursos.
      </p>
      <p>
        19.2. No asumimos responsabilidad alguna sobre el contenido de los enlaces de las páginas web
        en nuestro Sitio. Dichos enlaces no deberán ser interpretados como un respaldo por nuestra parte
        a dichos enlaces de las páginas web. No seremos responsables de cualquier pérdida o daño que
        pueda surgir de tu uso de los mismos.
      </p>

      <h2>20.-PROMOCIONES</h2>
      <p>
        20.1. Periódicamente podremos publicar promociones, concursos, juegos o sorteos de premios en el
        Sitio con términos y condiciones especiales de acuerdo con los mismos.
      </p>
      <p>
        20.2. Si existiera cualquier conflicto entre dichos términos y condiciones especiales y estos
        Términos, prevalecerán los términos y condiciones especiales.
      </p>

      <h2>21.-SUCESOS FUERA DE NUESTRO CONTROL</h2>
      <p>
        No incumpliremos estos Términos ni seremos responsables del retraso en la ejecución, o en el
        incumplimiento de cualquier obligación de acuerdo con este acuerdo de los Términos si dicho
        retraso o incumplimiento surgiera por acontecimientos, circunstancias o causas fuera de nuestro
        razonable control. En dichas circunstancias tendremos derecho a una extensión razonable del
        período para el cumplimiento de dichas obligaciones.
      </p>

      <h2>22.-MARCAS COMERCIALES</h2>
      <p>
        22.1. GLOSET es una marca comunitaria comercial registrada perteneciente a “Gloset Club”
      </p>
      <p>
        22.2. Cualquier otra marca comercial que se muestre en el Sitio no es de nuestra propiedad
        ni de la propiedad de nuestro grupo empresarial. Cualquier uso de dicha marca comercial puede
        constituir una violación de los derechos del propietario de dicha marca comercial.
      </p>

      <h2>23.-SANCIONES. SUSPENSIÓN DE OPERACIONES</h2>
      <p>
        Sin perjuicio de otras medidas, GLOSET podrá advertir, suspender en forma temporal o inhabilitar
        definitivamente la Cuenta de un Usuario o una publicación, aplicar una sanción que impacte
        negativamente en la reputación de un Usuario, iniciar las acciones que estime pertinentes y/o
        suspender la prestación de sus Servicios si (a) se quebrantara alguna ley, o cualquiera de las
        estipulaciones de los Términos y Condiciones Generales y demás políticas de GLOSET; (b) si
        incumpliera sus compromisos como Usuario; (c) si se incurriera a criterio de GLOSET en conductas
        o actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del Usuario o cualquier
        información proporcionada por el mismo fuere errónea; (e) GLOSET entendiera que las publicaciones
        u otras acciones pueden ser causa de responsabilidad para el Usuario que las publicó, para GLOSET
        o para los demás Usuarios en general. En el caso de la suspensión de un Usuario, sea temporal o
        definitiva, todos los artículos que tuviera publicados serán removidos del sistema. También se
        removerán del sistema las ofertas de compra de bienes ofrecidos bajo la modalidad subasta.
      </p>

      <h2>24.-JURISDICCIÓN Y LEY APLICABLE</h2>
      <p>
        Para la interpretación, resolución, ejecución y cumplimiento del presente Acuerdo, las Partes se
        someten expresamente a las leyes y tribunales competentes de la Ciudad de México, renunciando a
        cualquier otro fuero que por razón de domicilio u otra causa, tuvieren o llegaren a adquirir.
      </p>
      <p>
        Si tienes alguna duda sobre los Términos y Condiciones Generales o demás políticas y principios
        que rigen GLOSET háznosla llegar al correo <a href="mailto:ayuda@gloset.mx">ayuda@gloset.mx</a>
      </p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
