import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Footer,
  IconClose,
  IconEmailSent,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LinkTabNavHorizontal,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  PrivacyPolicy,
  TermsOfService,
  VerificationSection,
  CheckVerificationSection,
  IconArrowHead,
} from '..';
import config from '../../config';
import {
  authenticationInProgress,
  login,
  signup,
  sendVerif,
  checkVerif,
  validateReferredCode
} from '../../ducks/Auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { LoginForm, SignupForm } from '../../forms';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { AmplitudeAnalytics, logEvent } from '../../util/logsEvent';
import { deleteSubscriberInSegment, upsertSubscriberInSegment } from '../../util/api';

import routeConfiguration from '../../routeConfiguration';

import { createResourceLocatorString } from '../../util/routes';

import  SectionNavigation  from './SectionNavigation'

import Image from '../../assets/background-auth-modal.jpg'

import css from './AuthenticationPageModalWrapper.css';


function getFirstName(fullName) {
  const parts = fullName.split(" ");
  
  const firstName = parts[0];
  
  return firstName;
}

function getLastName(fullName) {
  const partes = fullName.split(" ");
  
  const lastName = partes.slice(1).join(" ");
  
  return lastName;
}
export class AuthenticationPageModalWrapperComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      privacyModalOpen: false,
      verificationModalClose: true,
      checkVerificationModalClose: true,
      signupValues: null,
      phoneNumber: null,
      success: true,
      maxAttempts: false,
      alreadyTakenPhone: false,
      authPage: 'Login',
      sendVerificationInProgress: false,
      checkVerificationInProgress: false,
      referredCodeValid: true,
    };
    this.submitLogin = this.submitLogin.bind(this);
  }

  submitLogin = async values => {
    try {
      await this.props.submitLogin(values);
    } catch (error) { }
  };

  componentDidUpdate(prevProps) {
    const oldValue = prevProps.isAuthenticated;
    const newValue = this.props.isAuthenticated;
    const valueChanged = oldValue !== newValue;

    if(valueChanged && newValue){
      this.props.closeAuthModal()
      if(this.props.requiresExtraAction){
        this.props.extraAction()
      }
    }
    if (valueChanged && newValue && this.props.requiresRedirection) {
      this.props.onLoginCompleted()
    }
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      loginError,
      scrollingDisabled,
      signupError,
      submitSignup,
      tab,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      onManageDisableScrolling,
      onSendVerification,
      onCheckVerification,
    } = this.props;
    const isLogin = this.state.authPage === 'Login';

    const from = location.state && location.state.from ? location.state.from : null;
    const state = location.state || {};
    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const changePhoneNumber = val => {
      this.setState({ alreadyTakenPhone: false });
    };
    const maxAttemptsChange = val => {
      this.setState({ maxAttempts: false });
      this.setState({ success: true });
    };
    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

    // Already authenticated, redirect away from auth page

   // Already authenticated, check for redirection logic

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const handleSubmitSignup = async(values, type) => {
      const amplitude = new AmplitudeAnalytics();
      
      if (type === 'signup') {
        const validation = await this.props.onValidateReferredCode(values.referredCode)
        if(!validation && values.referredCode){
          this.setState({referredCodeValid: false})
          return 
        }
        this.setState({ signupValues: values });

        const { fname: firstName , email} = values;
        amplitude.trackEvent('Sign Up', { email: values.email });
        upsertSubscriberInSegment({
          email,
          firstName,
          segmentIds: [process.env.REACT_APP_FLODESK_NO_SIGN_UP],
        });
        this.setState({ authPage: 'Verify' });
      } else if (type === 'send') {
        const phone = values.phoneNumber;
        this.setState({ sendVerificationInProgress: true })
        onSendVerification({ phone })
          .then(response => {

            if(!this.state.sendVerificationInProgress){
              return
            }

            this.setState({ sendVerificationInProgress: false })

            if (response.status === 'pending') {
              this.setState({ phoneNumber: phone });
              this.setState({ authPage: 'Validate' });
            } else if (response.status === 'alreadyTakenPhone') {
              this.setState({ alreadyTakenPhone: true });
            } else {
              this.setState({ success: false });
            }
          })
          .catch(e => {
            if (e.details && e.details.status === 'alreadyTakenPhone') {
              this.setState({ alreadyTakenPhone: true });
            } else {
              console.error(e);
              this.setState({ success: false });
            }
            this.setState({ sendVerificationInProgress: false })
          });
      } else if (type === 'check') {
        const code = values.code;
        const phone = this.state.phoneNumber;

        this.setState({ checkVerificationInProgress: true })

        onCheckVerification({ phone, code })
        .then(response => {

          if(!this.state.checkVerificationInProgress){
            return
          }

          this.setState({ checkVerificationInProgress: false })
          if (response.status === 'approved') {
            const phoneNumber = phone;
            this.setState({ authPage: 'SignUp' });
            const { fname,email,  ...rest } = this.state.signupValues;
            const firstName = getFirstName(this.state.signupValues.fname);

            const params = {
              firstName: firstName.trim(),
              lastName: getLastName(fname).trim() ? getLastName(fname).trim() : " ",
              phoneNumber,
              email,
              ...rest,
            };
            logEvent('Phone Verified', { ['phone number']:  phone });
            amplitude.trackEvent('Successful Sign Up', { email });
            submitSignup(params);
            deleteSubscriberInSegment({
              email,
              segmentIds: [process.env.REACT_APP_FLODESK_NO_SIGN_UP]
            })
            
          } else if (response.status === 'Invalid') {
            this.setState({ success: false });
          } else if (response.status === 'maxAttempts') {
            this.setState({ maxAttempts: true });
          }
        })
          .catch(e => {
            this.setState({ checkVerificationInProgress: false })
            //este console.log no es el que sale
            console.log(e);
          });
      } else if (type === 'resend') {
        const phone = this.state.phoneNumber;
        onSendVerification({ phone })
          .then(response => {

            if (response.status === 'pending') {
              this.setState({ verificationModalClose: true });
              this.setState({ checkVerificationModalClose: false });
            } else {
              this.setState({ success: false });
            }
          })
          .catch(e => {
            this.setState({ success: false });
          });
      }
    };

    const registerLink = (
      <InlineTextButton onClick={() => this.setState({authPage: "SignUp"})} className={css.recoveryLink}>
        <FormattedMessage id="LoginForm.registerText" />
      </InlineTextButton>
    );

    const loginLink = (
      <InlineTextButton onClick={() => this.setState({authPage: "Login"})} className={css.recoveryLink}>
        <FormattedMessage id="LoginForm.loginText" />
      </InlineTextButton>
    )

    const formContent = (
      <div className={css.content}>
        {isLogin && (this.state.authPage === 'Login' || this.state.authPage === 'SignUp') ? (
          <div className={css.label}>¡Bienvenida de vuelta Gloser!</div>
        ) : (this.state.authPage === 'Login' || this.state.authPage === 'SignUp') && (
          <div className={css.label}>¡Usa el código "HOLAGLOSET" para $150 en tu primera compra!</div>
        )}
        {/* {(this.state.authPage === 'Login' || this.state.authPage === 'SignUp') && <SectionNavigation className={css.tabs} authPage={this.state.authPage} setAuthPage={(value) => this.setState({authPage: value })}/>} */}
        {loginOrSignupError}
        {isLogin && this.state.authPage === 'Login' ? (
          <>
            <h2>Iniciar sesión</h2>
            <LoginForm className={css.form} onSubmit={this.submitLogin} inProgress={authInProgress} />
            <div className={css.signupButton}>
              <span>
                <FormattedMessage
                  id="LoginForm.goToSignUp"
                  values={{ registerLink }}
                />
              </span>
            </div>
          </>

        ) : this.state.authPage === 'SignUp' && (
          <>
            <h2>Registrate</h2>
            <SignupForm
              className={css.form}
              onSubmit={values => {
                handleSubmitSignup(values, 'signup')
              }}
              inProgress={authInProgress}
              onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
              onOpenPrivacyPolicy={() => this.setState({ privacyModalOpen: true })}
              referredCodeValid={this.state.referredCodeValid}
              resetReferredCodeStatus={() => this.setState({referredCodeValid: true})}
            />
            <div className={css.signupButton}>
              <span>
                <FormattedMessage
                  id="LoginForm.goToLogin"
                  values={{ loginLink }}
                />
              </span>
            </div>
          </>
         
        )}      
        {this.state.authPage === 'Validate' && (
        <>
          <CheckVerificationSection
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmitEnquiry={values => handleSubmitSignup(values, 'check')}
            resendCode={values => handleSubmitSignup(values, 'resend')}
            success={this.state.success}
            maxAttempts={this.state.maxAttempts}
            maxAttemptsChange={maxAttemptsChange}
            useModal={false}
            inProgress={this.state.checkVerificationInProgress}
            disabled={this.state.checkVerificationInProgress}
          />
        </>
        )}
        {this.state.authPage === 'Verify' && (
        <>
          <VerificationSection
            onManageDisableScrolling={onManageDisableScrolling}
            onSubmitEnquiry={values => handleSubmitSignup(values, 'send')}
            success={this.state.success}
            alreadyTakenPhone={this.state.alreadyTakenPhone}
            changePhoneNumber={changePhoneNumber}
            useModal={false}
            inProgress={this.state.sendVerificationInProgress}
            disabled={this.state.sendVerificationInProgress}

          />
        </>
        )}
      </div>
    );

    const name = user.attributes.profile.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <NamedLink className={css.verifyClose} name="LandingPage">
          <span className={css.closeText}>
            <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </NamedLink>
        <IconEmailSent className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </p>
        </div>
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = isLogin
      ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
      : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

    const topbarClasses = classNames({
      [css.hideOnMobile]: showEmailVerification,
    });

    return (
      <Modal
        id="AuthenticationPageModalWrapper.authForm"
        isOpen={this.props.authContainerOpen}
        onClose={() => {
          this.props.closeAuthModal()
          this.setState({
            tosModalOpen: false,
            privacyModalOpen: false,
            verificationModalClose: true,
            checkVerificationModalClose: true,
            signupValues: null,
            phoneNumber: null,
            success: true,
            maxAttempts: false,
            alreadyTakenPhone: false,
            authPage: 'Login',
            referredCodeValid: true
          });
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal

        containerClassName={css.container}
        className={css.isOpen}
        scrollLayerClassName={css.scrollLayer}
        isClosedClassName={css.isClosed}

        closeButtonClassName={css.close}
      >

        <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <div className={css.formContainer}>
                <img className={css.imageSrc} src={Image} />
                {showEmailVerification ? emailVerificationContent : formContent}
              </div>
            </div>
        </LayoutWrapperMain>

      </Modal>
          
    );
  }
}

AuthenticationPageModalWrapperComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageModalWrapperComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, status, phoneNumber } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    status,
    phoneNumber,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendVerification: phone => dispatch(sendVerif(phone)),
  onCheckVerification: ({ phone, code }) => dispatch(checkVerif(phone, code)),
  onValidateReferredCode: (code) => dispatch(validateReferredCode(code)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPageModalWrapper = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageModalWrapperComponent);

export default AuthenticationPageModalWrapper;
