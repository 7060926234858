import React from 'react';
import {Button, Modal, FieldPhoneNumberInput, Form } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import * as validators from '../../util/validators';


import { Form as FinalForm, Field } from 'react-final-form';

import css from './CheckoutPage.css';

const isNonEmptyString = val => {
  return typeof val === 'string' && val.trim().length > 0;
};

const validatePhone = (value) => {
  if (typeof value === 'undefined' || value === null || !isNonEmptyString(value)) {
    // undefined, null values, or non-empty strings are invalid
    return false;
  }

  // Adjusted regular expression to enforce correct phone number validation
  // This regex matches either exactly 10 digits, or a '+' followed by one or more digits, and then exactly 10 digits
  const phoneRegex = /^(?:\+\d{1,3})?\d{10}$/;
  const isValidPhone = phoneRegex.test(value);

  if (!isValidPhone) {
    return false;
  }

  return true;
};

const SectionPhoneNumber = props => (
    <FinalForm
      {...props}
      render={formRenderProps => {

        const {
          isPhoneNumberModalOpen,
          onClosePhoneNumberModal,
          handleSubmit,
          manageDisableScrolling,
          updateInProgress,
          updateError,
          setPhoneNumber,
        } = formRenderProps;

        const isPhoneValid = validators.isPhoneValid("Teléfono no es válido");
  
      return (
          <div  className={css.nameContainer}>
          <Form
          className={css.form}
          onSubmit={handleSubmit}
          >         

            <Field name={"phoneNumber"}>
              {({ input, meta }) => {

                const customOnChange = (e) => {
                  // Call React Final Form's onChange to ensure the form state is updated

                  const phoneNumber = e.target.value;
                  const isPhoneNumberValid = validatePhone(e.target.value)

                  setPhoneNumber(phoneNumber, isPhoneNumberValid)
                  input.onChange(e);
                };

                return (
                  <FieldPhoneNumberInput
                  inputRootClass={css.phoneNumber}
                  type="text"
                  name="phoneNumber"
                  id={'phoneNumber'}
                  onChange={customOnChange}
                />
                )
              }}
              </Field>
          </Form>
      </div>
      )

      }}
      />

)

export default SectionPhoneNumber
