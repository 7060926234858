import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldUsername } from '../../components';

import { logEvent } from '../../util/logsEvent';

import css from './SignupForm.css';
import { useEffect } from 'react';

const KEY_CODE_ENTER = 13;

const valuesKeyForm = {
  fname: 'first name',
  lname: 'last name',
  email: 'email',
  password: 'password',
};


const processText = (text) => {
  return text
    .toLowerCase()         // Convert to lowercase
    .replace(/\s/g, '')    // Remove spaces
    .replace(/[^a-z0-9]/g, ''); // Remove special characters, keeping only alphanumeric
};


const SignupFormComponent = props => {
  const [enableFirstLogEvent, setEnableFirstLogEvent] = useState(false);

  const handleModifyForm = values => {
    for (let clave in values) {
      if (values.hasOwnProperty(clave)) {
        let valor = values[clave];
        if (typeof valor === 'string' && valor.length > 3) {
          return clave; //
        }
      }
    }
    return null;
  };

  const logFirstCharacter = values => {
    if (!enableFirstLogEvent) {
      const firstInput = handleModifyForm(values);
      if (firstInput) {
        setEnableFirstLogEvent(true);
        logEvent('signup started', { ['input name']: valuesKeyForm[firstInput] });
      }
    }
  };

  useEffect(() => {
    //logEvent('Sign Up Page Viewed');
  }, []);
  
  

  const [isReferredCode, toggleIsReferredCode] = useState(false);
  const handleIsReferredCode = () => {
    toggleIsReferredCode(pre => !pre);
  }

  return <FinalForm
    {...props}
    initialValues={{
      referredCode: props.referredCode,
    }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        onOpenPrivacyPolicy,
        referredCode,
        handleUsernameValidation,
        validateUserNameInProgress,
        validateUserNameError,
        userNameAvailable,
        referredCodeValid,
        resetReferredCodeStatus
      } = fieldRenderProps;

      // email
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });

      const usernamePlaceholder = intl.formatMessage({
        id: 'SignupForm.usernamePlaceholder',
      });

      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );



      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });

      const usernameRequiredErrorMessage = intl.formatMessage({
        id: 'SignupForm.usernameRequired',
      });

      const usernameAlreadyTakenMessage = intl.formatMessage({
        id: 'SignupForm.usernameTaken'
      })

      const firstNameRequired = validators.required(firstNameRequiredMessage);

      const usernameRequired = validators.required(usernameRequiredErrorMessage);

      // lastName
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      //phone number

      const referralCodePlaceholder = intl.formatMessage({
        id: 'SignupForm.referralCodePlaceholder',
      })


      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const handlePrivacyKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenPrivacyPolicy();
        }
      };
      const privacyPolicy = (
        <span
          className={css.termsLink}
          onClick={onOpenPrivacyPolicy}
          role="button"
          tabIndex="0"
          onKeyUp={handlePrivacyKeyUp}
        >
          <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </span>
      );
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (

        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.containerFormRegister} >

            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              placeholder={firstNamePlaceholder}
              validate={firstNameRequired}
            />
            <FieldTextInput
              className={css.email}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />

            {!isReferredCode && !referredCode ?
              <div className={css.referralCode} style={{ width: '100%', marginRight: '15px' }}><LinkReferredCode onClick={handleIsReferredCode} /></div> :
              (
                <Field
                name="referredCode"
                render={({ input }) => (
                  <input
                    {...input}
                    className={css.referralCode}
                    type="text"
                    id={formId ? `${formId}.referredCode` : 'referredCode'}
                    placeholder={referralCodePlaceholder}
                    disabled={!!referredCode}
                    onFocus={() => resetReferredCodeStatus()}
                    onChange={(e) => {
                      const processedText = processText(e.target.value);
                      input.onChange(processedText);
                    }}
                  />
                )}
              />
              )
            }

          <div className={!referredCodeValid ? css.errorMessage : css.invisible}>Código referidos no válido</div>

          </div>


          <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                logFirstCharacter(values);
                return null;
              }}
            </FormSpy>

          <div className={css.bottomWrapper}>
            {/* <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ privacyPolicy, termsLink }}
                />
              </span>
            </p> */}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
};


SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,
  onOpenPrivacyPolicy: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;

const LinkReferredCode = ({ onClick }) => {

  return <button className={css.linkReferredCode} type={'button'} onClick={onClick}>¿Tienes código de referido?</button>
}