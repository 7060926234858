import React from 'react';
import { CarouselProvider, Slider, Slide, ImageWithZoom, Image, Dot, ButtonNext, ButtonBack } from 'pure-react-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import css from './ListingPage.css';

const ReloveSectionImages = props => {
  const {
    listing,
    isMobile,
  } = props;

  const hasImages = listing.images && listing.images.length;

  const filteredSlides = hasImages ? listing.images.filter(image => !!image.attributes.variants['scaled-xlarge']) : []

  const totalSlides = hasImages ? filteredSlides.length : 0; 

  return (
    <div className={css.sectionImages}>
    {isMobile ? (
      <CarouselProvider
        className={css.mobileCarouselWrapper}
        naturalSlideHeight={350}
        naturalSlideWidth={350}
        totalSlides={totalSlides}
        infinite={true}
      >
        <Slider className={css.mobileCarouselSlider}>
          {hasImages ? filteredSlides.map((image, index) => (
            <Slide key={index} index={index}>
              <Image
                className={css.imageMobile}
                src={image.attributes.variants['scaled-xlarge'].url}
              />
            </Slide>
          )) : null}
        </Slider>
        {totalSlides > 1 ? (
          <>
            <ButtonBack className={css.carouselBackButton}><ChevronLeftIcon /></ButtonBack>
            <ButtonNext className={css.carouselNextButton}><ChevronRightIcon /></ButtonNext>
          </>
        ) : null}
      </CarouselProvider>
    ) : (
      <CarouselProvider
        className={css.desktopCarouselWrapper}
        naturalSlideHeight={500}
        naturalSlideWidth={500}
        totalSlides={totalSlides}
        orientation="vertical"
        dragEnabled={false}
      >
        <div className={css.carouselNavigator} >
          {hasImages ? filteredSlides.map((image, index) => (
            <Dot key={index} slide={index} className={css.carouselDot}>
              <Image
                src={image.attributes.variants['square-small'].url}
              />
            </Dot>
          )) : null}
        </div>
        <Slider className={css.carouselSlider}>
          {hasImages ? filteredSlides.map((image, index) => (
            <Slide key={index} index={index}>
              <ImageWithZoom
                className={css.imageWithZoom}
                src={image.attributes.variants['scaled-xlarge'].url}
              />
            </Slide>
          )) : null}
        </Slider>
      </CarouselProvider>
    )}
    </div>
  );
};

export default ReloveSectionImages;
