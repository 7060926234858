import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  ReloveIconFavoriteVariant,
} from '../../components';

import css from './AboutPage.css';
import image from './About us.png';

const AboutPage = () => {

  // prettier-ignore
  return (
    <StaticPage
      title="Sobre nosotras"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Sobre Gloset',
        name: 'Sobre nosotras',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.bgCircle}></div>
          <Grid className={css.pageHeader} container>
            <Grid item xs={12} sm={6} className={css.imageWrapper} >
              <img className={css.roundImage} src={image} alt="Nosotras" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1 className={css.pageTitle}>Sobre<br />Nosotras</h1>
              <h2 className={css.pageSubtitle}>
                <i>Queremos empoderar a nuestra comunidad, a través de la moda, ayudando al mundo una prenda a la vez.</i> 
              </h2>
              <p className={css.quote}>- Emma y Sofía <ReloveIconFavoriteVariant className={css.quoteIcon}/></p>
            </Grid>
          </Grid>
          <div className={css.contentWrapper}>
            <p className={css.firstLine}>
              ¡Todos merecemos una segunda oportunidad incluso tu ropa!
            </p>

            <p>
              Con ese fin nace <span className={css.featuredLine}>Gloset</span>, una plataforma de compra 
              y venta de ropa de segunda mano, claro, en buen estado y siempre a la moda.
            </p>

            <p>
              ¿Sabías que la moda es una de las industrias más contaminantes del mundo? 
              Ponte a pensar... <span className={css.featuredLine}>si la moda es una manera de expresar nuestra personalidad</span>, 
              sentimientos y en fin quienes somos, lo que compramos y <span className={css.featuredLine}>donde 
              lo compramos tiene un gran significado.</span>
            </p>

            <p>
              Para ponerlas más o menos en perspectiva: se espera que la moda consuma una cuarta parte del presupuesto 
              mundial de carbono para el 2050, y tan solo una blusa requiere 700 galones de agua para producirse. 
              Es por ello que <span className={css.featuredLine}>cada decisión de compra que tomamos influye no solo 
              en nuestros closets si no en nuestro planeta.</span>
            </p>

            <p>
              <span className={css.featuredLine}>Gloset</span> es un sueño de Emma y Sofía, amantes de la moda y de 
              encontrar tesoros en los closets para darles una segunda vida. En <span className={css.featuredLine}>Gloset</span>, 
              queremos reinventar la manera de comprar, de vestir y claro de vender a segunda mano. Nuestro <span className={css.featuredLine}>compromiso</span> es 
              inspirar a nuestras comunidades de compradoras a 
              pensar primero en segunda mano. Y un súper tip, no solo ayudas al medio ambiente, sino también a tu cartera.  
            </p>
            <p className={css.fillText}>
              Se parte del club y podrás encontrar los mejores looks a un mejor precio.
            </p>
            <h3 id="contact" className={css.subtitle} style={{scrollMarginTop: '100px'}}>
              Contacto
            </h3>
            <p>
              Hola Gloser, si tienes alguna duda, pregunta, queja o felicitación estás en el lugar correcto. 
              Puedes consultar nuestro <ExternalLink href="https://www.notion.so/gloset/PREGUNTAS-FRECUENTES-cd21a5653f8346f381b35fa0691b16a6">Centro de soporte en preguntas frecuentes</ExternalLink>{' '} 
              para obtener respuestas rápidas.
            </p>
            <p>
              Para reportar incidentes o si solo quieres platicar mándanos correo a <ExternalLink href="mailto:soporte@gloset.mx?Subject=Reporte de incidente de Gloset">soporte@gloset.mx</ExternalLink> 
              {' '}y te atenderemos en cuanto antes. 
            </p>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
