import React, { useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import FieldSelect from '../../../components/FieldSelectSimple/FieldSelectSimple';
import css from './ReloveEditListingForm.css';

const ResetOnChange = ({ field, set }) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {() => {
              onChange(undefined);
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

const ReloveCategorySelectField = props => {
  const {
    categoryName,
    categoryId,
    categoryLabel,
    categoryPlaceholder,
    categoryValidator,
    subCategoryName,
    subCategoryId,
    subCategoryLabel,
    subCategoryPlaceholder,
    subCategoryRequired,
    options,
    sizeName,
    className,
    errorMessage,
    isUpdate
  } = props;

  const [subCategoryTouched, setSubCategoryTouched] = useState(false);

  return options ? (
    <React.Fragment>
      <ResetOnChange field={categoryName} set={subCategoryName} />
      <ResetOnChange field={categoryName} set={sizeName} />

      <FieldSelect
        className={className || css.category}
        name={categoryName}
        id={categoryId}
        label={categoryLabel}
        validate={categoryValidator}
        options={options}
      >
        <option value="" className={css.placeholderOption}>
          {categoryPlaceholder}
        </option>
        {options.map(c => (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        ))}
      </FieldSelect>

      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          const category = options.find(group => group.key === values[categoryName]);
          const subOptions = category ? category.options : [];

          const subCategoryValidator = errorMessage ? undefined : subCategoryRequired;
          const showCustomError = (isUpdate || subCategoryTouched) && errorMessage && !values[subCategoryName] && subOptions.length > 0;

          return (
            <div className={css.subCategoryContainer}>
              <FieldSelect
                className={css.category}
                name={subCategoryName}
                id={subCategoryId}
                label={subCategoryLabel}
                disabled={!subOptions.length}
                options={subOptions}
                placeholder={subCategoryPlaceholder}
                onBlur={() => setSubCategoryTouched(true)}
              >
                <option value="">
                  {subCategoryPlaceholder}
                </option>
                {subOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
              
              {showCustomError && (
                <div className={css.validationError}>
                  {errorMessage}
                </div>
              )}
            </div>
          );
        }}
      />
    </React.Fragment>
  ) : null;
};

export default ReloveCategorySelectField;
