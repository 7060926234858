import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink, ReloveIconFavorite, ExternalLink } from '../../components';

import css from './SectionHowItWorks.css';

import Image1 from '../../assets/misc/howitworks-1.jpg';
import Image2 from '../../assets/misc/howitworks-2.jpg';
import Image3 from '../../assets/misc/howitworks-3.jpg';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div id="howItWorks" className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
      </div>
      <p className={css.subtitle}>
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
      </p>
      <div className={css.steps}>
        <div className={css.step}>
          <h2 className={classNames(css.stepTitle, css.stepTitle1)}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h2>
          <div className={css.stepImage} style={{backgroundImage: `url(${Image1})`}}></div>
          <ol className={css.stepContent}>
            <li>¡Tómale buenas fotos a tus artículos!</li>
            <li>Da click en "VENDE", sube tus fotos y completa tu información (para pagarte)</li>
            <li>Comparte y promociona tu ropa</li>
            <li>Empácala bonito y envíala con nuestras guías prepagadas</li>
            <li>¡Gana dinero! Llévate hasta el 86% de tu venta. Encuentra nuestro esquema de comisiones en <ExternalLink href="https://www.notion.so/gloset/PREGUNTAS-FRECUENTES-cd21a5653f8346f381b35fa0691b16a6">Preguntas Frecuentes.</ExternalLink></li>
          </ol>
        </div>
        <div className={css.step}>
          <h2 className={classNames(css.stepTitle, css.stepTitle2)}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h2>
          <div className={css.stepImage} style={{backgroundImage: `url(${Image2})`}}></div>
          <ol className={css.stepContent}>
            <li>¡Descubre nuevas prendas cada día!</li>
            <li>Si las quieres comprar más tarde, dales like <ReloveIconFavorite /> para guardarlas</li>
            <li>Cómpralas u ofértalas a precios atractivos</li>
            <li>Recíbelas a la puerta de tu casa</li>
          </ol>
        </div>
        <div className={css.step}>
          <h2 className={classNames(css.stepTitle, css.stepTitle3)}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h2>
          <div className={css.stepImage} style={{backgroundImage: `url(${Image3})`}}></div>
          <div className={css.stepContent}>
            <p>Si no es lo que ordenaste, ¡te devolvemos tu dinero!</p>
            <p>Todos los envíos son por medio de Estafeta y los pagos por medio de Stripe, un portal súper seguro.</p>
          </div>
        </div>
      </div>
      <div className={css.createListingLink}>
        <NamedLink name="NewListingPage">
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
