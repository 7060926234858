import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconFavoriteVariant.css';

const ReloveIconFavoriteVariant = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 150.7 196.5"
      style={{ enableBackground: 'new 0 0 150.7 196.5' }}
      xmlSpace="preserve">
        <path d="M147.9,33.7c-0.6-4.2-2-8.3-4.1-12c-2.9-5-7.3-10.1-12.5-12.8c-20.4-10.5-36.6,13.8-46.4,28
          c-5.9,8.6-9.9,18.1-12.6,28c-2.2-7.1-4.7-14-7.4-20.9c-3.2-8.3-7-16.3-12.6-23.3C46.8,13.9,38.5,8.7,29.6,9.8
          c-6.1,0.8-11.4,3.4-16.2,7.2c-4.1,3.3-6.8,8.3-8,13.4C4.2,35.2,4,40.2,4.2,45c0.2,4.8,0.6,9.6,1.2,14.4c3,23.4,11.4,45.5,22.1,66.4
          c10.3,20.2,22.7,39.2,34.8,58.3c1.3,2.1,2.6,4.2,4,6.2c0.3,0.7,0.8,1.4,1.4,2c0.4,0.4,0.9,0.7,1.4,1c0.1,0.1,0.2,0.1,0.3,0.1
          c0,0,0.1,0,0.1,0.1c1.5,0.8,3.2,1.1,4.8,0.8c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c1.3-0.3,2.5-0.9,3.4-2c17.5-21,32.9-43.8,45.8-67.9
          c10.1-18.8,18.4-38.9,22.6-59.9C148.4,54.5,149.5,44,147.9,33.7 M132.4,31.4c0,0,0-0.1-0.1-0.3C132.4,31.2,132.4,31.3,132.4,31.4
           M89.8,58C89.8,58.1,89.8,58.1,89.8,58 M30.3,24.2c-0.2,0-0.4,0.1-0.4,0.1C29.9,24.3,30.1,24.2,30.3,24.2 M133.8,51.5
          c-0.1,0.5-0.1,1.1-0.2,1.6c0,0.2-0.1,0.7-0.1,1c-0.1,0.4-0.1,0.8-0.1,0.9c-0.1,0.7-0.2,1.4-0.3,2.1c-1.7,10-4.5,19.8-7.9,29.3
          c-0.8,2.3-1.7,4.6-2.6,6.8c-0.4,1.1-0.9,2.2-1.3,3.3c-0.1,0.2-0.6,1.4-0.5,1.2c-0.1,0.3-0.2,0.5-0.3,0.8c-1.8,4.2-3.8,8.4-5.9,12.5
          c-5.9,11.4-12.3,22.6-19.3,33.4c-3.5,5.4-7.1,10.8-10.8,16.1c-1.9,2.7-3.8,5.3-5.8,7.9c-0.9,1.2-1.9,2.5-2.8,3.7
          c-0.2,0.3-0.4,0.5-0.6,0.8c-0.6,0.7-1.1,1.4-1.7,2.1c-9.7-15.3-19.6-30.6-28.3-46.5c-2.6-4.8-5.2-9.6-7.5-14.5
          c-1.2-2.5-2.4-5-3.5-7.5c-0.5-1.2-1.1-2.4-1.6-3.5l-0.4-0.9c0-0.1-0.1-0.2-0.2-0.4c-0.2-0.5-0.4-0.9-0.6-1.4
          C27.3,90.2,24,80,21.7,69.6c-0.6-2.6-1.1-5.3-1.5-8c-0.1-0.6-0.2-1.2-0.3-1.8c0-0.2-0.1-0.5-0.1-0.7c-0.2-1.3-0.3-2.6-0.4-3.9
          c-0.4-4.3-0.7-8.6-0.8-12.9c0-2,0.2-4,0.3-6c0-0.1,0-0.3,0.1-0.4c0.1-0.5,0.2-0.9,0.3-1.4c0.2-1,0.8-2,0.9-3c0,0,0,0,0,0
          c0.2-0.5,0.5-0.9,0.7-1.4c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.2,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.1,0.1-0.2
          c0,0.1-0.1,0.1-0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.4-0.4,0.7-0.7,1.1-1c0.1,0,0.1-0.1,0.2-0.1c1.1-0.7,2.1-1.4,3.3-2
          c0.3-0.2,0.6-0.3,0.9-0.5c0.2-0.1,0.3-0.1,0.4-0.2c0.9-0.3,1.8-0.5,2.7-0.7c0.4,0,0.8,0,1.2,0c0.3,0,0.6,0,0.9,0
          c0.7,0.1,1.4,0.4,2.1,0.6c0.4,0.2,0.7,0.4,1.1,0.6c0.6,0.3,1.2,0.7,1.7,1.1c0.4,0.3,0.7,0.6,1.1,1c0.6,0.6,1.2,1.2,1.8,1.8
          c0.3,0.3,0.6,0.7,0.9,1c0.1,0.1,0.2,0.2,0.2,0.3c1,1.4,2,2.9,2.9,4.4c1,1.7,1.9,3.3,2.8,5.1c0.8,1.6,1.8,3.3,2.3,5.1
          c0,0-0.8-1.8-0.3-0.8c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.3,0.8,0.5,1.2c0.5,1.3,1,2.6,1.5,3.9c1.1,2.7,2.1,5.5,3.1,8.3
          c2,5.6,3.9,11.3,5.5,17c1.7,5.8,3.1,11.7,4.2,17.6c0.2,1.3,0.5,2.6,0.7,3.9c0.1,0.7,0.2,1.5,0.4,2.2c0.1,0.4,0.1,0.7,0.2,1.1
          c0,0.1,0.1,0.5,0.1,0.8c0,0.1,0,0.3,0,0.4c0,0.3,0.1,0.6,0.1,0.8c0.1,0.7,0.2,1.5,0.2,2.2c0.2,1.6,0.3,3.2,0.4,4.8
          c0.2,2.9,0.3,5.8,0.3,8.8c0,3.8,3.3,7.4,7.3,7.3c3.9-0.2,7.3-3.2,7.3-7.3c0-5.8,0-11.7,0.3-17.5c0.1-2.8,0.3-5.7,0.5-8.5
          c0.1-1.4,0.2-2.8,0.4-4.3c0.1-0.7,0.2-1.5,0.3-2.2c0-0.3,0.1-0.7,0.1-1c0.8-5.6,1.9-11.2,3.5-16.7c0.8-2.8,1.7-5.5,2.8-8.2
          c0.2-0.6,0.5-1.2,0.7-1.8c0.1-0.3,0.2-0.5,0.4-0.8c0.6-1.4,1.3-2.7,2-4c2.3-4.4,5.1-8.5,7.9-12.5c1.2-1.7,2.5-3.5,3.7-5.2
          c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.5-0.6,0.8-1c0.7-0.9,1.5-1.8,2.2-2.7c1.5-1.7,3-3.3,4.6-4.8c0.6-0.6,1.3-1.2,2-1.7
          c-0.3,0.2,0.1-0.1,0.3-0.3c0,0,0,0,0.1,0c0,0,0,0,0,0c0.2-0.2,0.4-0.3,0.6-0.4c-0.2,0.1-0.3,0.2-0.4,0.3c0.4-0.3,0.8-0.6,1.2-0.8
          c0.8-0.5,1.5-0.9,2.3-1.4c0.1,0,0.2-0.1,0.4-0.2c0.3-0.1,0.7-0.3,0.8-0.3c0.4-0.1,0.9-0.3,1.4-0.4c0,0,0.1,0,0.1,0
          c0.3,0,0.5,0,0.8,0c0.2,0,0.8,0.1,1.2,0.1c0.4,0.1,0.7,0.2,1.1,0.3c0.5,0.3,1,0.5,1.4,0.8c0.4,0.3,0.8,0.6,1.2,0.9
          c-0.4-0.2-0.6-0.4-0.8-0.5c0.2,0.1,0.6,0.4,0.9,0.6c1,0.9,1.9,1.9,2.8,3c0,0,0,0,0,0c0.2,0.3,0.4,0.5,0.6,0.8
          c0.4,0.5,0.7,1.1,1.1,1.6c0.4,0.7,0.8,1.4,1.2,2.2c0.1,0.3,0.2,0.6,0.3,0.9c0.3,0.9,0.6,1.8,0.8,2.7c0.2,0.9,0.4,1.8,0.6,2.7
          c0,0,0,0.1,0,0.2c0,0.4,0.1,1.1,0.1,1.2c0.2,2.1,0.3,4.2,0.2,6.4C134.2,47.1,134,49.3,133.8,51.5"/>
      </svg>
  );
};

ReloveIconFavoriteVariant.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconFavoriteVariant.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconFavoriteVariant;
