// PaymentButton.js
import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2'; // Example library: react-paypal-button-v2

export default function PaymentButton(props){


  const {
    amount,
    handlePaypalInit,
    handlePaypalPurchase,
    onCreatePaypalOrder,
    onApprovePayment,
    isShippingOptionBeingChanged,
    shouldButtonBeDisabled
  } = props

  const createOrder = (data, actions) => {
    return onCreatePaypalOrder(amount)
    // return axios.post('/api/paypal/create-order') // Endpoint to create the payment order on the server
      .then((response) => {
        return response.ecToken;
      })
      .catch((error) => {
        console.error('Failed to create payment order:', error);
      });
};

  const onApprove = (data, actions) => {
    return handlePaypalInit()
      .then(transaction => {
    return onApprovePayment(data.paymentID, data.payerID)
      .then((response) => {
        // Handle successful payment completion on the client
        handlePaypalPurchase(response.pay_id)
      })
      .catch((error) => {
        console.error('Failed to approve payment:', error);
      });
    })
  };

  return !isShippingOptionBeingChanged && !shouldButtonBeDisabled && amount > 0 && (
    <PayPalButton
      createOrder={createOrder}
      onApprove={onApprove}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_ID,
        currency:"MXN",
        disableFunding: 'card',
      }}
    />
  );
};
