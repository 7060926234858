import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import { nonEmptyArray, composeValidators } from '../../../util/validators';
import { AddImages } from '../../../components';
import { isUploadImageOverLimitError } from '../../../util/errors';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './ReloveEditListingForm.css';

import Resizer from "react-image-file-resizer";

const ACCEPT_IMAGES = 'image/*';

const resizeFile = (file) =>
  new Promise((resolve) => {
  
    let compression;
    
    if(file.size > 1000000){
      compression = 70;
    }else{
      compression = 100
    }


    return Resizer.imageFileResizer(
      file,
      2400,
      2400,
      "JPEG",
      compression,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
    );
  });

function RelovePhotosDropzone(props) {
  const {
    className,
    disabled,
    fetchErrors,
    form,
    intl,
    images,
    onRemoveImage,
    onUpdateImageOrder,
    imageOrder,
  } = props;

  const [imageUploadRequested, setImageUploadRequested] = useState(false);

  const onImageUploadHandler = useCallback((file, newCrop, index) => {
    if (file && newCrop) {
      setImageUploadRequested(true);
      props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file, originalFile:newCrop})
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }else if (file) {
      setImageUploadRequested(true);
      props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file, index})
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  }, [props]);

  const onImageUpload = (file, newCrop) =>{
    onImageUploadHandler(file, newCrop)
  }

  const onDrop = useCallback(acceptedFiles => {
    form.change(`images`, acceptedFiles);
    form.blur(`images`);

    acceptedFiles.forEach(async (file,index) => {
      let newFile = await resizeFile(file);
      onImageUploadHandler(newFile,null,index);
    });
  }, [form, onImageUploadHandler]);

  const {getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: ACCEPT_IMAGES,
    onDrop,
    disabled
  });

  const classes = classNames(css.dropzone, className);

  const { uploadImageError } =
    fetchErrors || {};
  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError && uploadImageError.status === 400) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }

  const style = classNames(
    css.dropzoneBase,
    isDragActive ? css.dropzoneActive : '',
    isDragAccept ? css.dropzoneAccept : '',
    isDragReject ? css.dropzoneReject : ''
  );

  return (
    <div className={classes}>
      <AddImages
        className={css.imagesField}
        images={images}
        wrapperClassName={css.dropzoneThumbnails}
        thumbnailClassName={css.thumbnail}
        savedImageAltText={intl.formatMessage({
          id: 'EditListingPhotosForm.savedImageAltText',
        })}
        onRemoveImage={onRemoveImage}
        onUpdateImageOrder={onUpdateImageOrder}
        imageOrder={imageOrder}
        onImageUpload={onImageUpload}
      >
        <div {...getRootProps({className: style})}>
          <button type="button" className={css.dropzoneButton} onClick={open} disabled={imageUploadRequested}>
            Sube una foto
          </button>
          <input id="image" name="image" {...getInputProps()} disabled={imageUploadRequested}/>
          <p className={css.dropzoneSmallText}>o arrástrala aquí</p>
          <p className={css.dropzoneSpecsText}>.JPG, .GIF or .PNG max. 5mb</p>
        </div>
      </AddImages>
      {uploadImageFailed}
      <p className={css.tip}>
        Selecciona 2 o 3 imágenes que muestren tu producto en diferentes ángulos. Arrastra las imágenes para ordenarlas, la primera imagen será la imagen principal.
      </p>
    </div>
  );
}

export default RelovePhotosDropzone;
