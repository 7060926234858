/**
 Use this file to edit the Popular Trends on the Landing Page.
 Each trend is enclosed in curly braces ({}) followed by a comma (,), and contains the following elements:
 - id: an unique id, can be a number or a 'text' (enclosed in apostrophes)
 - name: The title for the Trend (enclosed in apostrophes)
 - image: An URL to the image you want to use (enclosed in apostrophes)
 - link: An URL to whatever you want the trend to take you to. It's preferred to used relative links (ej. '/s?keywords=Something' or '/u/123012739127301927310')
 Each of these elements is also followed by a comma (,).
*/

import Bolsas from './assets/trends/bolsas03.png';
import Descuento from './assets/trends/descuento.png';
import Vestidosmidi from './assets/trends/vestidomidi1.png';
import Mejoramiga from './assets/trends/mejoramiga1.png';

export const trends = [
  {
    id: 1,
    name: 'Boda Mejor Amiga',
    image: Mejoramiga,
    link: '/s?coleccion=mejoramiga&utm_source=gloset&utm_medium=page&utm_campaign=mejoramiga&utm_term=utm-generator',
  },
  {
    id: 2,
    name: 'Vestidos Midi',
    image: Vestidosmidi,
    link: '/s?pub_category=Vestidos-Midi&sort=-pub_updatedAt&utm_source=gloset&utm_medium=page&utm_campaign=midis&utm_term=utm-generator',
  },
  {
    id: 3,
    name: 'Rebajas 50% o más',
    image: Descuento,
    link: 's?coleccion=hot-deals&utm_source=gloset&utm_medium=page&utm_campaign=hot-deals&utm_term=utm-generator',
  },
  {
    id: 4,
    name: 'Bolsas Trendy',
    image: Bolsas,
    link: '/s?pub_category=Bolsas&pub_brand=Bolsas,ALEXANDER%20MCQUEEN,Alexander%20Wang,ALLSAINTS,ALO%20YOGA,BALENCIAGA,Bimba%20y%20Lola,BOTTEGA%20VENETA,BURBERRY,Carolina%20Herrera,Chanel,CHLOE,christian%20louboutin,Coach,COMME%20DES%20GARCONS,Cult%20Gaia,CULT%20GAIA,Dior,Fendi,FRAME,Free%20People,Ganni,Giuseppe%20Aanotti,GIUSEPPE%20DI%20MORABITO,GIVENCHY,Gucci,Isabel%20Marant,ISABEL%20MARANT,JACQUEMUS,jw%20pei,LOEWE,Marc%20Jacobs,MIU%20MIU,MONCLER,Nike,Nanushka,MUGLER,OFF-WHITE,ON,Prada,Rebecca%20Minkoff,RETROFETE,SAINT%20LAURENT,SIMON%20MILLER,STAUD,STELLA%20MCCARTNEY,THE%20ATTICO,THE%20ROW,TOM%20FORD,VALENTINO%20GARAVANI,VERSACE,YSL,Varley&track=Top%20marcas&utm_source=gloset&utm_medium=page&utm_campaign=bolsas&utm_term=utm-generator',
  }
]
