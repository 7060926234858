import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './ClabeForm.css';

const ClabeFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        updateClabeError,
        pristine,
        invalid
      } = fieldRenderProps;

      const clabeLabel = intl.formatMessage(
        {
          id: 'ClabeForm.clabeLabel',
        },
        { authorDisplayName }
      );
      const clabePlaceholder = intl.formatMessage(
        {
          id: 'ClabeForm.clabePlaceholder',
        },
        { authorDisplayName }
      );
      const clabeRequiredMessage = intl.formatMessage({
        id: 'ClabeForm.clabeRequired',
      });
      const clabeRequired = validators.requiredAndNonEmptyString(clabeRequiredMessage)
      const exactLength = validators.exactLength("La clabe debe de ser 18 digitos sin espacios", 18);

      const clabeValidation = validators.composeValidators(clabeRequired, exactLength)


      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h2 className={css.heading}>
            <FormattedMessage id="ClabeForm.heading" values={{ listingTitle }} />
          </h2>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="clabe"
            id={"formId"}
            label={clabeLabel}
            placeholder={clabePlaceholder}
            validate={clabeValidation}
          />
          <div className={submitButtonWrapperClassName}>
            {updateClabeError ? (
              <p className={css.error}>
                <FormattedMessage id="ClabeForm.updateClabeError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled || pristine || invalid}>
              <FormattedMessage id="ClabeForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ClabeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  updateClabeError: null,
};

ClabeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  updateClabeError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ClabeForm = compose(injectIntl)(ClabeFormComponent);

ClabeForm.displayName = 'ClabeForm';

export default ClabeForm;
