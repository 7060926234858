import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  EarningsSection
} from '../../components';

import { loadData, handlePayout,loadNonCriticalData, loadTranferPage } from './BalancePage.duck'

import css from './BalancePage.css'

import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

const { UUID } = sdkTypes;

const BalancePageComponent = (props) => {

  const hasLoadedData = React.useRef(false);
  const {
    currentUser,
    onManageDisableScrolling,
    stripeAccountDataExternalAccount,
    onHandlePayout,
    initiatePayoutInProgess,
    initiatePayoutError,
    userCounter,
    totalPending,
    transfers,
    totalPages,
    paginationInfo,
    onLoadNonCriticalData,
    onLoadTranferPage
  } = props;

  React.useEffect(() => {

    if (currentUser && !hasLoadedData.current) {

      onLoadNonCriticalData({userReferredCode: currentUser.attributes.profile.privateData.referralCode, userId: currentUser.id.uuid, stripeAccountId: currentUser?.stripeAccount?.attributes?.stripeAccountId});
      hasLoadedData.current = true;
    }
  }, [currentUser]); 
  

  if(!currentUser)   return (
    <Page title={"schemaTitle"} scrollingDisabled={true} >
      <LayoutSideNavigation className={css.layoutBalance} >
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="BalancePage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav/>
        <LayoutWrapperMain>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  )






  const userStripeAccountMaybe = currentUser?.stripeAccount?.attributes?.stripeAccountId;

  const userEarnings = currentUser.attributes.profile.privateData.userEarnings ? currentUser.attributes.profile.privateData.userEarnings: 0;

  const userCredits = currentUser.attributes.profile.privateData.userCredits ? currentUser.attributes.profile.privateData.userCredits : 0;

  const pendingCredits = totalPending ? totalPending : 0;
  

  const onHandlePayoutRequest = async () => {
    const userId = currentUser.id.uuid;
  
    try {
      const result = await onHandlePayout({
        userId,
        userStripeAccountMaybe,
        emailReceiver: currentUser.attributes.email,
        emailData: {
          userEarnings,
          last4: stripeAccountDataExternalAccount?.last4,
          name: `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`,
        },
      });
      return { success: true, result };
    } catch (error) {
      throw error;
    }
  };
  


  const onLoadNewPage = (currentPage, newPage) => {
    onLoadTranferPage({stripeAccountId:userStripeAccountMaybe, currentPage, action: 'specificPage', targetPage: newPage, paginationInfo})
  }

  return (
      <Page title={"Mi balance"} scrollingDisabled={false} >
        <LayoutSideNavigation >
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="BalancePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutBalance}>
            <EarningsSection
              userEarnings={userEarnings}
              userCredits={userCredits}
              pendingCredits={pendingCredits}
              onManageDisableScrolling={onManageDisableScrolling}
              userCounter={userCounter}
              userStripeAccountMaybe={userStripeAccountMaybe}
              stripeAccountDataExternalAccount={stripeAccountDataExternalAccount}
              onHandlePayoutRequest={onHandlePayoutRequest}
              initiatePayoutInProgess={initiatePayoutInProgess}
              initiatePayoutError={initiatePayoutError}
              depositHistoryData={transfers}
              totalPages={totalPages}
              paginationInfo={paginationInfo}
              onLoadNewPage={onLoadNewPage}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    )
}


const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onHandlePayout: (data) =>
    dispatch(handlePayout(data)),
  onLoadNonCriticalData: (data) => 
    dispatch(loadNonCriticalData(data)),
  onLoadTranferPage: (data) => 
    dispatch(loadTranferPage(data))

    
});

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    stripeAccountDataExternalAccount,
    initiatePayoutInProgess,
    initiatePayoutError,
    userCounter,
    totalPending,
    transfers,
    totalPages,
    paginationInfo,
  } = state.BalancePage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeAccountDataExternalAccount,
    initiatePayoutInProgess,
    initiatePayoutError,
    userCounter,
    totalPending,
    transfers,
    totalPages,
    paginationInfo,
  };
};


const BalancePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(BalancePageComponent);

BalancePage.loadData = (params, queryParams) => {
  return loadData();
};

export default BalancePage