import React, { useEffect } from 'react';
// import { Button } from '@material-ui/core';
import {ModalInMobile} from '..';
import { VerificationForm } from '../../forms';
 
import css from './VerificationSection.css';
import { logEvent } from '../../util/logsEvent';

const VerificationSection = props => {
  const {
    sendEnquiryInProgress,
    onSubmitEnquiry,
    onManageDisableScrolling,
    onCloseEnquiry,
    success,
    alreadyTakenPhone,
    changePhoneNumber,
    useModal,
    inProgress,
    disabled
  } = props;


  const handleOnCloseVerifications = ()=>{
    onCloseEnquiry();
    logEvent('Phone Verification Cancelled')
  }

  if(useModal){
    return (
      <div>
          <ModalInMobile
          id="UniqueIdForThisAddressAlert"
          isOpen={true}
          onClose={handleOnCloseVerifications}
          usePortal
          contentClassName={css.modalContent}
          onManageDisableScrolling={onManageDisableScrolling}
          >
          <VerificationForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
          onOpenTermsOfService={() => {
            return
          }}
          onOpenPrivacyPolicy={() => {
            return
          }}
          success={success}
          alreadyTakenPhone={alreadyTakenPhone}
          changePhoneNumber={changePhoneNumber}
          />
        </ModalInMobile>
      </div>
    )
  }else{
    return (
      <div>
          <VerificationForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress || inProgress}
          disabled={disabled}
          onOpenTermsOfService={() => {
            return
          }}
          onOpenPrivacyPolicy={() => {
            return
          }}
          success={success}
          alreadyTakenPhone={alreadyTakenPhone}
          changePhoneNumber={changePhoneNumber}
          />
      </div>
    )
  }
}
export default VerificationSection;