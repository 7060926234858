import React, { useState } from 'react';
import { Modal, NamedLink } from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './ListingPage.css';
import cssMaybe from './SectionFeatureMaybe.css';

function truncateText(texto, longitud) {
  if (texto.length <= longitud) {
    return texto;
  } else {
    return texto.substring(0, longitud) + '...';
  }
}

function prepareFeatures(listingData, filterSettings) {
  var features = {};
  //available
  filterSettings.forEach((filter) => {
    if (filter.id === 'description') {
      features[filter.label] = [listingData[filter.id]];
      return;
    }

    if (listingData[filter.id] !== undefined && filter.id !== 'available' && "config" in filter && "options" in filter.config) {
      let value = listingData[filter.id];

      if (!Array.isArray(value)) {
        let found = filter.config.options.find(({ key }) => key === value);
        features[filter.label] = found ? [found.label] : [value];
        return;
      }

      if (!['ReloveCategoryFilter', 'ReloveSizeFilter'].includes(filter.type)) {
        features[filter.label] = value.map(item => {
          let itemFound = filter.config.options.find(({ key }) => key === item);
          return itemFound ? itemFound.label : item;
        });
        return;
      }

      if (filter.type === "ReloveSizeFilter") {
        const shoeSizeOptions = findOptionsForSelectFilter('sizeShoes', config.custom.filters);
        const sizeOptions = findOptionsForSelectFilter('size', config.custom.filters);
        const accessoriesSizeOptions = findOptionsForSelectFilter('sizeAccessories', config.custom.filters);
        features[filter.label] = value.map(size => (
          sizeOptions.find(({ key }) => key === size)
          || shoeSizeOptions.find(({ key }) => key === size))?.label
          || accessoriesSizeOptions.find(({ key }) => key === size)?.label
          || size
        );
        return;
      }

      if (filter.type === "ReloveCategoryFilter") {
        features[filter.label] = [];

        let categoryItem = filter.config.options.find(({ key }) => key === value[0]);
        let categoryObject = {
          label: categoryItem ? categoryItem.label : value[0],
          key: value[0]
        }
        features[filter.label].push(categoryObject);


        if (value.length > 1) {
          let subcategoryItem = categoryItem ? categoryItem.options.find(({ key }) => key === value[1]) : null;

          let subcategoryObject = {
            label: subcategoryItem ? subcategoryItem.label : value[1],
            key: value[1]
          }
          features[filter.label].push(subcategoryObject);
        }
        return;
      }
    }
  })

  return features;
}

const SectionFeaturesMaybe = props => {
  const { publicData, description } = props;
  if (!publicData) {
    return null;
  }

  const filters = config.custom.filters;
  const values = prepareFeatures({ ...publicData, description }, filters);

  return (
    <div className={css.sectionFeatures}>
      {Object.entries(values).map(([key, value]) => {
        return (
          <div className={css.feature} key={key}>
            {(key !== 'Marca' && key !== 'Description' && key !== 'Condición') && <span style={{marginRight:'8px'}} className={key === 'Talla' ? css.featureSizeLabel : css.featureLabel}>{key}:</span>}
            <div className={css.featureValues}>
              {key === 'Marca' ?
                value.map(item => {
                  return (<NamedLink className={css.featureBrand} key={item} name="SearchPage" to={{ search: 'pub_brand=' + item }}>{item}</NamedLink>);
                }) :
                key === 'Condición' ?
                  value.map(item => {
                    return <ConditionText text={item} />;
                  }) :
                  key === 'Talla' ?
                    value.map((item, index) => {
                      return (
                        <NamedLink
                          key={item.key}
                          className={css.featureSize}
                          name="SearchPage" to={{ search: 'pub_category=' + item.key }}
                        >
                          { (value.length  - 1 ) > index? `${item}, ` : item }
                        </NamedLink>
                      );
                    }) :
                    key === 'Categoría' ?
                      value.map((item, index) => {
                        return (
                          <NamedLink
                            key={item.key}
                            className={css.featureValue}
                            name="SearchPage" to={{ search: 'pub_category=' + item.key }}
                          >
                            {(value.length  - 1 ) > index? `${item?.label}, ` : item?.label }
                          </NamedLink>
                        );
                      }) :
                      key === 'Description' ?
                        value.map(item => {
                          return <DescriptionText text={item} />
                        }) :
                        value.map((item, index) => {
                          return (<span style={{marginRight:'8px'}} className={css.featureValue} key={item}>{ (value.length  - 1 ) > index? `${item},` : item }</span>);
                        })

              }
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SectionFeaturesMaybe;

const ConditionText = ({ text }) => {
  const [openModal, setOpenModal] = useState(false)
  const handleOnClickModal = () => setOpenModal(!openModal)
  return (
    <div className={css.containerConditionText}>
      <div>
        <p className={css.featureValue}><span style={{marginRight:'8px'}} className={css.featureLabel}>Condición: </span>{text}</p>
      </div>
      <p onClick={handleOnClickModal} className={css.conditionTextMore} >Leer más sobre la condición del producto</p>
      <Modal
        customModalClass={cssMaybe.containerModalCondition}
        closeButtonClassName={cssMaybe.closeButton}
        scrollLayerClassName={cssMaybe.containerModalScroll}
        containerClassName={cssMaybe.containerModal}
        id='modal-condition' isOpen={openModal} onManageDisableScrolling={() => { }} onClose={handleOnClickModal}  >
        <div>
          <div>
            <h3 className={cssMaybe.titleModalCondition}>
              NUEVO, CON ETIQUETAS
            </h3>
            <p className={cssMaybe.descriptionModalCondition}>
              No tiene defectos ni alteraciones y cuenta con las etiquetas originales. En condición impecable.
            </p>
          </div>
          <div>
            <h3 className={cssMaybe.titleModalCondition}>
              NUEVO, SIN ETIQUETAS            </h3>
            <p className={cssMaybe.descriptionModalCondition}>
              No tiene defectos ni alteraciones. En condición impecable.
            </p>
          </div>
          <div>
            <h3 className={cssMaybe.titleModalCondition}>
              USADO, EN MUY BUENA CONDICIÓN
            </h3>
            <p className={cssMaybe.descriptionModalCondition}>
              Ha sido usado y puede mostrar ligeros signos de uso comunes en la ropa, como uso en la tela, pero ningún daño material. Puede tener alteraciones o detalles mínimos, y se pueden apreciar en las fotos o mencionar en la descripción.
            </p>
          </div>
          <div>
            <h3 className={cssMaybe.titleModalCondition}>
              USADO, EN BUENA CONDICIÓN
            </h3>
            <p className={cssMaybe.descriptionModalCondition}>
              Ha sido usado y muestra signos de uso comunes en la ropa, como uso en la tela. Puede tener alteraciones, detalles, o daños, y se pueden apreciar en las fotos o mencionar en la descripción.
            </p>
          </div>
          <div>
            <h3 className={cssMaybe.titleModalCondition}>
              USADO, CON VARIOS SIGNOS VISIBLES
            </h3>
            <p className={cssMaybe.descriptionModalCondition}>
              Ha sido usado y muestra signos de uso comunes en la ropa. Tiene detalles o daños y se pueden apreciar en las fotos y mencionar en la descripción.
            </p>
          </div>

        </div>
      </Modal>
    </div>)
};

const DescriptionText = ({ text }) => {

  const [openMoreInformation, setOpenMoreInformation] = useState(false);

  const handleToggleMoreInformation = () => setOpenMoreInformation(pre => !pre);

  return <p onClick={handleToggleMoreInformation} className={css.descriptionText}>{openMoreInformation ? text : truncateText(text, 120)}{
    !openMoreInformation && text.length >= 120 && <><br /> <span>Leer más</span></>
  }</p>
}