import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './ReloveSearchFilters.css';

const ReloveSearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    children,
  } = props;

  const classes = classNames(rootClassName || css.root, className);


  return (
    <div className={classes}>
      <h3 className={css.filtersTitle}>Filtrar por</h3>
      <div className={css.filters}>
        {children}
      </div>
    </div>
  );
};

ReloveSearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ReloveSearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const ReloveSearchFilters = ReloveSearchFiltersComponent;

export default ReloveSearchFilters;
