import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconSecurity.css';

const ReloveIconSecurity = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="28"
      viewBox="0 -80 700 700"
      width="28"
      // style="fill: skyblue; stroke: cadetblue; stroke-width: 2;"
    >
        <path d="m565.53 88.391-210-70c-3.6055-1.1914-7.4727-1.1914-11.078 0l-210 70c-7.1406 2.3984-11.953 9.0664-11.953 16.609v175c0 192.32 220.2 261.08 222.44 261.77 1.6445 0.48828 3.3438 0.73438 5.0586 0.73438s3.4141-0.24609 5.0586-0.73438c2.2383-0.68359 222.44-69.441 222.44-261.77v-175c0-7.543-4.8125-14.211-11.969-16.609zm-23.031 191.61c0 151.02-161.54 215.57-192.5 226.54-30.957-10.973-192.5-75.516-192.5-226.54v-162.38l192.5-64.172 192.5 64.172z"/>
        <path d="m274.87 250.13c-6.8438-6.8438-17.902-6.8438-24.746 0s-6.8438 17.902 0 24.746l70 70c3.4141 3.4102 7.8945 5.125 12.375 5.125s8.9609-1.7148 12.371-5.1289l122.5-122.5c6.8438-6.8438 6.8438-17.902 0-24.746s-17.902-6.8438-24.746 0l-110.12 110.13z"/>
    </svg>
  );
};

ReloveIconSecurity.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconSecurity.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconSecurity;
