import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  EarningsSection
} from '../../components';

const EarningsPageComponent = ({currentUser}) => {

    if(!currentUser) return (<div>loading</div>)

    return (
        <Page title={"schemaTitle"} scrollingDisabled={true} >
          <LayoutSideNavigation>
            <LayoutWrapperTopbar>
              <TopbarContainer currentPage="PrivacyPolicyPage" />
            </LayoutWrapperTopbar>
            <LayoutWrapperSideNav/>
            <LayoutWrapperMain>
              <EarningsSection/>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSideNavigation>
        </Page>
      )

}

const mapStateToProps = state => {
    const { currentUser } = state.user;

    return {
        currentUser
    };
  };
  
  const mapDispatchToProps = dispatch => ({

  });
  
  const EarningsPage = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    injectIntl
  )(EarningsPageComponent);
  
  export default EarningsPage;