import React from 'react';
import { Avatar, Button, ExpandingTextarea } from '../../components';
import { addComment, getListingById } from '../../util/api';
import { storableError } from '../../util/errors';
import { FormattedMessage } from '../../util/reactIntl';
import { types } from '../../util/sdkLoader';
import css from './ListingPage.css';
import { logEvent, getParamsListing } from '../../util/logsEvent';
const { Money } = types;
class AddComment extends React.Component {
  constructor(props) {
    super(props);
    const { listingId, currentUser, commentId, replyTo } = props;
    this.state = {
      comment: '',
      commentInProgress: null,
      commentError: null,
      currentUser,
      commentId,
      replyTo,
      listingId,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { comment } = this.state;
    const { onCommentPosted } = this.props;
    if (comment === '') {
      this.setState({ commentError: 'Debes escribir un comentario' });
      return false;
    }
    this.setState({ commentInProgress: true, commentError: null });

    try {
      const response = await this.submitComment();
      this.setState({ comment: '', commentInProgress: false });
      return onCommentPosted(response);
    } catch (e) {
      this.setState({ commentError: storableError(e) });
      throw e;
    }
  }

  handleChange(comment) {
    this.setState({ comment });
  }

  getUserObjectForComment() {
    const { currentUser } = this.state;
    return {
      id: { uuid: currentUser.id.uuid },
      attributes: {
        profile: {
          displayName: currentUser.attributes.profile.displayName,
          abbreviatedName: currentUser.attributes.profile.abbreviatedName,
        },
      },
    };
  }

  async submitComment() {
    const user = this.getUserObjectForComment();
    const { comment, listingId: listing, commentId, replyTo } = this.state;
    return addComment({ user, listing, comment, commentId, replyTo }).then(response => {
      getListingById({ idListing: listing })
      .then(responseListing =>{
        logEvent('send comment',{
          ...getParamsListing(responseListing),
          comment
        });
      })
      
      const { data } = response.data;
      const price = data.attributes.price;
      data.attributes.price = new Money(price.amount, price.currency);
      return response;
    });
  }

  render() {
    const { currentUser, commentInProgress, comment, commentError } = this.state;
    if (!currentUser) {
      return null;
    }

    const { replyTo } = this.props;
    const errorMessage = commentError ? (
      <p className={css.commentErrorMessage}>
        <FormattedMessage id="CommentsSection.defaultError" />)
      </p>
    ) : null;
    return (
      <div className={css.addComment}>
        <Avatar user={currentUser} className={css.addCommentAvatar} disableProfileLink />
        <form
          className={css.commentForm}
          onSubmit={e => {
            e.persist();
            this.handleSubmit(e);
          }}
        >
          <ExpandingTextarea
            autoFocus={this.props.autoFocus}
            onChange={comment => {
              comment.persist();
              this.handleChange(comment.target.value);
            }}
            disabled={commentInProgress}
            onKeyDown={e => {
              // If the user hits control + enter, we want to submit the form
              if (e.ctrlKey && e.keyCode === 13) {
                e.preventDefault();
                this.handleSubmit(e);
              }
            }}
            name="new_comment"
            value={comment}
            rows={replyTo ? 1 : 2}
            placeholder={replyTo ? 'Escribe tu respuesta' : 'Añade tu comentario'}
            className={replyTo ? css.commentFormTextareaDense : css.commentFormTextarea}
            required
          />
          {errorMessage}
          <Button
            type="submit"
            rootClassName={css.commentFormSubmit}
            inProgress={commentInProgress}
          >
            Enviar
          </Button>
        </form>
      </div>
    );
  }
}

export default AddComment;
