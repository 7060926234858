import classNames from 'classnames';
import { array, arrayOf, func, node, object, string } from 'prop-types';
import React, { Component, useState } from 'react';
import { ReloveFilterMobile, ReloveFilterPlain } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseSelectFilterOptions } from '../../../util/search';
import ReloveCategoryCheckbox from './ReloveCategoryCheckbox';
import css from './ReloveCategoryFilter.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options, initialOptions } = props;

  const [checked, setChecked] = useState(initialOptions);

  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          const categoryOpen = checked.includes(option.key) || option.options.some(r=> checked.includes(r.key));
          const classes = classNames(css.categoryParent, categoryOpen ? css.categoryOpen : null)
          return (
            <li key={fieldId} className={css.item}>
              <ReloveCategoryCheckbox
                id={fieldId}
                name={name}
                label={option.label}
                value={option.key}
                parse={value => { const checked = value.includes(option.key) ? [option.key] : value; setChecked(checked); return checked; }}
                textClassName={css.categoryItem}
                className={classes}
              />
              {(!!option.options) && (
                <ul className={css.subList}>
                  {option.options.map((suboption, index) => {
                    const subFieldId = `${id}.${suboption.key}`;
                    return (
                      <li key={subFieldId} className={css.subitem}>
                        <ReloveCategoryCheckbox
                          id={subFieldId}
                          name={name}
                          label={suboption.label}
                          parse={value => { const checked = value.filter(item => option.options.find(option => option.key === item)); setChecked(checked); return checked; }}
                          value={suboption.key}
                          textClassName={css.subcategoryItem}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class ReloveCategoryFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      onSubmit,
      queryParamNames,
      searchMode,
      intl,
      hasControls,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName, searchMode));
    };

    return hasControls ? (
      <ReloveFilterPlain
        autoApply={true}
        className={classes}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize={false}
        hasControls={hasControls}
        topControls={true}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroup}
          name={name}
          id={`${id}-checkbox-group`}
          options={options}
          initialOptions={selectedOptions}
        />
      </ReloveFilterPlain>
    ) : (
      <ReloveFilterMobile
        className={classes}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          name={name}
          id={`${id}-checkbox-group`}
          options={options}
          initialOptions={selectedOptions}
        />
      </ReloveFilterMobile>
    );
  }
}

ReloveCategoryFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
};

ReloveCategoryFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ReloveCategoryFilter);
