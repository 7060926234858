import React from 'react'

const ReloveIconPeople = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="User / Users">
<path id="Vector" d="M21 20.0097C21 18.2681 19.3304 16.7864 17 16.2373M15 20.0098C15 17.8006 12.3137 16.0098 9 16.0098C5.68629 16.0098 3 17.8006 3 20.0098M15 13.0098C17.2091 13.0098 19 11.2189 19 9.00977C19 6.80063 17.2091 5.00977 15 5.00977M9 13.0098C6.79086 13.0098 5 11.2189 5 9.00977C5 6.80063 6.79086 5.00977 9 5.00977C11.2091 5.00977 13 6.80063 13 9.00977C13 11.2189 11.2091 13.0098 9 13.0098Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

  )
}

export default ReloveIconPeople