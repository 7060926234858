import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperFooter,
  Footer,
  CheckVerificationSection,
} from '../../components';
import { ProfileSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import {
  updateProfile,
  uploadImage,
  uploadCoverImage,
  sendVerif,
  checkVerif,
} from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';
import { getIsZipCodeAvailable } from '../../ducks/Address.duck';
import { getCodeOfPhoneNumber, getNumberPhoneWithoutCode } from '../../util/codePhone';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnavailable: false,
      openZipErrorModal: false,
      onChangePhone: false,
      checkVerificationModalClose: true,
      phoneNumber: null,
      success: true,
      submitValues: null,
      maxAttempts: false,
      alreadyTakenPhone: false,
    };
  }
  render() {
    const {
      currentUser,
      image,
      coverImage,
      onImageUpload,
      onCoverImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      uploadCoverImageError,
      uploadCoverInProgress,
      intl,
      onSendVerification,
      onCheckVerification,
    } = this.props;

    const submit = values => {
      this.setState({ isUnavailable: false });
      const {
        firstName,
        name,
        birthday,
        address,
        addressExtNumber,
        addressIntNumber,
        addressNeighborhood,
        addressZip,
        addressState,
        addressCity,
        phoneNumber,
        codePhoneNumber,
      } = values;
      const uploadedCoverImage = this.props.coverImage;
    
      const getFirstName = fullName => fullName.split(" ")[0];
      const getLastName = fullName => {
        const parts = fullName.split(" ").slice(1);
        return parts.length > 0 ? parts.join(" ") : " ";
      };
    
      const firstNameValue = getFirstName(firstName);
      const lastName = getLastName(firstName);

      const publicData = {
        birthday,
        userName,
      };
    
      const updatedPublicData =
        uploadedCoverImage && uploadedCoverImage.location && uploadedCoverImage.id
          ? { ...publicData, coverImage: uploadedCoverImage }
          : publicData;

      const profile = {
        firstName,
        lastName,
        publicData: updatedPublicData,
        privateData: {
          address: {
            name,
            address_line: address,
            ext_number: addressExtNumber,
            int_number: addressIntNumber,
            neighborhood: addressNeighborhood,
            zip: addressZip,
            state: addressState,
            city: addressCity,
          },
          phoneNumber: codePhoneNumber + phoneNumber,
        },
      };
    
      const uploadedImage = this.props.image;
    
      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;
    
      getIsZipCodeAvailable(addressZip)
        .then(response => {
          if (response.available) {
            onUpdateProfile(updatedValues);
          } else {
            this.setState({ isUnavailable: true, openZipErrorModal: true });
          }
        })
        .catch(e => {
          if (addressZip) {
            this.setState({ isUnavailable: true, openZipErrorModal: true });
          } else {
            onUpdateProfile(updatedValues);
          }
        });
    };
    

    const handleSubmit = (values, type) => {
      const { phoneNumber, codePhoneNumber  } = values;

      if (this.state.onChangePhone === true && type === 'submit') {
        this.setState({ submitValues: values });
        const phone = codePhoneNumber + phoneNumber;
        onSendVerification({ phone })
          .then(response => {
            if (response.status === 'pending') {
              this.setState({ phoneNumber: phone });
              this.setState({ checkVerificationModalClose: false });
            } else if (response.status === 'alreadyTakenPhone') {
              this.setState({ alreadyTakenPhone: true });
            } else {
              this.setState({ success: false });
            }
          })
          .catch(e => {
            if (e.details && e.details.status === 'alreadyTakenPhone') {
              this.setState({ alreadyTakenPhone: true });
            } else {
              this.setState({ success: false });
            }
          });
      } else if (this.state.onChangePhone === true && type === 'check') {
        const code = values.code;
        const phone = this.state.phoneNumber;
        onCheckVerification({ phone, code })
          .then(response => {
            if (response.status === 'approved') {
              this.setState({ checkVerificationModalClose: true });
              submit(this.state.submitValues);
            } else if (response.status === 'Invalid') {
              this.setState({ success: false });
            } else if (response.status === 'maxAttempts') {
              this.setState({ maxAttempts: true });
            }
          })
          .catch(e => {
            console.log('check verification', e);
            //este console.log no es el que sale
            console.log(e);
          });
      } else if (this.state.onChangePhone === true && type === 'resend') {
        const phone = this.state.phoneNumber;
        onSendVerification({ phone })
          .then(response => {
            if (response.status === 'pending') {
              this.setState({ verificationModalClose: true });
              this.setState({ checkVerificationModalClose: false });
            } else {
              this.setState({ success: false });
            }
          })
          .catch(e => {
            this.setState({ success: false });
          });
      } else {
        submit(values);
      }
    };

    const changePhoneNumber = val => {
      this.setState({ onChangePhone: true });
      this.setState({ alreadyTakenPhone: false });
    };

    const user = ensureCurrentUser(currentUser);

    const addressObj =
      user.attributes.profile.privateData && user.attributes.profile.privateData.address
        ? user.attributes.profile.privateData.address
        : {};
    user.attributes.profile.name = addressObj?.name;
    user.attributes.profile.address = addressObj?.address_line;
    user.attributes.profile.addressExtNumber = addressObj?.ext_number;
    user.attributes.profile.addressIntNumber = addressObj?.int_number;
    user.attributes.profile.addressNeighborhood = addressObj?.neighborhood;
    user.attributes.profile.addressZip = addressObj?.zip;
    user.attributes.profile.addressState = addressObj?.state;
    user.attributes.profile.addressCity = addressObj?.city;
    const contactObj =
      user?.attributes?.profile?.privateData?.phoneNumber &&
      JSON.stringify(user?.attributes?.profile?.privateData?.phoneNumber) !== '{}'
        ? user.attributes.profile.privateData.phoneNumber
        : null;
    user.attributes.profile.phoneNumber =  contactObj ;
    const codePhoneNumber = getCodeOfPhoneNumber(contactObj);

    const {
      firstName: firstName = `${user.attributes.profile.firstName || ''} ${user.attributes.profile.lastName || ''}`.trim(),
      name,
      address,
      addressExtNumber,
      addressIntNumber,
      addressNeighborhood,
      addressZip,
      addressState,
      addressCity
    } = user.attributes.profile;
    

    const phoneNumber = getNumberPhoneWithoutCode(user.attributes.profile.phoneNumber)

    const { userName, birthday, coverImage: coverImageData } = user.attributes.profile.publicData;

    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };
    const userCoverImage = coverImage || coverImageData || {};

    const onClosingAlert = () => {
      this.setState({ openZipErrorModal: false });
    };

    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{
          firstName,
          birthday,
          codePhoneNumber,
          profileImage: user.profileImage,
          coverImage: coverImageData,
          name,
          address,
          addressExtNumber,
          addressIntNumber,
          addressNeighborhood,
          addressZip,
          addressState,
          addressCity,
          phoneNumber,

        }}
        profileImage={profileImage}
        coverImage={userCoverImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        uploadImageError={uploadImageError}
        onCoverImageUpload={e => onImageUploadHandler(e, onCoverImageUpload)}
        uploadCoverInProgress={uploadCoverInProgress}
        uploadCoverImageError={uploadCoverImageError}
        updateInProgress={updateInProgress}
        updateProfileError={updateProfileError}
        isUnavailable={this.state.isUnavailable}
        openZipErrorModal={this.state.openZipErrorModal}
        onClosingAlert={onClosingAlert}
        onManageDisableScrolling={this.props.onManageDisableScrolling}
        onSubmit={values => handleSubmit(values, 'submit')}
        changePhoneNumber={changePhoneNumber}
        alreadyTakenPhone={this.state.alreadyTakenPhone}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSettingsSideNav currentTab="ProfileSettingsPage" />
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>Configuración</h1>
              </div>
              <div className={css.formWrapper}>
                {profileSettingsForm}
                {!this.state.checkVerificationModalClose ? (
                  <CheckVerificationSection
                    onManageDisableScrolling={this.props.onManageDisableScrolling}
                    onSubmitEnquiry={values => handleSubmit(values, 'check')}
                    resendCode={values => handleSubmit(values, 'resend')}
                    onCloseEnquiry={() => this.setState({ checkVerificationModalClose: true })}
                    success={this.state.success}
                    maxAttempts={this.state.maxAttempts}
                    useModal={true}
                  />
                ) : null}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  uploadCoverImageError: null,
  updateProfileError: null,
  image: null,
  coverImage: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  coverImage: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onCoverImageUpload: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  uploadCoverImageError: propTypes.error,
  uploadCoverInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    coverImage,
    uploadCoverImageError,
    uploadCoverInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    image,
    coverImage,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    uploadCoverImageError,
    uploadCoverInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onImageUpload: data => dispatch(uploadImage(data)),
  onCoverImageUpload: data => dispatch(uploadCoverImage(data)),
  onUpdateProfile: data => {
    const urlParams = new URLSearchParams(window.location.search);
    const target = urlParams.get('target');
    dispatch(updateProfile(data, target));
  },
  onSendVerification: phone => dispatch(sendVerif(phone)),
  onCheckVerification: ({ phone, code }) => dispatch(checkVerif(phone, code)),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
