import React from 'react';
import {Button, Modal, ReloveIconMessages, ReloveIconFavorite } from '../../components';
import { EnquiryForm } from '../../forms';
import classNames from 'classnames';

import css from './ListingPage.css';


const SectionInbox = props => {

  const {
    title,
    authorDisplayName,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    onManageDisableScrolling,
    isOwnListing
  } = props;



  return (
    <div  className={isOwnListing ? css.iconsHidden : css.icons}>
        <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapperChat}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  )
}

export default SectionInbox