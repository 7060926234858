import React from 'react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './HowItWorksPage.css';

import sell1 from '../../assets/misc/1 como vender-min.jpg';
import sell2 from '../../assets/misc/2 como vender-min.jpg';
import sell3 from '../../assets/misc/3 como vender-min.jpg';
import buy1 from '../../assets/misc/1 Como comprar-min.jpg';
import buy2 from '../../assets/misc/2 Como comprar-min.png';
import buy3 from '../../assets/misc/3 Como comprar-min.jpg';

const HowItWorksPage = () => {

  // prettier-ignore
  return (
    <StaticPage
      title="¿Cómo funciona Gloset?"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HowItWorksPage',
        description: '¿Cómo funciona Gloset?',
        name: 'Cómo funciona',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.heading}>
            <p className={css.subtitle}>
              <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
            </p>
            <h1 className={css.title}>
              <FormattedMessage id="SectionHowItWorks.titleLineOne" />
            </h1>
          </div>
          

          <Grid container className={css.contentWrapper} spacing={4}>
            <Grid item className={css.howToSell} xs={12} md={6}>
              <h3 className={css.contentTitle}>Vende</h3>
              <div className={css.contentSection}>
                <img className={css.sectionImage} src={sell1} alt="Publica tu ropa"/>
                <h4>Publica tu ropa</h4>
                <p>Tómale fotos a tu ropa y accesorios para que luzcan y alguien más se enamore de ellos. ¡Recuerda que deben de estar en buen estado y apegarse al catálogo!</p>
                <p>Saca fotos claras y describe cualquier daño o detalle que la prenda pueda tener para evitar contratiempos</p>
                <p>Al <NamedLink name="NewListingPage">subir tu primer artículo</NamedLink>, da de alta tu <NamedLink name="ProfileSettingsPage">dirección</NamedLink>, <NamedLink name="StripePayoutPage">Clabe Interbancaria</NamedLink> y <ExternalLink href="https://www.notion.so/gloset/Alta-de-CLABE-y-RFC-para-subir-tu-primer-producto-742ce59400c1437f833c6fc11f499cb8">RFC</ExternalLink> (¡para que cuando vendas te podamos enviar tu dinero!)</p>
              </div> 
              <div className={css.contentSection}>
                <img className={css.sectionImage} src={sell2}  alt="Vende y envía tus paquetes"/>
                <h4>Vende y envía tus paquetes</h4>
                <p>Cuando vendas, te mandaremos por correo una guía prepagada para que <ExternalLink href="https://www.notion.so/gloset/C-mo-hacer-un-env-o-81c428a393894a3aaa96d25fe5bfb108">hagas el envío o agendes una recolección</ExternalLink></p>
                <p>Tienes 7 días para hacer el envío, o se podrá cancelar la venta, y máximo 2 semanas si la compradora decide esperar</p>
                <p><span className={css.featuredLine}>Tip Gloset:</span> empaca tus prendas a tu estilo y agrégale una notita a la compradora</p>
              </div> 
              <div className={css.contentSection}>
                <img className={css.sectionImage} src={sell3} alt="¡Y listo!"/>
                <h4>¡Y listo! Gana dinero </h4>
                <p> Llévate hasta el 86% de tu venta. Una vez que la compradora acepte el pedido, tus ganancias se reflejarán en tu balance de Gloset, listas para ser redimidas. Encuentra nuestro esquema de comisiones en <ExternalLink href="https://www.notion.so/gloset/PREGUNTAS-FRECUENTES-cd21a5653f8346f381b35fa0691b16a6">Preguntas Frecuentes</ExternalLink></p>
              </div>    
            </Grid>

            <Grid item className={css.howToBuy} xs={12} md={6}>
              <h3 className={css.contentTitle}>Compra</h3>
              <div className={css.contentSection}>
                <img className={css.sectionImage} src={buy1} alt="Encuentra lo que estabas buscando"/>
                <h4>Encuentra lo que estabas buscando</h4>
                <p>Las Glosers tienen el mejor estilo y por eso ¡encontrarás las cosas más increíbles! … A un súper precio. Busca lo que quieras por estilos, marcas, colores y tallas</p>
              </div> 
              <div className={css.contentSection}>
                <img className={css.sectionImage} src={buy2} alt="Compra u oferta de manera segura"/>
                <h4>Compra u oferta de manera segura</h4>
                <p>Si algo te encanta, ¡cómpralo antes de que alguien más te lo gane!… y si crees que puedes mejorar el precio, haz una oferta, y si te la rechazan, no te desanimes e intenta con otro precio</p>
                <p>Tu pago será procesado de manera segura mediante Stripe, y todas tus compras tienen garantía</p>
              </div> 
              <div className={css.contentSection}>
                <img className={css.sectionImage} src={buy3} alt="¡Recibe tu paquete a la puerta de tu casa!"/>
                <h4>¡Recibe tu paquete a la puerta de tu casa! Vestir a la moda y ser sustentable nunca fue tan fácil</h4>
                <p>¡Que no se te olvide calificar a la vendedora para ayudarla a vender más!</p>
                <p>Y recuerda, tienes hasta 3 días después de que recibiste tu paquete para avisarnos si la prenda era diferente a la descripción o estaba dañada. En esos casos, te regresaremos tu dinero</p>
              </div>    
            </Grid>
          </Grid>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HowItWorksPage;
