
import * as amplitude from '@amplitude/analytics-browser'

export class AmplitudeAnalytics {

  constructor() {
    this.apiKey = process.env.REACT_APP_AMPLITUDE_KEY
  }

  async initialize() {
    if (!this.isInitialized) {
      await amplitude.init(this.apiKey, {
        defaultTracking: {
          formInteractions: false,
          pageViews: false,
          sessions: true,
          fileDownloads: false,
          attribution: true,
        }
      })
      this.isInitialized = true
    };
  }

  async setUser(id) {
    await this.initialize();
    amplitude.setUserId(id);
  }

  async trackEvent(name, data = {}) {
    await this.initialize();
    amplitude.track(name, data);
  }

  async cleanSession() {
    await this.initialize();
    amplitude.reset();
  }

  async setUserProperties(nameProperties, value) {
    await this.initialize();
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set(nameProperties, value);
    amplitude.identify(identifyEvent);

  }

}

export const logEventInit = async () => {

  //amp.track('Start Session');
};

export const logEvent = (eventName = '', properties) => {
  const amplitude = new AmplitudeAnalytics();
  amplitude.trackEvent(eventName.toLocaleLowerCase(), properties);
};

export const logIdentify = (key, value) => {
  const amplitude = new AmplitudeAnalytics();
  amplitude.setUserProperties(key, value);
};

export const logEventSetUserID = userId => {
  const amplitude = new AmplitudeAnalytics();
  amplitude.setUser(userId)
};

const useCondition ={
  Usado:'Usado, con varios signos visibles',
  Ligero:  'Usado, en buena condición',
  PerfectasCondiciones:'Usado, en muy buena condición',
  Etiquetado: 'Nuevo, con etiquetas',
  SinEtiquetas: 'Nuevo, sin etiquetas'
} 


/*[
  { key: 'Usado', label: 'Usado, con varios signos visibles', text:'Ha sido usado y muestra signos de uso comunes en la ropa. Tiene detalles o daños y se pueden apreciar en las fotos y mencionar en la descripción.' },
  { key: 'Ligero', label: 'Usado, en buena condición', text:'Ha sido usado y muestra signos de uso comunes en la ropa, como uso en la tela. Puede tener alteraciones, detalles, o daños, y se pueden apreciar en las fotos o mencionar en la descripción.'},
  { key: 'PerfectasCondiciones', label: 'Usado, en muy buena condición', text:'Ha sido usado y puede mostrar ligeros signos de uso comunes en la ropa, como uso en la tela, pero ningún daño material. Puede tener alteraciones o detalles mínimos, y se pueden apreciar en las fotos o mencionar en la descripción.' },
  { key: 'Etiquetado', label: 'Nuevo, con etiquetas', text:'No tiene defectos ni alteraciones y cuenta con las etiquetas originales. En condición impecable.' },
  { key: 'SinEtiquetas', label: 'Nuevo, sin etiquetas',text:'No tiene defectos ni alteraciones. En condición impecable.' },
]*/

export const getParamsListing = (listing) => {
  const { attributes, author } = listing;
  return {
    ['product id']: listing?.id?.uuid,
    ['product price']: getAmountAmplitude(attributes.price.amount),
    ['product original price']: getAmountAmplitude(attributes.publicData.original_price.amount),
    ['product name']: attributes?.description,
    ['product category']: attributes?.publicData?.category[0],
    ['product subcategory']: attributes?.publicData?.category?.[1],
    ['product name']: attributes?.title,
    ['seller id']: author?.id?.uuid,
    ['seller name']: author?.attributes?.profile?.displayName,
    size: attributes?.publicData?.size?.join(', '),
    brand: attributes?.publicData?.brand,
    ['favorite count']: attributes?.metadata?.likedBy?.length,
    ['product condition']: useCondition[attributes?.publicData?.use]
    
  }
}

export const getAmountAmplitude = (amount) => {
  return amount / 100
}



export const logPixelFacebook = (eventName, properties = {}) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };

      ReactPixel.init(process.env.REACT_APP_PIXEL_FACEBOOK_ID, advancedMatching, options)
      ReactPixel.trackCustom(eventName, properties)
    })
}