import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  ReloveIconMoney, 
  ReloveIconFriends,
  ReferralCodeGeneratorSection,

  ReloveIconCard,
  ReloveIconMoneyHand,
  ReloveIconPeople,
  ModalAddUsername
} from '../../components';

import { manageDisableScrolling, isScrollingDisabled } from './../../ducks/UI.duck';
import css from './ReferralPage.css'


import {
  generateReferralCodeLink,
  generateNewUser
} from './ReferralPage.duck'

const ReferralPageComponent = (props) => {


  const {
    onManageDisableScrolling,
    currentUser, 
    onGenerateReferralCodeLink,
    onGenerateNewUser,
    generateNewUserInProgress,
    generateNewUserTakenError,
    generateReferralCodeInProgess
  } = props;

  const [referralCodeLink, setReferralCodeLink] = useState(currentUser?.attributes?.profile?.privateData?.referralCodeLink);
  const [referralCode, setReferralCode] = useState(currentUser?.attributes?.profile?.privateData?.referralCode);

  const [userName, setUserName] = useState(currentUser?.attributes?.profile?.publicData?.userName);

  const [newUserName, setNewUserName] = useState(null);


  const [isUserModalOpen, setIsUserModalOpen] = useState(false)


  useEffect(() => {
    setReferralCode(currentUser?.attributes?.profile?.privateData?.referralCode);
    setReferralCodeLink(currentUser?.attributes?.profile?.privateData?.referralCodeLink);
    setUserName(currentUser?.attributes?.profile?.publicData?.userName);
  }, [currentUser]);

  if(!currentUser) return (<div>loading</div>)

  // Split the first and second strings into words
  const firstName = currentUser.attributes.profile.firstName.split(' ')[0];
  const lastName = currentUser.attributes.profile.lastName.split(' ')[0];




  const sectionTitle = (
    <div className={css.sectionTitleContainer}>
      <div className={css.referralPageHeader}>Invita amigas, gana $150 por cada una</div>
      <div className={css.iconsContainer}>
        <ReloveIconFriends />
        <ReloveIconMoney />
      </div>
    </div>
  )
  

  const sectionHowItWorks = (
    <div className={css.sectionHowItWorks}>
      <h1 className={css.sectionTitle}>Cómo Funciona</h1>
      <div className={css.sectionItem}><ReloveIconPeople/><span>Comparte tu código de referidas con todas tus amigas</span></div>
      <div className={css.sectionItem}><ReloveIconCard/><span>Les regalarás $150 pesos para su primer compra</span></div>
      <div className={css.sectionItem}><ReloveIconMoneyHand/><span>Ganarás $150 por cada compra entregada!</span></div>
    </div>
  )
  

  const handleGenerateReferralCode = async () => {

    if(userName){
      const generatedReferralCode = await onGenerateReferralCodeLink({referralCode: userName, userId : currentUser.id.uuid})
      setReferralCodeLink(generatedReferralCode);
    }else{
      //open modal

      setIsUserModalOpen(true)
    }


  }

  const handleNewUserCreation = async () => {
    onGenerateNewUser(currentUser.id.uuid, newUserName).then((userNameReponse) => {
      if(!userNameReponse.unavailable){
        onGenerateReferralCodeLink({referralCode : newUserName, userId: currentUser.id.uuid}).then(() => {
          setIsUserModalOpen(false)
        }).catch(e => {
        })
      }
    }).catch(e => {
    })
  }


  // Get the last 4 digits from the third string
  const last4Digits = currentUser.id.uuid.slice(-4);

  // Join them together to create the referral code



  //Todo finish modal for when no user on profile.
  return (
    <Page title={"Invita amigas"} scrollingDisabled={false} >
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ReferralPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {sectionTitle}
          <div>    
            <div className={css.referralContainer}>Mi código para referir</div>      
            <ReferralCodeGeneratorSection
              hasCodeBeenGenerated={!!referralCodeLink}
              handleGenerateReferralCode={handleGenerateReferralCode}
              referralCode={referralCode}
              referralCodeLink={referralCodeLink}
            />
            
            <ModalAddUsername
              onManageDisableScrolling={onManageDisableScrolling}
              currentUser={currentUser}
              isUserModalOpen={isUserModalOpen}
              setIsUserModalOpen={(value) => setIsUserModalOpen(value)}
              customModalClass={css.modal}
              newUserName={newUserName}
              setNewUserName={(value) => {setNewUserName(value)}}
              handleNewUserCreation={() => handleNewUserCreation()}
              generateReferralCodeInProgess={generateReferralCodeInProgess}
              generateNewUserInProgress={generateNewUserInProgress}
              generateNewUserTakenError={generateNewUserTakenError}
            />
          </div>
          {sectionHowItWorks}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  )
}

const mapStateToProps = state => {
  const {
    newUserName,
    generateReferralCodeInProgess,
    generateReferralCodeError,
  
    generateNewUserInProgress,
    generateNewUserTakenError,
    generateNewUserError,
  
    generateNewUserUnavailable,
  } = state.ReferralPage;

  return {
    currentUser: state.user.currentUser,
    generateNewUserInProgress,
    generateNewUserTakenError,
    generateReferralCodeInProgess
  }
};

const mapDispatchToProps = dispatch => ({
  onGenerateReferralCodeLink: (data) => dispatch(generateReferralCodeLink(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
  dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGenerateNewUser: (userId, username) => dispatch(generateNewUser(userId, username))
});

const ReferralPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
    injectIntl
  )(ReferralPageComponent);
  
  
  export default ReferralPage;