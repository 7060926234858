import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconFavorite.css';

const ReloveIconFavorite = props => {
  const { rootClassName, className, isFull } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <>
      {isFull ?
        <svg className={classes} viewBox="254.917 214.655 29 27" width="29" height="27">
          <path id="Vector" d="M 275.942 214.655 C 273.419 214.655 270.998 215.847 269.417 217.731 C 267.837 215.847 265.415 214.655 262.892 214.655 C 258.426 214.655 254.917 218.216 254.917 222.748 C 254.917 228.31 259.847 232.842 267.315 239.728 L 269.417 241.655 L 271.52 239.713 C 278.987 232.842 283.917 228.31 283.917 222.748 C 283.917 218.216 280.408 214.655 275.942 214.655 Z"
            fill="#FD7C67"
          ></path>
        </svg> :
        <svg
          className={classes}
          width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="Vector" d="M21.025 0C18.502 0 16.0805 1.19183 14.5 3.0752C12.9195 1.19183 10.498 0 7.975 0C3.509 0 0 3.56076 0 8.09264C0 13.6545 4.93 18.1864 12.3975 25.0725L14.5 27L16.6025 25.0578C24.07 18.1864 29 13.6545 29 8.09264C29 3.56076 25.491 0 21.025 0ZM14.645 22.8801L14.5 23.0272L14.355 22.8801C7.453 16.5384 2.9 12.345 2.9 8.09264C2.9 5.14986 5.075 2.94278 7.975 2.94278C10.208 2.94278 12.383 4.39946 13.1515 6.41526H15.863C16.617 4.39946 18.792 2.94278 21.025 2.94278C23.925 2.94278 26.1 5.14986 26.1 8.09264C26.1 12.345 21.547 16.5384 14.645 22.8801Z" fill="#FD7C67" />
        </svg>}
    </>
  );
};

ReloveIconFavorite.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconFavorite.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconFavorite;
