import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import { Avatar, Button, NamedLink } from '../../components';
import css from './ListingPage.css';

const formatter = buildFormatter(spanishStrings);
const Comment = props => {
  const { className, comment, commentUsers, showReplyButton, onShowReplyBox } = props;
  let commentUserFind = commentUsers.find(c => comment.user.id.uuid === c.id.uuid);
  let user = commentUserFind || comment.user;
  const replyButton =
    showReplyButton && comment.id ? (
      <Button
        rootClassName={css.comment__reply__button}
        type="button"
        onClick={() => {
          onShowReplyBox(comment.id.uuid);
        }}
      >
        Responder
      </Button>
    ) : null;

  // Convert UTC time to local time
  const localTime = new Date(comment.createdAt).toLocaleString();

  return (
    <div className={className}>
      <div className={css.comment}>
        <Avatar user={user} className={css.comment__avatar} />
        <div className={css.comment__content}>
          <p className={css.comment__message}>
            <NamedLink
              name="ProfilePage"
              params={{ id: comment.user.id.uuid }}
              className={css.comment__author}
            >
              {comment.user.attributes.profile.displayName}
            </NamedLink>
            {comment.comment}
          </p>
          <TimeAgo
            date={comment.createdAt}
            title={localTime}
            formatter={formatter}
            className={css.comment__date}
          />
          {replyButton}
        </div>
      </div>
      <div>
        {comment.comments &&
          comment.comments.slice(0).map((item, key) => {
            return (
              <Comment
                comment={item}
                key={key}
                commentUsers={commentUsers}
                showReplyButton={showReplyButton}
                onShowReplyBox={onShowReplyBox}
                className={css.comment__reply}
              />
            );
          })}
      </div>
    </div>
  );
};

Comment.defaultProps = { className: null };

const { string, bool, func, object, array } = PropTypes;

Comment.propTypes = {
  className: string,
  showReplyButton: bool,
  onShowReplyBox: func,
  comment: object,
  commentUsers: array,
};

export default Comment;
