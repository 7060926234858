import React from 'react';
import {
  Button, 
  Modal,
  Form, 
  FieldTextInput,
  FieldSelectSimple,
  FieldSelect
 } from '../../components';

import config from '../../config';
import * as validators from '../../util/validators';

import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { Form as FinalForm, Field, FormSpy } from 'react-final-form';

import css from './CheckoutPage.css';

const SectionAddress = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {

      const {
        isAddressModalOpen,
        onCloseAddressModal,
        handleSubmit,
        manageDisableScrolling,
        zipIsUnavailable,
        intl,
        updateInProgress,
        updateError,
        invalid,

        setNewAddressState,
        setAddressValid
      } = formRenderProps;

      // Address
      const addressLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.addressLabel',
      });
      const addressPlaceholder = intl.formatMessage({
        id: 'ProfileSettingsForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.addressRequired',
      });

      const addressRequired = validators.required(addressRequiredMessage);

      const maxCharacters = validators.maxLength(
        "Longitud máxima de campo son 30 carácteres",
        30
      );
      const zipErrorMessage = zipIsUnavailable ? intl.formatMessage({
        id: 'ProfileSettingsForm.zipUnavailableShortError',
      }) : null;

      const composedValidator = validators.composeValidators(addressRequired, maxCharacters);

      const handleAnyFieldChange = (field, value, isInvalid) => {
        setNewAddressState(field, value, isInvalid);
      };

      const mexicoStates = config.states;

      const validateState = value => {
        if (!value || value === "") {
          return "State is required";
        }
      };

      return (
        <div className={css.AddressContainer}>
          <Form
            className={css.form}
            onSubmit={handleSubmit}
          >         
            <div className={classNames(css.sectionContainer, css.lastSection)}>

              {/* Name Field */}
              <div className={css.nameContainer}>
                <Field name="name">
                  {({ input, meta }) => {
                    const customOnChange = (e) => {
                      handleAnyFieldChange('name', e.target.value, e.target.value.length <= 30);
                      input.onChange(e);
                    };

                    return (
                      <FieldTextInput
                        inputRootClass={css.name}
                        className={css.inputRoot}
                        type="text"
                        id="name"
                        name="name"
                        label="Nombre"
                        placeholder="Nombre de quien recibe"
                        validate={composedValidator}
                        autoComplete="off"
                        onChange={customOnChange}
                      />
                    );
                  }}
                </Field>
              </div>

              <div className={css.nameContainer}>
                <Field name="address_line">
                  {({ input, meta }) => (
                    <FieldTextInput
                      inputRootClass={css.address_line}
                      className={css.inputRoot}
                      type="text"
                      id="address_line"
                      name="address_line"
                      label={addressLabel}
                      placeholder={addressPlaceholder}
                      validate={composedValidator}
                      autoComplete="off"
                      onChange={(e) => {
                        handleAnyFieldChange('address_line', e.target.value, e.target.value.length <= 30);
                        input.onChange(e);
                      }}
                    />
                  )}
                </Field>
              </div>

              <div className={css.addressRowContainer}>
                <Field name="ext_number">
                  {({ input, meta }) => {
                    const customOnChange = (e) => {
                      handleAnyFieldChange('ext_number', e.target.value, meta.valid);
                      input.onChange(e);
                    };

                    return (
                      <FieldTextInput
                        inputRootClass={css.ext_number}
                        className={css.inputRoot}
                        type="text"
                        id="ext_number"
                        name="ext_number"
                        label="Número Exterior"
                        placeholder="Número Exterior"
                        validate={addressRequired}
                        autoComplete="off"
                        onChange={customOnChange}
                      />
                    );
                  }}
                </Field>
                <Field name="int_number">
                  {({ input, meta }) => {
                    const customOnChange = (e) => {
                      handleAnyFieldChange('int_number', e.target.value, true);
                      input.onChange(e);
                    };

                    return (
                      <FieldTextInput
                        inputRootClass={css.int_number}
                        className={css.inputRoot}
                        type="text"
                        id="int_number"
                        name="int_number"
                        label="Número Interior"
                        placeholder="Número Interior"
                        autoComplete="off"
                        onChange={customOnChange}
                      />
                    );
                  }}
                </Field>
              </div>

              <div className={css.nameContainer}>
                <Field name="neighborhood">
                  {({ input, meta }) => {
                    const customOnChange = (e) => {
                      handleAnyFieldChange('neighborhood', e.target.value, e.target.value.length <= 30);
                      input.onChange(e);
                    };

                    return (
                      <FieldTextInput
                        inputRootClass={css.neighborhood}
                        className={css.inputRoot}
                        type="text"
                        id="neighborhood"
                        name="neighborhood"
                        label="Colonia"
                        placeholder="Colonia"
                        validate={composedValidator}
                        autoComplete="off"
                        onChange={customOnChange}
                      />
                    );
                  }}
                </Field>
              </div>

              <div className={css.addressRowContainer}>
                <Field name="zip">
                  {({ input, meta }) => {
                    const customOnChange = (e) => {
                      handleAnyFieldChange('zip', e.target.value, meta.valid);
                      input.onChange(e);
                    };

                    return (
                      <FieldTextInput
                        inputRootClass={css.zip}
                        className={css.inputRoot}
                        type="text"
                        id="zip"
                        name="zip"
                        label="C.P."
                        placeholder="C.P."
                        validate={addressRequired}
                        customErrorText={zipErrorMessage}
                        autoComplete="off"
                        onChange={customOnChange}
                      />
                    );
                  }}
                </Field>
                <Field name="city">
                  {({ input, meta }) => {
                    const customOnChange = (e) => {
                      handleAnyFieldChange('city', e.target.value, meta.valid);
                      input.onChange(e);
                    };

                    return (
                      <FieldTextInput
                        inputRootClass={css.city}
                        className={css.inputRoot}
                        type="text"
                        id="city"
                        name="city"
                        label="Ciudad"
                        placeholder="Ciudad"
                        validate={addressRequired}
                        autoComplete="off"
                        onChange={customOnChange}
                      />
                    );
                  }}
                </Field>
              </div>

              <div className={css.nameContainer}>
                <Field name="state" validate={addressRequired}>
                  {({ input, meta }) => {
                    const customOnChange = (value) => {
                      handleAnyFieldChange('state', value, false);
                      input.onChange(value);
                    };

                    return (
                      <FieldSelect
                        id="state"
                        name="state"
                        label="Estado"
                        autoComplete="off"
                        onChange={customOnChange}
                        options={mexicoStates}
                        hasInternalCss={true}
                        internalCss={css.addressState}
                      >
                        <option disabled value="">
                          Estado
                        </option>
                        {mexicoStates.map(state => (
                          <option key={state.clave} value={state.clave}>
                            {state.nombre}
                          </option>
                        ))}
                      </FieldSelect>
                    );
                  }}
                </Field>

                <FormSpy
                  subscription={{ values: true, valid: true }}
                  onChange={({ values, valid }) => {
                    setAddressValid(valid && values.state);
                  }}
                />
              </div>

              {updateError ? (
                <p className={css.error}>
                  <FormattedMessage id="CheckoutPage.updateError" />
                </p>
              ) : null}
            </div>
          </Form>
        </div>
      );
    }}
  />
);

export default SectionAddress;
