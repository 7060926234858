import React, { useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { isStripeError } from '../../util/errors';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import {
  Button,
  ExternalLink,
  InlineTextButton,
  FieldSelectSimple,
  FieldRadioButton,
  Form,
  StripeBankAccountTokenInputField,
  StripeBankAccountTokenInputFieldUpdate,
  FieldClabeInput,
} from '../../components';

import css from './StripeConnectAccountForm.css';

const supportedCountries = config.stripe.supportedCountries.map(c => c.code);

export const stripeCountryConfigs = countryCode => {
  const country = config.stripe.supportedCountries.find(c => c.code === countryCode);

  if (!country) {
    throw new Error(`Country code not found in Stripe config ${countryCode}`);
  }
  return country;
};

const countryCurrency = countryCode => {
  const country = stripeCountryConfigs(countryCode);
  return country.currency;
};



const CreateStripeAccountFields = props => {
  const { disabled, countryLabel, showAsRequired, form, values, intl, currentUserId, onSaveClientClabeNumber } = props;
  /*
  We pass some default values to Stripe when creating a new Stripe account in order to reduce couple of steps from Connect Onboarding form.
  - businessProfileURL: user's profile URL
  - businessProfileMCC: default MCC code from stripe-config.js
  - accountToken (https://stripe.com/docs/connect/account-tokens) with following information:
    * accountType: individual or business
    * tos_shown_and_accepted: true
  Only country and bank account token are mandatory values. If you decide to remove the additional default values listed here, remember to update the `createStripeAccount` function in `ducks/stripeConnectAccount.duck.js`.
  */

  const individualAccountLabel = intl.formatMessage({
    id: 'StripeConnectAccountForm.individualAccount',
  });

  const companyAccountLabel = intl.formatMessage({ id: 'StripeConnectAccountForm.companyAccount' });

  const hasBusinessURL = values && values.businessProfileURL;
  // Use user profile page as business_url on this marketplace
  // or just fake it if it's dev environment using Stripe test endpoints
  // because Stripe will not allow passing a localhost URL
  if (!hasBusinessURL && currentUserId) {
    const pathToProfilePage = uuid =>
      createResourceLocatorString('ProfilePage', routeConfiguration(), { id: uuid }, {});
    const hasCanonicalRootUrl = config && config.canonicalRootURL;
    const rootUrl = hasCanonicalRootUrl ? config.canonicalRootURL.replace(/\/$/, '') : null;
    const defaultBusinessURL =
      hasCanonicalRootUrl && !rootUrl.includes('localhost')
        ? `${rootUrl}${pathToProfilePage(currentUserId.uuid)}`
        : `https://test-marketplace.com${pathToProfilePage(currentUserId.uuid)}`;
    form.change('businessProfileURL', defaultBusinessURL);
  }

  const hasMCC = values && values.businessProfileMCC;
  // Use default merchant category code (MCC) from stripe-config.js
  if (!hasMCC && config.stripe.defaultMCC) {
    const defaultBusinessProfileMCC = config.stripe.defaultMCC;
    form.change('businessProfileMCC', defaultBusinessProfileMCC);
  }

  const country = values.country;
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripeConnectAccountForm.countryRequired',
    })
  );

  const faqLink = (
    <ExternalLink href="https://www.notion.so/Alta-de-CLABE-y-RFC-para-subir-tu-primer-producto-742ce59400c1437f833c6fc11f499cb8#bbd4fbe3497a4ff8962140ba62f07b42" className={css.faqLink}>
      <FormattedMessage id="StripeConnectAccountForm.faqLink" />
    </ExternalLink>
  );

  return (
    <div className={css.sectionContainer}>
      <h3 className={css.subTitle}>
        <FormattedMessage id="StripeConnectAccountForm.accountTypeTitle" />
      </h3>
      <div className={css.radioButtonRow}>
        <FieldRadioButton
          id="individual"
          name="accountType"
          label={individualAccountLabel}
          value="individual"
          showAsRequired={showAsRequired}
        />
        <FieldRadioButton
          id="company"
          name="accountType"
          label={companyAccountLabel}
          value="company"
          showAsRequired={showAsRequired}
        />
      </div>

      <FieldSelectSimple
        id="country"
        name="country"
        disabled={true}
        className={css.selectCountry}
        autoComplete="country"
        label={countryLabel}
        validate={countryRequired}
        initialValue="MX"
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'StripeConnectAccountForm.countryPlaceholder' })}
        </option>
        {supportedCountries.map(c => (
          <option key={c} value={c}>
            {intl.formatMessage({ id: `StripeConnectAccountForm.countryNames.${c}` })}
          </option>
        ))}
      </FieldSelectSimple>

      {country ? (
        <>
          <StripeBankAccountTokenInputField
            className={css.bankDetailsStripeField}
            disabled={disabled}
            name="bankAccountToken"
            formName="StripeConnectAccountForm"
            country={country}
            currency={countryCurrency(country)}
            validate={validators.required(' ')}
            onSaveClientClabeNumber={onSaveClientClabeNumber}
          />
          <p className={css.accountTokenTip}>
            <FormattedMessage
              id="StripeConnectAccountForm.accountTokenTip"
              values={{faqLink}}
            />
          </p>
        </>
      ) : null}
    </div>
  );
};

const UpdateStripeAccountFields = props => {
  const {
    disabled,
    countryLabel,
    savedCountry,
    showCardUpdateInput,
    setShowCardUpdateInput,
    stripeBankAccountLastDigits,
    onSaveClientClabeNumber,
    hasConektaClabe,
    areFourDigitsEqual,
    conektaClabe,
    setShowConektaInput,
    showConektaInput,
    inProgress,
    setClabeValidationError,
    setDummyClabeField,
    dummyClabeField,
    submitButtonClicked
  } = props;

  let dummyAcocuntField;
  let stripeAccountField;



  //cases

  // 1. clabe saved on privateData and this clabe last 4 digits are equal to stripeBankAccountLastDigits
  // show only one Clabe Field form. on update update both clabes

  // 2. clabe saved on privateData and this clabe last 4 digits are not equal to stripeBankAccountLastDigits or stripeBankAccountLastDigits is null
  // show two clabe field forms, the first one offers no real functionality but to save both need to be the same. The one that will be saved is the second field form

  //3. clabe not saved on privateData
  // show two clabe field forms, the first one offers no real functionality but to save both need to be the same. The one that will be saved is the second field form


  if(hasConektaClabe && areFourDigitsEqual && !submitButtonClicked) {

    dummyAcocuntField = null;
    stripeAccountField = showCardUpdateInput ? (
      <StripeBankAccountTokenInputFieldUpdate
        className={css.bankDetailsStripeField}
        disabled={disabled}
        name="bankAccountToken"
        formName="StripeConnectAccountForm"
        country={savedCountry}
        currency={countryCurrency(savedCountry)}
        validate={validators.required(' ')}
        onSaveClientClabeNumber={onSaveClientClabeNumber}
        stripeBankAccountLastDigits={stripeBankAccountLastDigits}
        updateValidator={false}
        clabeFieldType={'same_digits'}
        setClabeValidationError={setClabeValidationError}
      />): (
        <InlineTextButton
          className={css.savedBankAccount}
          onClick={() => setShowCardUpdateInput(true)}
        >
          <div>•••••••••••••••••••••••• {stripeBankAccountLastDigits}</div>
      </InlineTextButton>
    )
  } else if((hasConektaClabe && !areFourDigitsEqual && !submitButtonClicked) ) {

    setShowCardUpdateInput(true)
    stripeAccountField = showConektaInput ? (
      <StripeBankAccountTokenInputFieldUpdate
        className={css.bankDetailsStripeField}
        disabled={disabled}
        name="bankAccountToken"
        formName="StripeConnectAccountForm"
        country={savedCountry}
        currency={countryCurrency(savedCountry)}
        validate={validators.required(' ')}
        onSaveClientClabeNumber={onSaveClientClabeNumber}
        stripeBankAccountLastDigits={stripeBankAccountLastDigits}
        updateValidator={false}
        clabeFieldType={'dif-digits'}
        setClabeValidationError={setClabeValidationError}
      />
    ): (
      <InlineTextButton
        className={css.savedBankAccount}
        onClick={() => setShowConektaInput(true)}
      >
        <div>•••••••••••••••••••••••• {stripeBankAccountLastDigits}<span className={css.highlightText}> (para pagos por Stripe)</span></div>
        <div>•••••••••••••••••••••••• {conektaClabe.slice(-4)} <span className={css.highlightText}> (para pagos por PayPal)</span></div>
    </InlineTextButton>
  )

  } else {
    dummyAcocuntField = (showCardUpdateInput && !submitButtonClicked) ? (
    <StripeBankAccountTokenInputFieldUpdate
        className={css.bankDetailsStripeField}
        disabled={disabled}
        name="bankAccountToken"
        formName="StripeConnectAccountForm"
        country={savedCountry}
        currency={countryCurrency(savedCountry)}
        validate={validators.required(' ')}
        onSaveClientClabeNumber={onSaveClientClabeNumber}
        stripeBankAccountLastDigits={stripeBankAccountLastDigits}
        updateValidator={false}
        clabeFieldType={'same_digits'}
        setClabeValidationError={setClabeValidationError}
      />
      ) : (
        <InlineTextButton
          className={css.savedBankAccount}
          onClick={() => setShowCardUpdateInput(true)}
        >
          •••••••••••••••••••••••• {stripeBankAccountLastDigits}
      </InlineTextButton>
    )
    stripeAccountField = (!showCardUpdateInput && !submitButtonClicked) && (
      <StripeBankAccountTokenInputFieldUpdate
        className={css.bankDetailsStripeField}
        disabled={disabled}
        name="bankAccountToken"
        formName="StripeConnectAccountForm"
        country={savedCountry}
        currency={countryCurrency(savedCountry)}
        validate={validators.required(' ')}
        onSaveClientClabeNumber={onSaveClientClabeNumber}
        stripeBankAccountLastDigits={stripeBankAccountLastDigits}
        updateValidator={true}
        clabeFieldType={'validation'}
        setClabeValidationError={setClabeValidationError}
        setDummyClabeField={setDummyClabeField}
        dummyClabeField={dummyClabeField}
      />
    )
  }

  return (
    <div className={css.savedInformation}>
      <h3 className={css.accountInformationTitle}>{countryLabel}</h3>
      <div className={css.savedCountry}>
        <FormattedMessage id={`StripeConnectAccountForm.countryNames.${savedCountry}`} />
      </div>
      <h3 className={css.accountInformationTitle}>
        <FormattedMessage id="StripeConnectAccountForm.bankAccountLabel" />
      </h3>
      {dummyAcocuntField}
      {stripeAccountField}
    </div>
  );
};

const ErrorsMaybe = props => {
  const { stripeAccountError, stripeAccountLinkError, clabeValidationError } = props;
  return isStripeError(stripeAccountError) ? (
    <div className={css.error}>
      <FormattedMessage
        id="StripeConnectAccountForm.createStripeAccountFailedWithStripeError"
        values={{ stripeMessage: stripeAccountError.apiErrors[0].meta.stripeMessage }}
      />
    </div>
  ) : stripeAccountError ? (
    <div className={css.error}>
      <FormattedMessage id="StripeConnectAccountForm.createStripeAccountFailed" />
    </div>
  ) : stripeAccountLinkError ? (
    <div className={css.error}>
      <FormattedMessage id="StripeConnectAccountForm.createStripeAccountLinkFailed" />
    </div>
  ) : clabeValidationError ? (
      <div className={css.error}>
        <FormattedMessage id="StripeConnectAccountForm.CLABEValidationError" />
      </div>
  ) : null;
};

const StripeConnectAccountFormComponent = props => {
  const [showCardUpdateInput, setShowCardUpdateInput] = useState(false);
  const [showAddNewClabe, setShowAddNewClabe] = useState(false);
  const [showStripeInput, setShowStripeInput] = useState(false);
  const [showConektaInput, setShowConektaInput] = useState(false);

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);


  const [clabeValidationError, setClabeValidationError] = useState(false);

  const [dummyClabeField, setDummyClabeField] = useState(false);
  const { onSubmit, ...restOfProps } = props;
  const isUpdate = props.stripeConnected;

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={values => onSubmit(values, isUpdate)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          className,
          children,
          stripeAccountError,
          stripeAccountLinkError,
          disabled,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          pristine,
          ready,
          savedCountry,
          stripeAccountFetched,
          stripeBankAccountLastDigits,
          submitButtonText,
          form,
          values,
          stripeConnected,
          currentUser,
          onSaveClientClabeNumber
        } = fieldRenderProps;

        const hasConektaClabe = !!currentUser?.attributes?.profile?.privateData?.clabe;
        const conektaClabe = currentUser?.attributes?.profile?.privateData?.clabe;

        const areFourDigitsEqual = stripeBankAccountLastDigits === String(conektaClabe).slice(-4);


        const accountDataLoaded = stripeConnected && stripeAccountFetched && savedCountry;
        const submitInProgress = inProgress;
        const submitDisabled = pristine || invalid || disabled || submitInProgress;

        const handleFormSubmit = event => {
          // Close the bank account form when clicking "save details"
          setSubmitButtonClicked(true)
          setShowCardUpdateInput(false);
          handleSubmit(event, !!accountDataLoaded);
        };

        const countryLabel = intl.formatMessage({ id: 'StripeConnectAccountForm.countryLabel' });
        const classes = classNames(css.root, className, {
          [css.disabled]: disabled,
        });

        const showAsRequired = pristine;

        const currentUserId = currentUser ? currentUser.id : null;

        // If the user doesn't have Stripe connected account,
        // show fields for country and bank account.
        // Otherwise, show only possibility the edit bank account
        // because Stripe doesn't allow user to change the country
        const stripeAccountFields = !stripeConnected ? (
          <CreateStripeAccountFields
            stripeConnected={stripeConnected}
            onSaveClientClabeNumber={onSaveClientClabeNumber}
            disabled={disabled}
            showAsRequired={showAsRequired}
            countryLabel={countryLabel}
            supportedCountries={supportedCountries}
            currentUserId={currentUserId}
            form={form}
            values={values}
            intl={intl}
          />
        ) : (
          <UpdateStripeAccountFields
            disabled={disabled}
            countryLabel={countryLabel}
            savedCountry={savedCountry}
            stripeBankAccountLastDigits={stripeBankAccountLastDigits}
            showCardUpdateInput={showCardUpdateInput}
            values={values}
            submitInProgress={submitInProgress}
            setShowCardUpdateInput={setShowCardUpdateInput}
            showAddNewClabe={showAddNewClabe}
            setShowAddNewClabe={setShowAddNewClabe}
            intl={intl}
            onSaveClientClabeNumber={onSaveClientClabeNumber}
            hasConektaClabe={hasConektaClabe}
            areFourDigitsEqual={areFourDigitsEqual}
            conektaClabe={conektaClabe}

            setShowConektaInput={setShowConektaInput}
            showConektaInput={showConektaInput}

            setShowStripeInput={setShowStripeInput}
            showStripeInput={showStripeInput}
            setClabeValidationError={(value) => setClabeValidationError(value)}
            setDummyClabeField={setDummyClabeField}
            dummyClabeField={dummyClabeField}
            inProgress={inProgress}
            submitButtonClicked={submitButtonClicked}

          />
        );

        const stripeConnectedAccountTermsLink = (
          <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
            <FormattedMessage id="StripeConnectAccountForm.stripeConnectedAccountTermsLink" />
          </ExternalLink>
        );

        // Don't show the submit button while fetching the Stripe account data
        const submitButtonMaybe =
          !stripeConnected || accountDataLoaded ? (
            <>
              <p className={css.termsText}>
                <FormattedMessage
                  id="StripeConnectAccountForm.stripeToSText"
                  values={{ stripeConnectedAccountTermsLink }}
                />
              </p>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={ready}
              >
                {submitButtonText || (
                  <FormattedMessage id="StripeConnectAccountForm.submitButtonText" />
                )}
              </Button>
            </>
          ) : null;

        // If the Stripe publishable key is not set up, don't show the form
        return config.stripe.publishableKey ? (
          <Form className={classes} onSubmit={handleFormSubmit}>
            {!stripeConnected || accountDataLoaded ? (
              stripeAccountFields
            ) : (
              <div className={css.savedInformation}>
                <FormattedMessage id="StripeConnectAccountForm.loadingStripeAccountData" />
              </div>
            )}

            <ErrorsMaybe
              stripeAccountError={stripeAccountError}
              stripeAccountLinkError={stripeAccountLinkError}
              clabeValidationError={clabeValidationError}
            />

            {/* {dummyAccountField} */}
            {children}

            {submitButtonMaybe}
          </Form>
        ) : (
          <div className={css.missingStripeKey}>
            <FormattedMessage id="StripeConnectAccountForm.missingStripeKey" />
          </div>
        );
      }}
    />
  );
};

StripeConnectAccountFormComponent.defaultProps = {
  className: null,
  currentUser: null,
  stripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  savedCountry: null,
  stripeBankAccountLastDigits: null,
  submitButtonText: null,
  fieldRenderProps: null,
};

StripeConnectAccountFormComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  stripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  savedCountry: string,
  stripeBankAccountLastDigits: string,
  stripeAccountFetched: bool.isRequired,
  submitButtonText: string,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const StripeConnectAccountForm = compose(injectIntl)(StripeConnectAccountFormComponent);

export default StripeConnectAccountForm;
