import React from 'react';
import  FieldSelect  from '../../../components/FieldSelectSimple/FieldSelectSimple';

import css from './ReloveEditListingForm.css';

const ReloveFeatureSelectField = props => {
  const { name, id, label, placeholder, validator, options, className, valueField = 'key', selectProps, defaultValue } = props;
  return options ? (
    <FieldSelect
      className={className || css.category}
      name={name}
      id={id}
      defaultValue={defaultValue}
      label={label}
      validate={validator}
      options={options}
      valueField={valueField}
      selectProps={selectProps}
    >
      <option value="" className={css.placeholderOption}>
        {placeholder}
      </option>
      {options.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default  ReloveFeatureSelectField;
