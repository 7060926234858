import React, { useState } from 'react'
import { Button } from 'react-bootstrap';

import css from './ReferralCodeGeneratorSection.css';

import {
    ReloveIconMail,
    ReloveIconX,
    ReloveIconFacebook,
    ReloveIconWhatsapp,
} from '../'

const ReferralCodeGeneratorSection = props => {

    const {
        hasCodeBeenGenerated,
        referralCode,
        referralCodeLink,
        handleGenerateReferralCode
    } = props

      // Function to handle input changes
    const handleInputChange = (e) => {
    // setInputValue(e.target.value);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
    };


    const handleSocialMediaIconsClick = (socialMedia) => {
        let message;
        switch (socialMedia) {
            case 'Facebook': 
                message = encodeURIComponent(`Únete a Gloset, mi lugar favorito para comprar y vender moda. Te regalo $150 en tu primera compra con mi código de descuento: ${referralCodeLink}`);
                window.open(`https://www.facebook.com/dialog/share?
                app_id=247262510819791&
                display=popup
                &title=Hola
                &description=Thisd
                &quote=huehue
                &caption=caption
                &href=https%3A%2F%dev.gloset.mx%2Fdocs%2F
                &redirect_uri=https%3A%2F%2Fwww.[gloset].mx`, '_blank');

                // window.open(`https://www.facebook.com/dialog/share?
                // app_id=247262510819791
                // &display=popup
                // &href=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2F
                // &redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer`)
            break;
            case 'WhatsApp':
                message = encodeURIComponent(`Únete a Gloset, mi lugar favorito para comprar y vender moda. Te regalo $150 en tu primera compra con mi código de descuento: ${referralCodeLink}`);
                window.open(`https://api.whatsapp.com/send?text=${message}`, '_blank');
            break;
            case 'X':
                message = encodeURIComponent(`Únete a Gloset, mi lugar favorito para comprar y vender moda. Te regalo $150 en tu primera compra con mi código de descuento: ${referralCodeLink}`);
                const hashtags = '#Gloset'; // Replace with your desired hashtags
                window.open(`https://twitter.com/intent/tweet?text=${message}&hashtags=${hashtags}`, '_blank');
            break;
            case 'Mail':
                message = encodeURIComponent(`Únete a Gloset, mi lugar favorito para comprar y vender moda. Te regalo $150 en tu primera compra con mi código de descuento: ${referralCodeLink}`);
                window.location.href = `mailto:?subject=Your%20Subject&body=${message}`;
            break;
            default:
                break;
        }
    }

    const referralCodeButton = referralCodeLink ? (
        <div className={css.referralCodeButtonContainer}>
            <input
            type="text"
            value={referralCode}
            onChange={handleInputChange}
            placeholder="Usuario"
            className={css.referralCodeInput}
            disabled={true}
            />
            <Button className={css.referralCodeCopyButton} onClick={() => {navigator.clipboard.writeText(referralCodeLink)}}>COPIAR</Button>
        </div>
    ):
    (
        <Button 
            className={css.referralButton} 
            onClick={handleGenerateReferralCode}
            inProgress={true}
        >
            Generar Código
        </Button>
    )

    const referralCodeSocialMediaIcons = (
        <div className={css.referralCodeSocialMediaIconsContainer}>
            <button className={css.socialMediaButton} onClick={() => handleSocialMediaIconsClick("WhatsApp")}><ReloveIconWhatsapp/><div className={css.socialMediaIconText}>WhatsApp</div></button>
            {/* <button className={css.socialMediaButton} onClick={() => handleSocialMediaIconsClick("Facebook")}><ReloveIconFacebook/><div className={css.socialMediaIconText}>Facebook</div></button> */}
            <button className={css.socialMediaButton} onClick={() => handleSocialMediaIconsClick("X")}><ReloveIconX/><div className={css.socialMediaIconText}>X</div></button>
            <button className={css.socialMediaButton} onClick={() => handleSocialMediaIconsClick("Mail")}><ReloveIconMail/><div className={css.socialMediaIconText}>Email</div></button>
        </div>
    )

    return (
        <div className={css.container}>
            {referralCodeButton}
            {hasCodeBeenGenerated && referralCodeSocialMediaIcons }
        </div>
    )
}

export default ReferralCodeGeneratorSection