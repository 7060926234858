import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCardHome, NamedLink } from '../../components';

import css from './SectionLikesHome.css';

const SectionLikesHome = props => {
  const { rootClassName, className, favoritedListings, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  if (!favoritedListings.length) {
    return null;
  }
  
  const favoritedListingsSlice = isMobileLayout ? favoritedListings.slice(0, 8) : favoritedListings.slice(0, 9);
  const XS = isMobileLayout ? 3 : 2;
  const MD = isMobileLayout ? 5 : 4;
  return (
    <div className={classes}>
      <h3 className={css.title}><FormattedMessage id="SectionFavorite.title" /> <NamedLink 
          name="MyFavoritesPage"> Ver todos
        </NamedLink>  
      </h3>
      <Grid container spacing={2} className={css.favorite}>
        {favoritedListingsSlice.map(l => (
          <Grid item xs={XS} md={MD} key={l.id.uuid}>
            <ListingCardHome
              className={css.favoriteItem}
              listing={l} 
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

SectionLikesHome.defaultProps = { rootClassName: null, className: null, favoritedListings: [] };

const { string } = PropTypes;

SectionLikesHome.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLikesHome;