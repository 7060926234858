/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import Animal from './assets/prints/animalprint.jpg';
import Floral from './assets/prints/floralprint.jpg';
import Gold from './assets/prints/gold.jpg';
import Multi from './assets/prints/multi.jpg';
import Silver from './assets/prints/silver.jpg';

export const filters = [
  {
    id: 'brand',
    label: 'Marca',
    type: 'ReloveBrandFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '725 ORIGINALS', label: '725 ORIGINALS' },
        { key: '7 FOR ALL MANKIND', label: '7 FOR ALL MANKIND' },
        { key: 'ABERCROMBIE & FITCH', label: 'ABERCROMBIE & FITCH' },
        { key: 'ACLER', label: 'ACLER' },
        { key: 'ADIDAS', label: 'ADIDAS' },
        { key: 'AIDAN MATTOX', label: 'AIDAN MATTOX' },
        { key: 'ADRIANNA PAPELL', label: 'ADRIANNA PAPELL' },
        { key: 'ALYN PAIGE', label: 'ALYN PAIGE' },
        { key: 'AÉROPOSTALE', label: 'AÉROPOSTALE' },
        { key: 'ALEXANDER MCQUEEN', label: 'ALEXANDER MCQUEEN' },
        { key: 'ALICE + OLIVIA', label: 'ALICE + OLIVIA' },
        { key: 'ALLSAINTS', label: 'ALLSAINTS' },
        { key: 'ALO YOGA', label: 'ALO YOGA' },
        { key: 'AMUR', label: 'AMUR' },
        { key: 'AMERICAN EAGLE', label: 'AMERICAN EAGLE' },
        { key: 'ALEXIS', label: 'ALEXIS' },
        { key: 'ANDREA', label: 'ANDREA' },
        { key: 'ANNE KLEIN', label: 'ANNE KLEIN' },
        { key: 'ANTHROPOLOGIE', label: 'ANTHROPOLOGIE' },
        { key: 'ARMANI', label: 'ARMANI' },
        { key: 'ARITZIA', label: 'ARITZIA' },
        { key: 'ARTICLES OF SOCIETY', label: 'ARTICLES OF SOCIETY' },
        { key: 'ASICS', label: 'ASICS' },
        { key: 'ASOS', label: 'ASOS' },
        { key: 'ASTR THE LABEL', label: 'ASTR THE LABEL' },
        { key: 'BANANA REPUBLIC', label: 'BANANA REPUBLIC' },
        { key: 'BALENCIAGA', label: 'BALENCIAGA' },
        { key: 'BB DAKOTA', label: 'BB DAKOTA' },
        { key: 'BCBG MAX AZRIA', label: 'BCBG MAX AZRIA' },
        { key: 'BCBG', label: 'BCBG' },
        { key: 'BDG', label: 'BDG' },
        { key: 'BEBE', label: 'BEBE' },
        { key: 'BECCA', label: 'BECCA' },
        { key: 'BEC AND BRIDGE', label: 'BEC AND BRIDGE' },
        { key: 'BERSHKA', label: 'BERSHKA' },
        { key: 'BETSEY JOHNSON', label: 'BETSEY JOHNSON' },
        { key: 'BIMBA Y LOLA', label: 'BIMBA Y LOLA' },
        { key: 'BIRKENSTOCK', label: 'BIRKENSTOCK' },
        { key: 'BP.', label: 'BP.' },
        { key: 'BRANDY MELVILLE', label: 'BRANDY MELVILLE' },
        { key: 'BRONX AND BANCO', label: 'BRONX AND BANCO' },
        { key: 'BOTTEGA VENETA', label: 'BOTTEGA VENETA' },
        { key: 'BURBERRY', label: 'BURBERRY' },
        { key: 'CARTIER', label: 'CARTIER' },
        { key: 'C/MEO', label: 'C/MEO' },
        { key: 'C&A', label: 'C&A' },
        { key: 'CALVIN KLEIN', label: 'CALVIN KLEIN' },
        { key: 'CAROLINA HERRERA', label: 'CAROLINA HERRERA' },
        { key: 'CARTERS', label: 'CARTERS' },
        { key: 'CASLON', label: 'CASLON' },
        { key: 'CAMILA COELHO COLLECTION', label: 'CAMILA COELHO COLLECTION' },
        { key: 'CHAMPION', label: 'CHAMPION' },
        { key: 'CHANEL', label: 'CHANEL' },
        { key: 'CHARLOTTE RUSSE', label: 'CHARLOTTE RUSSE' },
        { key: 'CINDERELLA', label: 'CINDERELLA' },
        { key: 'CITIZENS OF HUMANITY', label: 'CITIZENS OF HUMANITY' },
        { key: 'CINQ A SEPT', label: 'CINQ A SEPT' },
        { key: 'CKLASS', label: 'CKLASS' },
        { key: 'COACH', label: 'COACH' },
        { key: 'COCOA LINGERIE', label: 'COCOA LINGERIE' },
        { key: 'COLE HAAN', label: 'COLE HAAN' },
        { key: 'COLUMBIA', label: 'COLUMBIA' },
        { key: 'CUIDADO CON EL PERRO', label: 'CUIDADO CON EL PERRO' },
        { key: 'CRUELLA', label: 'CRUELLA' },
        { key: 'DION LEE', label: 'DION LEE' },
        { key: 'DOLCE VITA', label: 'DOLCE VITA' },
        { key: 'DOROTHY GAYNOR', label: 'DOROTHY GAYNOR' },
        { key: 'DKNY', label: 'DKNY' },
        { key: 'ECOTÉ', label: 'ECOTÉ' },
        { key: 'EILEEN FISHER', label: 'EILEEN FISHER' },
        { key: 'EMPORIO ARMANI', label: 'EMPORIO ARMANI' },
        { key: 'EXPRESS', label: 'EXPRESS' },
        { key: 'FENDI', label: 'FENDI' },
        { key: 'FINDERS KEEPERS', label: 'FINDERS KEEPERS' },
        { key: 'FOR LOVE AND LEMONS', label: 'FOR LOVE AND LEMONS' },
        { key: 'FOREVER 21', label: 'FOREVER 21' },
        { key: 'FRAME', label: 'FRAME' },
        { key: 'FREE PEOPLE', label: 'FREE PEOPLE' },
        { key: 'FURLA', label: 'FURLA' },
        { key: 'GAP', label: 'GAP' },
        { key: 'GANNI', label: 'GANNI' },
        { key: 'GIANNI BINI', label: 'GIANNI BINI' },
        { key: 'GIVENCHY', label: 'GIVENCHY' },
        { key: 'GOLDEN GOOSE', label: 'GOLDEN GOOSE' },
        { key: 'GUCCI', label: 'GUCCI' },
        { key: 'GUESS', label: 'GUESS' },
        { key: 'H&M', label: 'H&M' },
        { key: 'HOLLISTER', label: 'HOLLISTER' },
        { key: 'HALSTON', label: 'HALSTON' },
        { key: 'HAUTE ROGUE', label: 'HAUTE ROGUE' },
        { key: 'INC', label: 'INC' },
        { key: 'ISABEL MARANT', label: 'ISABEL MARANT' },
        { key: 'JACQUEMUS', label: 'JACQUEMUS' },
        { key: 'J. CREW', label: 'J. CREW' },
        { key: 'J BRAND', label: 'J BRAND' },
        { key: 'JIMMY CHOO', label: 'JIMMY CHOO' },
        { key: 'JOES JEANS', label: 'JOES JEANS' },
        { key: 'JONATHAN SIMKHAI', label: 'JONATHAN SIMKHAI' },
        { key: 'JUICY COUTURE', label: 'JUICY COUTURE' },
        { key: 'JWPEI', label: 'JWPEI' },
        { key: 'KATE SPADE', label: 'KATE SPADE' },
        { key: 'KEBO', label: 'KEBO' },
        { key: 'KENZO', label: 'KENZO' },
        { key: 'KEEPSAKE', label: 'KEEPSAKE' },
        { key: 'KENDALL AND KYLIE', label: 'KENDALL AND KYLIE' },
        { key: 'KENDRA SCOTT', label: 'KENDRA SCOTT' },
        { key: 'KIPLING', label: 'KIPLING' },
        { key: 'KURT GEIGER', label: 'KURT GEIGER' },
        { key: 'LACOSTE', label: 'LACOSTE' },
        { key: 'LEVIS', label: 'LEVIS' },
        { key: 'LIVERPOOL', label: 'LIVERPOOL' },
        { key: 'LIONESS', label: 'LIONESS' },
        { key: 'LONGCHAMP', label: 'LONGCHAMP' },
        { key: 'LOVERS + FRIENDS', label: 'LOVERS + FRIENDS' },
        { key: 'LUCIANA BALDERRAMA', label: 'LUCIANA BALDERRAMA' },
        { key: 'LUCKY BRAND', label: 'LUCKY BRAND' },
        { key: 'LULULEMON ATHLETICA', label: 'LULULEMON ATHLETICA' },
        { key: 'LOUIS VUITTON', label: 'LOUIS VUITTON' },
        { key: 'CHRISTIAN LOUBOUTIN', label: 'CHRISTIAN LOUBOUTIN' },
        { key: 'MAAJI', label: 'MAAJI' },
        { key: 'MAC DUGGAL', label: 'MAC DUGGAL' },
        { key: 'MARC JACOBS', label: 'MARC JACOBS' },
        { key: 'MAJE', label: 'MAJE' },
        { key: 'MASSIMO DUTTI', label: 'MASSIMO DUTTI' },
        { key: 'MARCHESA NOTTE', label: 'MARCHESA NOTTE' },
        { key: 'MICHAEL KORS', label: 'MICHAEL KORS' },
        { key: 'MIU MIU', label: 'MIU MIU' },
        { key: 'MONDI', label: 'MONDI' },
        { key: 'MANGO', label: 'MANGO' },
        { key: 'MOSCHINO', label: 'MOSCHINO' },
        { key: 'MOSSIMO', label: 'MOSSIMO' },
        { key: 'NAUTICA', label: 'NAUTICA' },
        { key: 'NEW NEW', label: 'NEW NEW' },
        { key: 'NIKE', label: 'NIKE' },
        { key: 'NINE WEST', label: 'NINE WEST' },
        { key: 'NORDSTROM', label: 'NORDSTROM' },
        { key: 'NORMA KAMALI', label: 'NORMA KAMALI' },
        { key: 'OLD NAVY', label: 'OLD NAVY' },
        { key: 'OLIVER PEOPLES', label: 'OLIVER PEOPLES' },
        { key: 'PAIGE', label: 'PAIGE' },
        { key: 'RALPH LAUREN', label: 'RALPH LAUREN' },
        { key: 'RAPSODIA', label: 'RAPSODIA' },
        { key: 'RAG & BONE', label: 'RAG & BONE' },
        { key: 'RAY-BAN', label: 'RAY-BAN' },
        { key: 'REEBOK', label: 'REEBOK' },
        { key: 'REFORMATION', label: 'REFORMATION' },
        { key: 'REVOLVE', label: 'REVOLVE' },
        { key: 'RETROFETE', label: 'RETROFETE' },
        { key: 'ROTATE', label: 'ROTATE' },
        { key: 'RIXO', label: 'RIXO' },
        { key: 'RONNY KOBO', label: 'RONNY KOBO' },
        { key: 'PANDORA', label: 'PANDORA' },
        { key: 'PERUGIA', label: 'PERUGIA' },
        { key: 'PRADA', label: 'PRADA' },
        { key: 'PRANA', label: 'PRANA' },
        { key: 'PATBO', label: 'PATBO' },
        { key: 'PRINCESS POLLY', label: 'PRINCESS POLLY' },
        { key: 'PEPE JEANS', label: 'PEPE JEANS' },
        { key: 'POLO', label: 'POLO' },
        { key: 'POUR LA VICTORIE', label: 'POUR LA VICTORIE' },
        { key: 'PULL&BEAR', label: 'PULL&BEAR' },
        { key: 'PUMA', label: 'PUMA' },
        { key: 'PUSHEEN', label: 'PUSHEEN' },
        { key: 'PURIFICACIÓN GARCÍA', label: 'PURIFICACIÓN GARCÍA' },
        { key: 'SANDRO', label: 'SANDRO' },
        { key: 'SAINT LAURENT', label: 'SAINT LAURENT' },
        { key: 'SAM EDELMAN', label: 'SAM EDELMAN' },
        { key: 'SAKS POTTS', label: 'SAKS POTTS' },
        { key: 'SELF PORTRAIT', label: 'SELF PORTRAIT' },
        { key: 'SFERA', label: 'SFERA' },
        { key: 'SHASA', label: 'SHASA' },
        { key: 'SHEIN', label: 'SHEIN NO ACEPTAMOS' },
        { key: 'SIGNIFICANT OTHER', label: 'SIGNIFICANT OTHER' },
        { key: 'SOLUDOS', label: 'SOLUDOS' },
        { key: 'SONG OF STYLE', label: 'SONG OF STYLE' },
        { key: 'SPLENDID', label: 'SPLENDID' },
        { key: 'STEVE MADDEN', label: 'STEVE MADDEN' },
        { key: 'STRADIVARIUS', label: 'STRADIVARIUS' },
        { key: 'STELLA MCCARTNEY', label: 'STELLA MCCARTNEY' },
        { key: 'STUDIO F', label: 'STUDIO F' },
        { key: 'SWAROVSKI', label: 'SWAROVSKI' },
        { key: 'TERRANI', label: 'TERRANI' },
        { key: 'THEORY', label: 'THEORY' },
        { key: 'TED BAKER', label: 'TED BAKER' },
        { key: 'TED LAPIDUS', label: 'TED LAPIDUS' },
        { key: 'THE CLOTHING COMPANY', label: 'THE CLOTHING COMPANY' },
        { key: 'TOBI', label: 'TOBI' },
        { key: 'TOMMY HILFIGER', label: 'TOMMY HILFIGER' },
        { key: 'TOPSHOP', label: 'TOPSHOP' },
        { key: 'TORY BURCH', label: 'TORY BURCH' },
        { key: 'TOUS', label: 'TOUS' },
        { key: 'TRUE RELIGION', label: 'TRUE RELIGION' },
        { key: 'UGG', label: 'UGG' },
        { key: 'UNDER ARMOUR', label: 'UNDER ARMOUR' },
        { key: 'UNITED COLORS OF BENETTON', label: 'UNITED COLORS OF BENETTON' },
        { key: 'UNIQLO', label: 'UNIQLO' },
        { key: 'URBAN OUTFITTERS', label: 'URBAN OUTFITTERS' },
        { key: 'UTERQÜE', label: 'UTERQÜE' },
        { key: 'VANS', label: 'VANS' },
        { key: 'VALENTINO', label: 'VALENTINO' },
        { key: 'VEJA', label: 'VEJA' },
        { key: 'VERTIGO', label: 'VERTIGO' },
        { key: 'VELVET BOUTIQUE', label: 'VELVET BOUTIQUE' },
        { key: 'VICTORIAS SECRET', label: 'VICTORIAS SECRET' },
        { key: 'VINCE CAMUTO', label: 'VINCE CAMUTO' },
        { key: 'WESTIES', label: 'WESTIES' },
        { key: 'WOMENS SECRET', label: 'WOMENS SECRET' },
        { key: 'YEEZY', label: 'YEEZY' },
        { key: 'ZAFUL', label: 'ZAFUL' },
        { key: 'ZARA', label: 'ZARA' },
        { key: 'ZIMMERMANN', label: 'ZIMMERMANN' },
        { key: 'OTRO', label: 'OTRO' },
      ],
    },
  },
  {
    id: 'size',
    label: 'Talla',
    type: 'ReloveSizeFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'uni', label: 'Unitalla' },

        { key: 'standar-xxs', label: 'XXS', group: 'Ropa (Estándar)' },
        { key: 'standar-xs', label: 'XS', group: 'Ropa (Estándar)' },
        { key: 'standar-s', label: 'S', group: 'Ropa (Estándar)' },
        { key: 'standar-m', label: 'M', group: 'Ropa (Estándar)' },
        { key: 'standar-l', label: 'L', group: 'Ropa (Estándar)' },
        { key: 'standar-xl', label: 'XL', group: 'Ropa (Estándar)' },

        { key: 'numeric-00', label: '00', group: 'Ropa (US)' },
        { key: 'numeric-0', label: '0', group: 'Ropa (US)' },
        { key: 'numeric-2', label: '2', group: 'Ropa (US)' },
        { key: 'numeric-4', label: '4', group: 'Ropa (US)' },
        { key: 'numeric-6', label: '6', group: 'Ropa (US)' },
        { key: 'numeric-8', label: '8', group: 'Ropa (US)' },
        { key: 'numeric-10', label: '10', group: 'Ropa (US)' },
        { key: 'numeric-12', label: '12', group: 'Ropa (US)' },

        { key: 'mexico-24', label: '24', group: 'Ropa (MX)' },
        { key: 'mexico-25', label: '25', group: 'Ropa (MX)' },
        { key: 'mexico-26', label: '26', group: 'Ropa (MX)' },
        { key: 'mexico-27', label: '27', group: 'Ropa (MX)' },
        { key: 'mexico-28', label: '28', group: 'Ropa (MX)' },
        { key: 'mexico-29', label: '29', group: 'Ropa (MX)' },
        { key: 'mexico-30', label: '30', group: 'Ropa (MX)' },
        { key: 'mexico-31', label: '31', group: 'Ropa (MX)' },
        { key: 'mexico-32', label: '32', group: 'Ropa (MX)' },
        { key: 'mexico-33', label: '33', group: 'Ropa (MX)' },

        { key: 'mexiconumeric-1', label: '1', group: 'Ropa (Numérica)' },
        { key: 'mexiconumeric-3', label: '3', group: 'Ropa (Numérica)' },
        { key: 'mexiconumeric-5', label: '5', group: 'Ropa (Numérica)' },
        { key: 'mexiconumeric-7', label: '7', group: 'Ropa (Numérica)' },
        { key: 'mexiconumeric-9', label: '9', group: 'Ropa (Numérica)' },
        { key: 'mexiconumeric-11', label: '11', group: 'Ropa (Numérica)' },

        { key: 'plus-0', label: '0XL', group: 'Curvy + Plus' },
        { key: 'plus-1', label: '1XL', group: 'Curvy + Plus' },
        { key: 'plus-2', label: '2XL', group: 'Curvy + Plus' },
        { key: 'plus-3', label: '3XL', group: 'Curvy + Plus' },
        { key: 'plus-4', label: '4XL', group: 'Curvy + Plus' },
        { key: 'plus-5', label: '5XL', group: 'Curvy + Plus' },
      ],
    },
  },
  {
    id:'description',
    label:'Description'
  },
  {
    id: 'use',
    label: 'Condición',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_use'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Usado', label: 'Usado, con varios signos visibles', text:'Ha sido usado y muestra signos de uso comunes en la ropa. Tiene detalles o daños y se pueden apreciar en las fotos y mencionar en la descripción.' },
        { key: 'Ligero', label: 'Usado, en buena condición', text:'Ha sido usado y muestra signos de uso comunes en la ropa, como uso en la tela. Puede tener alteraciones, detalles, o daños, y se pueden apreciar en las fotos o mencionar en la descripción.'},
        { key: 'PerfectasCondiciones', label: 'Usado, en muy buena condición', text:'Ha sido usado y puede mostrar ligeros signos de uso comunes en la ropa, como uso en la tela, pero ningún daño material. Puede tener alteraciones o detalles mínimos, y se pueden apreciar en las fotos o mencionar en la descripción.' },
        { key: 'Etiquetado', label: 'Nuevo, con etiquetas', text:'No tiene defectos ni alteraciones y cuenta con las etiquetas originales. En condición impecable.' },
        { key: 'SinEtiquetas', label: 'Nuevo, sin etiquetas',text:'No tiene defectos ni alteraciones. En condición impecable.' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Categoría',
    type: 'ReloveCategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'Vestidos', label: 'Vestidos formales',
          options: [
            { key: 'Vestidos-Largos', label: 'Largos' },
            { key: 'Vestidos-Midi', label: 'Midi' },
            { key: 'Vestidos-Mini', label: 'Mini' },
            { key: 'Vestidos-MangaLarga', label: 'Manga larga' },
            { key: 'Vestidos-Bodas', label: 'Novia/Bridal' },
          ]
        },
        {
          key: 'Vestidos-Casuales', label: 'Vestidos casuales',
          options: [
            { key: 'Vestidos-Casuales-Midi/Maxi', label: 'Midi/Maxi' },
            { key: 'Vestidos-Casuales-Mini', label: 'Mini' },
          ]
        },
        {
          key: 'Tops', label: 'Tops',
          options: [
            { key: 'Tops-Blusas', label: 'Blusas' },
            { key: 'Tops-Crop', label: 'Crop Tops' },
            { key: 'Tops-Camisetas', label: 'T-shirts y camisetas' },
            { key: 'Tops-Manga', label: 'Manga larga' },
            { key: 'Tops-Sudaderas', label: 'Sudaderas/Hoodies' },
            { key: 'Tops-Sweaters', label: 'Suéters' },
            { key: 'Tops-Bodys', label: 'Bodys' },
          ]
        },
        {
          key: 'Faldas', label: 'Faldas',
          options: [
            { key: 'Faldas-Mini', label: 'Mini' },
            { key: 'Faldas-Midi', label: 'Midi' },
            { key: 'Faldas-Maxi', label: 'Maxi' },
            { key: 'Faldas-Mezclilla', label: 'Mezclilla' },
          ]
        },
        {
          key: 'Pantalones', label: 'Pantalones',
          options: [
            { key: 'Pantalones-Formales', label: 'Formales'},
            { key: 'Pantalones-Casuales', label: 'Casuales'},
            { key: 'Pantalones-Leggings', label: 'Leggings'},
            { key: 'Pantalones-Relajados', label: 'Sweatpants'},
          ]
        },
        {
          key: 'Jeans', label: 'Jeans',
          options: [
            { key: 'Jeans-Skinny', label: 'Pitillo/Skinny'},
            { key: 'Jeans-Relajados', label: 'Anchos/Relajados'},
            { key: 'Jeans-Rectos', label: 'Rectos'},
            { key: 'Jeans-Acampanados', label: 'Acampanados'},
            { key: 'Jeans-Crop', label: 'Al tobillo y crop'},
            { key: 'Jeans-Rasgados', label: 'Rasgados/rotos'},
          ]
        },
        {
          key: 'Shorts', label: 'Shorts',
          options: [
            { key: 'Shorts-Mini', label: 'Mini'},
            { key: 'Shorts-Mezclilla', label: 'Mezclilla'},
            { key: 'Shorts-Bermudas', label: 'Bermudas'},
            { key: 'Shorts-Falda', label: 'Falda-short'},
          ]
        },
        {
          key: 'Chamarras', label: 'Chamarras, Sacos, Abrigos',
          options: [
            { key: 'Chamarras-Piel', label: 'Chamarras de piel' },
            { key: 'Chamarras-Pluma', label: 'Chamarras de pluma y acolchadas' },
            { key: 'Chamarras-Mezclilla', label: 'Chamarras de mezclilla' },
            { key: 'Chamarras-Bomber', label: 'Chamarras bomber y biker' },
            { key: 'Chamarras-Blazers', label: 'Blazers y sacos' },
            { key: 'Chamarras-Abrigos', label: 'Abrigos' },
            { key: 'Chamarras-Chalecos', label: 'Chalecos' },
            { key: 'Chamarras-Pelo', label: 'Pelo' },
            { key: 'Chamarras-Capas', label: 'Capas y ponchos' },
            { key: 'Chamarras-Rompevientos', label: 'Rompevientos' },
          ]
        },
        {
          key: 'Deportiva', label: 'Ropa deportiva',
          options: [
            { key: 'Deportiva-Tops', label: 'Tops deportivos' },
            { key: 'Deportiva-Pantalones', label: 'Pantalones/leggings deportivos' },
            { key: 'Deportiva-Shorts', label: 'Shorts deportivos' },
            { key: 'Deportiva-Bras', label: 'Bras deportivos' },
            { key: 'Deportiva-Ski', label: 'Ropa de esquiar' },
          ]
        },
        {
          key: 'Jumpsuits', label: 'Monos y Jumpsuits',
          options: [
            { key: 'Jumpsuits-Cortos', label: 'Cortos' },
            { key: 'Jumpsuits-Largos', label: 'Largos' },
          ]
        },
        {
          key: 'Playa', label: 'De baño',
          options: [
            { key: 'Playa-Bikinis', label: 'Bikinis' },
            { key: 'Playa-Completos', label: 'Trajes completos' },
            // { key: 'Playa-Kimonos', label: 'Pareos y kimonos' },
            { key: 'Playa-Salidas', label: 'Salidas de baño' },
          ]
        },
        {
          key: 'Sets', label: 'Sets',
          options: [
            { key: 'Sets-FaldaTop', label: 'Falda-top' },
            { key: 'Sets-ShortsTop', label: 'Shorts-top' },
            { key: 'Sets-PantalonTop', label: 'Pantalón-top' },
          ]
        },
        {
          key: 'Bolsas', label: 'Bolsas',
          options: [
            { key: 'Bolsas-Mano', label: 'Bolsas de mano' },
            { key: 'Bolsas-Shopper', label: 'Totes' },
            { key: 'Bolsas-Bandolera', label: 'Crossbody' },
            { key: 'Bolsas-Clutchs', label: 'Clutchs y de noche' },
            { key: 'Bolsas-Mochilas', label: 'Mochilas' },
            { key: 'Bolsas-Carteras/Monederos', label: 'Carteras y Monederos' },
            { key: 'Bolsas-Rinoneras', label: 'Riñoneras' },
            { key: 'Bolsas-Cosmetiquera', label: 'Otras' },
          ]
        },
        {
          key: 'Accesorios', label: 'Accesorios',
          options: [
            { key: 'Accesorios-LentesSol', label: 'Lentes de sol' },
            { key: 'Accesorios-Tocados', label: 'Tocados'},
            { key: 'Accesorios-Pelo/Sombreros', label: 'Sombreros y Accesorios de pelo' },
            { key: 'Accesorios-Cinturones', label: 'Cinturones' },
            { key: 'Accesorios-Panuelos', label: 'Pañuelos' },
            { key: 'Accesorios-Bufandas', label: 'Bufandas y Guantes' },
            { key: 'Accesorios-LentesOpticos', label: 'Lentes ópticos' },
            { key: 'Accesorios-Calcetines', label: 'Calcetines y Medias' },
            { key: 'Accesorios-Collares', label: 'Collares' },
            { key: 'Accesorios-Aretes', label: 'Aretes' },
            { key: 'Accesorios-Anillos', label: 'Anillos' },
            { key: 'Accesorios-Pulseras', label: 'Pulseras' },
            { key: 'Accesorios-Reloj', label: 'Relojes' },
          ]
        },
        {
          key: 'Zapatos', label: 'Zapatos',
          options: [
            { key: 'Zapatos-Tacones', label: 'Tacones' },
            { key: 'Zapatos-SandaliasTacon', label: 'Sandalias de tacón' },
            { key: 'Zapatos-Sandalias', label: 'Sandalias' },
            { key: 'Zapatos-Flats', label: 'Zapatillas y Flats' },
            { key: 'Zapatos-Mocasines', label: 'Mocasines' },
            { key: 'Zapatos-Plataformas', label: 'Plataformas' },
            { key: 'Zapatos-Tenis', label: 'Tenis casuales' },
            { key: 'Zapatos-Deportivos', label: 'Tenis deportivos' },
            { key: 'Zapatos-Botas', label: 'Botas y Botines' },      
            { key: 'Zapatos-BotasTacon', label: 'Botas y Botines de tacón' },
          ]
        },
        {
          key: 'Lenceria', label: 'Lencería y Pijamas',
          options: [
            { key: 'Lenceria-Pijamas', label: 'Pijamas' },
            { key: 'Lenceria-Bras', label: 'Bras' },
            { key: 'Lenceria-Conjuntos', label: 'Conjuntos' },
          ]
        },
        {
          key: 'Maternidad', label: 'Ropa de maternidad',
          options: [
            { key: 'Maternidad-Pantalones', label: 'Pantalones maternidad' },
            { key: 'Maternidad-Shorts', label: 'Shorts maternidad' },
            { key: 'Maternidad-Faldas', label: 'Faldas maternidad' },
            { key: 'Maternidad-Vestidos', label: 'Vestidos maternidad' },
            { key: 'Maternidad-Tops', label: 'Tops maternidad' },
          ]
        },
         {
          key: 'Promociones', label: 'Promociones',
          options: []
        },
        {
          key: 'Otros', label: 'Otros',
          options: [
            { key: 'Otros-Disfraces', label: 'Disfraces' },
          ]
        },
      ],
    },
  },
  {
    id: 'price',
    label: 'Precio',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 150000,
      step: 5,
    },
  },
  
  {
    id: 'available',
    label: 'available',
    type: 'availableFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      options: [
        { key: 'Disponible', label: 'Disponible' },
        { key: 'No-Disponible', label: 'No disponible' },
      ]
    },
  },
  {
    id: 'sizeAccessories',
    label: 'Talla',
    type: 'ReloveSizeFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'uni', label: 'Unitalla' },

        { key: 'standar-xxs', label: 'XXS', group: 'Ropa (Estándar)' },
        { key: 'standar-xs', label: 'XS', group: 'Ropa (Estándar)' },
        { key: 'standar-s', label: 'S', group: 'Ropa (Estándar)' },
        { key: 'standar-m', label: 'M', group: 'Ropa (Estándar)' },
        { key: 'standar-l', label: 'L', group: 'Ropa (Estándar)' },
        { key: 'standar-xl', label: 'XL', group: 'Ropa (Estándar)' },
      ]
    }
  },
  {
    id: 'sizeShoes',
    label: 'Talla',
    type: 'ReloveSizeFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [

        { key: 'shoesUS-5', label: '5 US/ 2 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-55', label: '5.5 US/ 2.5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-6', label: '6 US/ 3 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-65', label: '6.5 US/ 3.5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-7', label: '7 US/ 4 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-75', label: '7.5 US/ 4.5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-8', label: '8 US/ 5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-85', label: '8.5 US/ 5.5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-9', label: '9 US/ 6 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-95', label: '9.5 US/ 6.5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-10', label: '10 US/ 7 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-105', label: '10.5 US/ 7.5 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-11', label: '11 US/ 8 MX', group: 'Zapatos (US/MX)' },
        { key: 'shoesUS-115', label: '11.5 US/ 8.5 MX', group: 'Zapatos (US/MX)' },

        { key: 'shoesCM-22', label: '22', group: 'Zapatos (CM)' },
        { key: 'shoesCM-225', label: '22.5', group: 'Zapatos (CM)' },
        { key: 'shoesCM-23', label: '23', group: 'Zapatos (CM)' },
        { key: 'shoesCM-235', label: '23.5', group: 'Zapatos (CM)' },
        { key: 'shoesCM-24', label: '24', group: 'Zapatos (CM)' },
        { key: 'shoesCM-245', label: '24.5', group: 'Zapatos (CM)' },
        { key: 'shoesCM-25', label: '25', group: 'Zapatos (CM)' },
        { key: 'shoesCM-255', label: '25.5', group: 'Zapatos (CM)' },
        { key: 'shoesCM-26', label: '26', group: 'Zapatos (CM)' },
        { key: 'shoesCM-265', label: '26.5', group: 'Zapatos (CM)' },
        { key: 'shoesCM-27', label: '27', group: 'Zapatos (CM)' },
        { key: 'shoesCM-275', label: '27.5', group: 'Zapatos (CM)' },
        { key: 'shoesCM-28', label: '28', group: 'Zapatos (CM)' },
        { key: 'shoesCM-285', label: '28.5', group: 'Zapatos (CM)' },
      ],
    },
  },
  {
    id: 'color',
    label: 'Color',
    type: 'ReloveColorFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'rojo', label: 'Rojo', color: '#e5473c'},
        { key: 'rosa', label: 'Rosa', color: '#ffcfd2'},
        { key: 'amarillo', label: 'Amarillo', color: '#fdd740' },
        { key: 'naranja', label: 'Naranja', color: '#faa534'},
        { key: 'dorado', label: 'Dorado', color: `url(${Gold})`},
        { key: 'plateado', label: 'Plateado', color: `url(${Silver})`},
        { key: 'verde', label: 'Verde', color: '#1ba186'},
        { key: 'azul', label: 'Azul', color: '#3e98d9'},
        { key: 'morado', label: 'Morado', color: '#8f40ab'},
        { key: 'negro', label: 'Negro', color: '#000000'},
        { key: 'gris', label: 'Gris', color: '#c1c1c1'},
        { key: 'blanco', label: 'Blanco', color: '#ffffff'},
        { key: 'crema', label: 'Crema', color: '#e3cfb8'},
        { key: 'cafe', label: 'Café', color: '#65340d'},
        { key: 'animal', label: 'Animal Print', color: `url(${Animal})` },
        { key: 'floral', label: 'Floral Print', color: `url(${Floral})` },
        { key: 'multi', label: 'Multicolor', color: `url(${Multi})` },
      ],
    },
  },
  {
    id: 'style',
    label: 'Ocasión',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_style'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Playero', label: 'Playa', },
        { key: 'Formal', label: 'Formal', },
        { key: 'Oficina', label: 'Oficina', },
        { key: 'Bridal', label: 'Novia/Bridal', },
        { key: 'Vintage', label: 'Vintage', },
        { key: 'Frio', label: 'Para la nieve/ frío', },
      ],
    },
  },
 ,
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Mas nuevos' },
    { key: '-pub_updatedAt', label: 'Recién actualizados' },
    { key: '-createdAt', label: 'Mas antiguos' },
    { key: '-price', label: 'Mas baratos' },
    { key: 'price', label: 'Mas caros' },
    

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevancia', longLabel: 'Relevancia (Búsqueda)' },
  ],
};
