import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import css from './pagination.css';
import PropTypes from 'prop-types';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if(!paginationRange){
        return null
    }
    
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    let lastPage = paginationRange[paginationRange.length - 1];


    const onNext = () => {

        if(currentPage !== lastPage){
            onPageChange(currentPage + 1);

        }
    };

    const onPrevious = () => {
        if(currentPage !== 1){
            onPageChange(currentPage - 1);

        }
    };

    return (
        <ul
            className={classnames(css['pagination-container'], { [className]: className })}
        >
            <li
                className={classnames(css['pagination-item'], { disabled: currentPage === 1 })}
                onClick={onPrevious}
            >
                <ChevronLeftIcon />
            </li>
            {paginationRange.map(pageNumber => {
                if (pageNumber === DOTS) {
                    return <li className={css["pagination-item dots"]}>&#8230;</li>;
                }
                
                return (
                    <li
                        className={classnames(css['pagination-item'], {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={classnames(css['pagination-item'], { disabled: currentPage === lastPage })}
                onClick={onNext}
            >
                <ChevronRightIcon />
            </li>
        </ul>
    );
};


const { string,  number, func } = PropTypes;

Pagination.propTypes = {
    className: string,
    onPageChange: func,
    totalCount: number,
    siblingCount: number,
    currentPage: number

};

export default Pagination;
