import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import imgDesktop from '../../assets/background-landing.avif';
import imgMobile from '../../assets/background-landing-mobile.avif';
import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className, isAuthenticated, scrollHandler } = props;




  const classes = classNames(rootClassName || css.root, className);

  const ctaButton = isAuthenticated ? (
    <NamedLink
      name="SearchPage"
      to={{
        search:
          '',
      }}
      className={css.heroButton}
    >
      <FormattedMessage id="SectionHero.browseButton" />
    </NamedLink>
  ) : (
    <NamedLink
      name="SignupPage"
      className={css.heroButton}
    >
      ¡Regístrate aquí!
    </NamedLink>
  );

  const tohowItWorks = (
    <div onClick={scrollHandler} className={css.linkToHowItWorks}>
      <FormattedMessage id="SectionHero.tohowItWorks" />
    </div>
  );

  return (

    <div className={classes}>
      <img src={imgDesktop }   draggable="false" loading='lazy' className={classNames(css.imgHero, css.desktopOnly)} />
      <img src={imgMobile }   draggable="false" loading='lazy' className={classNames(css.imgHero, css.mobileOnly)} />
      <div className={css.contentContainer}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} md={8} className={css.heroContent}> {/*12 6*/}
            <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.title" />
            </h1>
            {ctaButton}
            {tohowItWorks}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
