import classNames from 'classnames';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import {
  BookingTimeInfo, Button, ExternalLink, Footer,
  IconSpinner, LayoutSideNavigation, LayoutWrapperFooter, LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar, NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks, ReloveIconComment, UserDisplayName
} from '../../components';
import config from '../../config';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  txHasBeenDelivered,
  txHasBeenDeliveredLegacy,
  txHasReminder, 
  txIsAccepted, 
  txIsPendingValidation,
  txIsClaimed, 
  txIsDeclined,
  txPaymentFailed,
  txIsDeliveredConfirmed, 
  txIsPaymentExpired,
  txIsPaymentExpiredQuickPurchase,
  txIsPaymentPending,
  txIsPaymentPendingQuickPurchase,
  txIsManualPaymenConfirmationPending, 
  txIsRequested,
  txIsRequestedQuickPurchase, 
  txIsTrackingConfirmed,
  txIsManualPaymentPending,
  txIsPendingPayout,
  txIsClabeMissing,
  txHasBeenCancelled,
  txHasBeenPaid,
  txIsAuthenticating,
  txIsAuthenticatingTrackingConfirmed,
  txIsAuthenticationCompleted,
  txIsReminderSent
} from '../../util/transaction';
import { DATE_TYPE_DATE, propTypes } from '../../util/types';
import css from './InboxPage.css';
import { loadData, setApiClaim, setApiReceived } from './InboxPage.duck';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';
import { messageTransactionValidation, sendEnquiry } from '../ListingPage/ListingPage.duck';
import SectionInbox from '../ListingPage/SectionInbox';
import { sendMessageWhatsapp } from '../../util/api';
import { useHistory } from 'react-router-dom';

const REACT_APP_LEGACY_PROCESS_VERSION = process.env.REACT_APP_LEGACY_PROCESS_VERSION


// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {

  const isOrder = type === 'order';
  if (txIsRequested(tx) || txIsRequestedQuickPurchase(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateRequested',
      }),
    };
  } else if (txIsPaymentPending(tx) || txIsPaymentPendingQuickPurchase(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsManualPaymenConfirmationPending(tx) || txIsManualPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: isOrder ? intl.formatMessage({
        id: 'InboxPage.statePendingManualPayment',
      }):intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      })
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsPaymentExpiredQuickPurchase(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if(txPaymentFailed(tx)){
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePaymentFailed',
      }),
    };
  } else if (txIsAccepted(tx) || txIsPendingValidation(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.statePending',
      }),
    };
  } else if (txIsAuthenticating(tx)){

    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateProductAuthenticating',
      }),
    };
  }else if (txIsAuthenticatingTrackingConfirmed(tx)){
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateProductAuthenticatingTrackingConfirmed',
      }),
    };
  } else if (txIsAuthenticationCompleted(tx)){
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateProductAuthenticated',
      }),
    };
  }else if (txIsTrackingConfirmed(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateTrackingConfirmed',
      }),
    };
  } else if (txIsDeliveredConfirmed(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeliveredConfirmed',
      }),
    };
  } else if (txIsClaimed(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeliveredConfirmed',
      }),
    };
  } else if (txHasBeenCancelled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if(txIsClabeMissing(tx)){
    return{
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.missingClabe',
      }),
    }
  } else if ((tx.attributes.processVersion >= REACT_APP_LEGACY_PROCESS_VERSION && txHasBeenDelivered(tx)) || (tx.attributes.processVersion < REACT_APP_LEGACY_PROCESS_VERSION && txHasBeenDeliveredLegacy(tx)) || txHasBeenPaid(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if (txIsReminderSent(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePending',
      }),
    };
  }else if (txIsPendingPayout(tx)){
    return{
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayout',
      }),
    }
  }else{
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATE}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export const InboxItem = props => {
  const { type, tx, intl, stateData } = props;
  const { customer, provider } = tx;
  const isOrder = type === 'order';
  const [openModal, setOpenModal] = useState(false);

  const [sendEnquiryInProgress, setSendEnquiryInProgress] = useState(false);

  const history = useHistory();

  const otherUser = isOrder ? provider : customer;
  const userType = isOrder ? 'Vendedor: ' : 'Comprador: ';
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;

  const dispatch = useDispatch();
const  onManageDisableScrolling =  (componentId, disableScrolling) =>
  dispatch(manageDisableScrolling(componentId, disableScrolling))


  const  onMessageTransactionValidation = (listingId, receiver, sender) => dispatch(messageTransactionValidation(listingId, receiver, sender))
  const onSendEnquiry =  (listingId,receiver,sender, message)=> dispatch(sendEnquiry(listingId,receiver,sender, message))

  function prepareFeatures (listingData, filterSettings) {
    let features = {};

    filterSettings.forEach(filter => {
      if (listingData[filter.id] !== undefined && "config" in filter && "options" in filter.config) {
        let value = listingData[filter.id];
        if (Array.isArray(value)) {
          if(filter.type === "ReloveCategoryFilter") {
            features[filter.label] = [];

            let categoryItem = filter.config.options.find( ({ key }) => key === value[0]);
            features[filter.label].push(categoryItem ? categoryItem.label : value[0]);

            if(value.length > 1) {
              let subcategoryItem = categoryItem ? categoryItem.options.find(({ key }) => key === value[1]) : null;
              features[filter.label].push(subcategoryItem ? subcategoryItem.label : value[1]);
            }
          } else {
            features[filter.label] = value.map(item => {
              let itemFound = filter.config.options.find( ({ key }) => key === item);
              return itemFound ? itemFound.label : item;
            });
          }
        } else {
          let found = filter.config.options.find( ({ key }) => key === value);
          features[filter.label] =  found ? [found.label] : [value];
        }
      }
    })

    return features;
  }

  const formatNumber = (price) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(price);
  };

  const prepareMeta = (metadata, state, lastTransaction) => {
    let type = '';

    switch (state) {
      case 'Rechazada':
        type = 'rejected';
        break;
      case 'Pago Rechazado':
        type = 'paymentRejected';
        break;
      case 'Aceptada':
        type = 'accepted';
        break;
      case 'Finalizada':
        type = 'delivered';
        break;
      case 'Pago expirado':
        type = 'expired_payment';
        break;
      case 'Enviada':
        type = 'delivering';
        break;
      case 'Autenticando':
        type = 'authenticating'
        break;
      case 'Enviado_Autenticado':
        type = 'delivering_auth'
        break;
      case 'Pago_Fallido':
        type = 'payment_failed'
        break;
      case 'Autenticado':
        type = 'authenticated'
        break;
      case 'Cancelada':
        type = 'cancelled';
        break;
      case 'Pendiente':
        type = 'pending';
        break;
      case 'Pago pendiente':
        type = 'pending_payment';
        break;
      case 'Recibida':
        type = 'received';
        break;
      case 'Solicitada':
        type = 'requested';
        break;
      case 'Pregunta':
        type = 'asked';
        break;
      case 'Actualiza tu método de pago':
        type = 'manual_payment_pending'
      break
      case 'Clabe faltante':
        type = isOrder ? 'received' : 'missing_clabe'
        break;
      default:
        type = 'none';
        break;
    }

    const mehStates = ['expired_payment', 'pending_payment', 'requested', 'rejected', 'received', 'delivered', 'cancelled','manual_payment_pending', 'missing_clabe', 'none', 'delivering_auth', 'authenticating', 'authenticated', 'payment_failed']
    if (!mehStates.includes(type)) {
      if (typeof metadata.delivered === 'undefined' && type === 'delivered') {
        type = 'pending';
      }

      if (typeof metadata.delivered !== 'undefined' && metadata.delivered) {
        type = 'delivered'
      }

      if (typeof metadata.delivered !== 'undefined' && !metadata.delivered) {
        const trackingConfirmedTransactions = [
          'transition/confirm-tracking',
          'transition/confirm-tracking-after-reminder',
        ];
      }
    }

    if (lastTransaction === 'transition/claim') {
      type = 'claimed';
    }

    if (lastTransaction === 'transition/decline-claim') {
      type = 'delivered';
    }

    if(lastTransaction === 'transition/reject-payment'){
      type = 'paymentRejected'
    }

    const types = {
      rejected: {label: 'Oferta Rechazada', className: css.rejected},
      paymentRejected: {label: 'Pago rechazado', className: css.rejected},
      accepted: {label: 'Aceptado', className: css.pending},
      delivered: {label: 'Entregado', className: css.completed},
      received: {label: 'Entregado', className: css.completed},
      expired_payment: {label: 'Pago Expirado', className: css.rejected},
      cancelled: {label: 'Cancelado', className: css.rejected},
      pending: {label: 'Pendiente de Envío', className: css.pending},
      pending_payment: {label: 'Pago Pendiente', className: css.pending},
      requested: {label: isOrder ? 'Oferta realizada' : 'Oferta recibida', className: css.pending},
      asked: {label: 'En espera de respuesta', className: css.pending},
      delivering: {label: isOrder ? 'En camino a tu domicilio' : 'En camino al domicilio', className: css.pending},
      authenticating: {label: 'Está siendo autenticando', className: css.pending},
      delivering_auth: {label: 'En camino a gloset', className: css.pending},
      payment_failed: {label: 'El pago falló', className: css.rejected},
      authenticated: {label: isOrder ? 'En camino a tu domicilio' : 'En camino al domicilio', className: css.pending},
      claimed: {label: 'Caso abierto', className: css.pending},
      manual_payment_pending:{label: 'Actualiza tu método de pago', className: css.pending},
      missing_clabe:{label: 'Falta clabe', className: css.pending},
      none: {label: '', className: css.pending},
      error: {label: 'error', className: css.rejected}
    };

    return {
      state: {
        status: type,
        label: types[type].label,
        className: types[type].className,
      },
      tracking_number: metadata.tracking_number,
      tracking_url: metadata.tracking_url,
      tracking_download_url: metadata.tracking_download_url,
      clientCase: metadata.client_case ? metadata.client_case : {
        exists: false,
        messages: []
      },
    }
  };

  const onContactUser= ()=> {
    const {
      location,
      tx
    } = props;
    const { listing } =tx;
    const routes = routeConfiguration();
    

    
    const listingId = listing.id.uuid;

    if (!otherUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {

      const receiver = tx.provider.id.uuid
      const sender = tx.customer.id.uuid
      onMessageTransactionValidation(listingId, receiver, sender)
        .then(txId => {
          if (txId !== null) {
            history.push(
              createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
            );
          } else {
            setOpenModal(true)
          }
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    }
  }

  const originalPrice = tx.listing.attributes.price ? tx.listing.attributes.price.amount/100 : 0;

  const lineItems = tx.attributes.lineItems || [];

  const payinLineItems = lineItems.filter(item => item.includeFor.includes('customer'));
  const payinTotalAmount = payinLineItems.reduce((total, item) => total + item.lineTotal.amount, 0) / 100;
  
  const payoutLineItems = lineItems.filter(item => item.includeFor.includes('provider'));
  const payoutTotalAmount = payoutLineItems.reduce((total, item) => total + item.lineTotal.amount, 0) / 100;
  

  const listingPrice = lineItems.find(item => item.code === "line-item/night");
  let payedPrice = stateData.state === 'Rechazada' ? 0 : isOrder ?  payinTotalAmount : listingPrice.lineTotal.amount/100;  

  let payoutTotal = tx.attributes.payoutTotal.amount/100;

  const price = formatNumber(originalPrice);
  payedPrice = formatNumber(payedPrice);
  payoutTotal = formatNumber(payoutTotal);

  const values = prepareFeatures(tx.listing.attributes.publicData, config.custom.filters);
  const lastTransaction = tx.attributes ? tx.attributes.lastTransition : null;
  const metaCustom = prepareMeta(tx.attributes.metadata, stateData.state, lastTransaction);

  let expiredTransactions = [
    'transition/expire-payment',
    'transition/expire-payment-quick-purchase',
  ];
  if (expiredTransactions.includes(lastTransaction)) {
    return null;
  }

  const isRequest = metaCustom.state.status === 'requested';

  const isManualPaymentPending = stateData.state === "Actualiza tu método de pago"

  const paymentDetails = isOrder ?
    (<div className={css.orderDetailPayment}>Tu pago: <span>{payedPrice}</span></div>) :
    (<div className={css.orderDetailPayment}>
      Precio de venta: <span>{payedPrice}</span><br/>
      Tus ganancias: <span><b>{payoutTotal}</b></span>
    </div>);

  const canShowTrackingTransactions = [
    'transition/confirm-tracking',
    'transition/confirm-tracking-after-reminder',
    'transition/send-reminder',
    'transition/accept',
    'transition/accept-quick-purchase',
    'transition/complete-manual-payment',
    'transition/accept-purchase',
    'transition/approve-payment',
    'transition/approve-offer-payment',
    'transition/conekta-payment-quick-purchase',
    'transition/complete-authentication',
    'transition/confirm-tracking-for-authentication',
    'transition/confirm-tracking-for-authentication-after-reminder',
    'transition/offer-payment-successful'
  ];

  const trackingConfirmedTransactions = [
    'transition/confirm-tracking',
    'transition/confirm-tracking-after-reminder',
    'transition/complete-authentication',
    'transition/confirm-tracking-for-authentication',
    'transition/confirm-tracking-for-authentication-after-reminder'
  ];

  const track = isOrder ? 'Rastrea tu pedido' : 'Rastrea tu Venta';
  const trackingContainer = canShowTrackingTransactions.includes(lastTransaction)
    && metaCustom.tracking_number !== '' && metaCustom.tracking_number !== undefined
    && metaCustom.tracking_download_url !== '' && metaCustom.tracking_download_url !== undefined ?
    (trackingConfirmedTransactions.includes(lastTransaction) ? 
    (
      <div className={css.orderDetailRow}>
        <div className={css.orderHasTracking}>{track}{' '}
          <ExternalLink className={css.trackingUrl} href={metaCustom.tracking_url}>{metaCustom.tracking_number}</ExternalLink></div>
        <br/>
      </div>
    )
    :
    (!isOrder ? (<div className={css.orderDetailRow}>
      <div className={css.orderHasTracking}>Descarga la guia{' '}
        <ExternalLink className={css.trackingUrl} href={metaCustom.tracking_download_url}>{metaCustom.tracking_number}</ExternalLink></div>
      <br/>
    </div>) : null)
    ): null
  const actionOpenRequest = isRequest && !isOrder ? (
    <div className={css.orderDetailRow}>
      <div>
        <Button className={css.setCompleted} onClick={() => {
          window.location.href = '/sale/' + tx.id.uuid + '/details';
        }}>Contestar oferta</Button>
      </div>
    </div>
  ) : null;

  const confirmReceiveBtn = stateData.state === 'Clabe faltante' && !isOrder ? (
    <div className={css.orderDetailRow}>
      <div>
        <Button className={css.setCompleted} onClick={() => {
          window.location.href = '/sale/' + tx.id.uuid + '/details';
        }}>Actualizar Clabe</Button>
      </div>
    </div>
  ) : null;

  const actionPendingManualPayment = isManualPaymentPending && isOrder ? (
    <div className={css.orderDetailRow}>
      <div>
        <Button className={css.setCompleted} onClick={() => {
          window.location.href = '/order/' + tx.id.uuid + '/details';
        }}>Actualizar Método Pago</Button>
      </div>
    </div>
  ) : null;

  const handleReceiving = (transactionId) => {
    const { onSetApiReceived } = props;
    onSetApiReceived(transactionId, 'received', lastTransaction);
  };

  const hasMetaTracking = metaCustom.tracking_number !== '' && metaCustom.tracking_number !== undefined;

  const allowReceive = [
    'transition/confirm-delivering',
    'transition/confirm-delivering-by-operator',
    'transition/confirm-delivering-by-customer'
  ];
  const actionReceived = isOrder && allowReceive.includes(lastTransaction) && hasMetaTracking ?
    (
      <div className={css.orderDetailRow}>

        <div className={css.setCompletedLabel}>¿Recibiste el pedido como estaba descrito? Al completarlo, el dinero se entregará a la vendedora</div>
        <div>
          <Button className={css.setCompleted} onClick={() => {
            handleReceiving(tx.id.uuid, lastTransaction);
          }}>Completar Pedido</Button>
        </div>
      </div>
    ) : null;

  const openCase = (transactionId, lastTransaction) => {
    const { onSetApiReceived } = props;
    onSetApiReceived(transactionId, 'claim', lastTransaction);
  };

  const allowClaimStatuses = [
    'transition/confirm-tracking',
    'transition/confirm-tracking-after-reminder',
    'transition/confirm-delivering-by-operator',
    'transition/claim',
  ];
  const allowClaim = allowClaimStatuses.includes(lastTransaction);

  const caseContainer = isOrder ?
    (allowClaim ? (!metaCustom.clientCase.exists ? (<div className={css.orderDetailRow}>
        <div className={css.orderHasCaseStatus}>¿Hubo algún contratiempo? Haz click en
          <span className={css.startCase} onClick={() => {openCase(tx.id.uuid, lastTransaction)}}> Abrir Caso</span>
          {' '} y mándanos un correo a <a href="mailto:soporte@gloset.mx">soporte@gloset.mx</a>{' '} y te ayudaremos lo más pronto posible.
        </div>
      </div>)
        : (lastTransaction !== 'transition/decline-claim' ?
            (<div className={css.orderHasCaseStatus}>
              Hay un caso abierto, nuestro personal se comunicará contigo para resolverlo.
              Puedes enviar un correo a <a href="mailto:soporte@gloset.mx">soporte@gloset.mx</a>{' '} para contactárnos
            </div>)
          : null)
    ) : null) : null;

  const size = values && values['Talla'] && values['Talla'][0] ? values['Talla'][0] : 'No Definida';
  const brand = values && values['Marca'] && values['Marca'][0] ? values['Marca'][0] : 'No Definida';

  const linkProps = otherUser.id
    ? { name: 'ProfilePage', params: { id: otherUser.id.uuid } }
    : { name: 'ProfileBasePage' };

  const handleChat = (e)=>{ 
    e.stopPropagation();
    onContactUser();
  };

  const onSubmitEnquiry = (values) =>{
    const { tx } = props;
    const routes = routeConfiguration();
    const { listing, provider, customer } = tx;

    const listingId = listing.id.uuid;
    const { message } = values;

    const receiver = provider.id.uuid;
    const sender = customer.id.uuid;
    //send listing so that onEnquiry we can validate that there is a message with this listingId
    // let enquiryParamas = {listing}


    setSendEnquiryInProgress(true)
    onSendEnquiry(listingId,receiver,sender, message.trim())
      .then(txId => {
        
        setOpenModal(false);
        setSendEnquiryInProgress(false)
        const type = 'message';
        sendMessageWhatsapp({ sender, receiver, currentListing:listing, type });
        // Redirect to MessageCustomerDetailsPage
        history.push(
          createResourceLocatorString('MessageCustomerDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        setSendEnquiryInProgress(false)
        // Ignore, error handling in duck file
      });
  }

  return (
    <div
      className={css.orderContainer}
    >
      <SectionInbox
      isEnquiryModalOpen={openModal}
      onManageDisableScrolling={onManageDisableScrolling}
      onCloseEnquiryModal={()=>setOpenModal(false)}
      onSubmitEnquiry={onSubmitEnquiry}
      sendEnquiryInProgress={sendEnquiryInProgress}
      />
      <div className={css.orderDetailRow}>
        <NamedLink
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
          className={css.fullWidth}
        >
        <div className={css.orderDetailPicture}>
          <img
            src={tx.listing.images[0].attributes.variants['square-small'].url}
            width="155"
            height="155"
            alt=""
          />
        </div>
        <div className={css.orderDetailListing}>
          <p className={css.orderDetail}>{tx.listing.attributes.title}</p>
          <p className={css.orderDetailPrice}>{price} MXN</p>
          <p className={css.orderDetail}>Talla: {size}</p>
          <p className={css.orderDetail}>Marca: {brand}</p>
          <p className={css.orderDetail}>{userType}
            <NamedLink {...linkProps}>
              <span className={css.orderDetailUser}>{otherUserDisplayName}</span>
            </NamedLink>
          </p>
        </div>
        {paymentDetails}
        </NamedLink>
          {isOrder && <Button type='button' className={css.chatButton} onClick={handleChat} >
            <ReloveIconComment className={css.chatButtonIcon} />
            <span className={css.textButtonChat} >Chat</span>
          </Button>}
      </div>
      <div className={css.orderDetailRow}>
        <div className={css.orderDetailStatus}>ESTATUS <span className={metaCustom.state.className}>{metaCustom.state.label}</span></div>
      </div>
      {actionOpenRequest}
      {actionPendingManualPayment}
      {confirmReceiveBtn}
      {trackingContainer}
      {actionReceived}
      {caseContainer}
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    onSetApiReceived,
  } = props;

  let { transactions} = props;

  transactions = transactions.filter(transaction => {
    const lastTransition = transaction.attributes.lastTransition;
    const disabledTransitions = [
      'transition/expire-payment',
      'transition/expire-payment-quick-purchase',
    ];
  
    // Exclude 'reject-payment' transitions for providers
    const isProviderTab = params.tab === 'sales';
    if (isProviderTab && lastTransition === 'transition/reject-payment') {
      return false;
    }
  
    return !disabledTransitions.includes(lastTransition);
  });
  
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const toTxItem = tx => {
    if (tx.listing.attributes.title === null) {
      return null;
    }
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem unitType={unitType} type={type} tx={tx} intl={intl} stateData={stateData} onSetApiReceived={onSetApiReceived}/>
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;
  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.salesTabTitle" />
          {providerNotificationBadge}
        </span>
      ),
      selected: !isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
    },
  ];
  // const nav = <TabNav rootClassName={css.tabs} tabs={tabs} />;

  return (
    <Page title={title} className={css.root} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            {error}
            <h2 className={css.inboxTitle}>{isOrders ? 'Mis Compras' : 'Mis Ventas'}</h2>
            <ul className={css.itemList}>
              {!fetchInProgress ? (
                transactions.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}
              {noResults}
            </ul>
            {pagingLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const mapDispatchToProps = dispatch => ({
  onSetApiReceived: (transactionId, action = 'received', lastTransaction = null) => {
    switch (action) {
      case 'received':
        dispatch(setApiReceived(transactionId, lastTransaction));
        break;
      case 'claim':
        dispatch(setApiClaim(transactionId, lastTransaction));
        break;
      default:
        break;
    }
  },
});

const InboxPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
