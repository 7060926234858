/**
 Use this file to edit the Popular Brands on the Landing Page.
 Each brand is enclosed in curly braces ({}) followed by a comma (,), and contains the following elements:
 - id: an unique id, can be a number or a 'text' (enclosed in apostrophes)
 - name: The name of the Brand (enclosed in apostrophes), used only for accesibility.
 - logo: An URL to the image you want to use (enclosed in apostrophes). Or if local files are used, enclosed by a require() function.
 - searchQuery: The search parameters for the brand. This normally consists of a text in the following format '?pub_brand=<BRAND>'. 
   You can copy this from your broser.
 Each of these elements is also followed by a comma (,).
*/

export const brands = [
  {
    id: 1,
    name: 'Alexis',
    logo: require('./assets/brand-logos/alexis.png'), 
    searchQuery: '?pub_brand=Alexis'
  },
  {
    id: 2,
    name: 'Keepsake',
    logo: require('./assets/brand-logos/keepsake.png'),
    searchQuery: '?pub_brand=Keepsake'
  },
  {
    id: 3,
    name: 'Retrofete',
    logo: require('./assets/brand-logos/retrofete.png'),
    searchQuery: '?pub_brand=Retrofete'
  },
  {
    id: 4,
    name: 'Gucci',
    logo: require('./assets/brand-logos/gucci.png'),
    searchQuery: '?pub_brand=Gucci'
  },
  {
    id: 5,
    name: 'Carolina Herrera',
    logo: require('./assets/brand-logos/carolina_herrera.png'),
    searchQuery: '?pub_brand=Carolina%20Herrera'
  },
  {
    id: 6,
    name: 'BCBG',
    logo: require('./assets/brand-logos/bcbgmax.png'),
    searchQuery: '?pub_brand=BCBG%2CBCBG%20Max%20Azria'
  },
  {
    id: 7,
    name: 'Zara',
    logo: require('./assets/brand-logos/zara.png'),
    searchQuery: '?pub_brand=Zara'
  },
  {
    id: 8,
    name: 'Lululemon',
    logo: require('./assets/brand-logos/lululemmon.png'),
    searchQuery: '?pub_brand=lululemon'
  },
]
