/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
 import React, { Component } from 'react';
 import { bool, func, object, string } from 'prop-types';
 import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
 import { Form as FinalForm } from 'react-final-form';
 import classNames from 'classnames';
 import config from '../../config';
 import { propTypes } from '../../util/types';
 import { ensurePaymentMethodCard } from '../../util/data';
 
 import {
   Form,
   PrimaryButton,
   FieldCheckbox,
   FieldTextInput,
   IconSpinner,
   SavedCardDetails,
   StripePaymentAddress,
   MonthlyInstallmentsComponent
 } from '../../components';
 import css from './StripePaymentForm.css';
import { logEvent } from '../../util/logsEvent';
 
 /**
  * Translate a Stripe API error object.
  *
  * To keep up with possible keys from the Stripe API, see:
  *
  * https://stripe.com/docs/api#errors
  *
  * Note that at least at moment, the above link doesn't list all the
  * error codes that the API returns.
  *
  * @param {Object} intl - react-intl object from injectIntl
  * @param {Object} stripeError - error object from Stripe API
  *
  * @return {String} translation message for the specific Stripe error,
  * or the given error message (not translated) if the specific error
  * type/code is not defined in the translations
  *
  */
 const stripeErrorTranslation = (intl, stripeError) => {
   const { message, code, type } = stripeError;
 
   if (!code || !type) {
     // Not a proper Stripe error object
     return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
   }
 
   const translationId =
     type === 'validation_error'
       ? `StripePaymentForm.stripe.validation_error.${code}`
       : `StripePaymentForm.stripe.${type}`;
 
   return intl.formatMessage({
     id: translationId,
     defaultMessage: message,
   });
 };
 
 const stripeElementsOptions = {
   fonts: [
     {
       family: 'sofiapro',
       fontSmoothing: 'antialiased',
       src:
         'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
     },
   ],
 };
 
 const cardStyles = {
   base: {
     fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
     fontSize: '18px',
     fontSmoothing: 'antialiased',
     lineHeight: '24px',
     letterSpacing: '-0.1px',
     color: '#4A4A4A',
     '::placeholder': {
       color: '#B2B2B2',
     },
   },
 };
 
 const OneTimePaymentWithCardElement = props => {
   const { cardClasses, formId, handleStripeElementRef, hasCardError, error, label, intl, isOffer, speculatedTransaction, chosenMsiInstallments, msiIntallmentsChange } = props;


   const percentageIncrease = {
    3: 0.05, // 5% increase for 3-month installment
    6: 0.075, // 7% increase for 6-month installment
    9: 0.10  // 10% increase for 9-month installment
  };
  
  // Function to calculate payinTotals dynamically
  function calculatePayinTotals(chosenMsiInstallments, knownPayinTotal) {
    let originalTotal;
  
    // Initialize payinTotals
    const payinTotals = {
      3: null,
      6: null,
      9: null
    };
  
    // Check if chosenMsiInstallments is null
    if (chosenMsiInstallments === null) {
      // If null, assume knownPayinTotal is the original total
      originalTotal = knownPayinTotal;
    } else {
      // Calculate the original total based on the chosen installment
      originalTotal = knownPayinTotal / (1 + percentageIncrease[chosenMsiInstallments]);
      
      payinTotals[chosenMsiInstallments] = knownPayinTotal;
    }
  
    // Calculate payinTotal for the other installment options
    Object.keys(payinTotals).forEach(installment => {
      if (payinTotals[installment] === null) {
        payinTotals[installment] = originalTotal * (1 + percentageIncrease[installment]);
      }
    });
  
    return payinTotals;
  }
  
  // Usage: When chosenMsiInstallments changes, call this function
  const knownPayinTotal = speculatedTransaction?.attributes?.payinTotal?.amount; // e.g., 110 for 9 months

  const updatedPayinTotals = calculatePayinTotals(chosenMsiInstallments, knownPayinTotal);
 
   const labelText =
     label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });
   return (
     <React.Fragment>
       <label className={css.subTitle} htmlFor={`${formId}-card`}>
         <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
       </label>
       <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
       { knownPayinTotal > 150000 && (
       <MonthlyInstallmentsComponent
          rootClassName={css.MonthlyInstallmentsComponent}
          monthlyInstallmentsBy3Total={updatedPayinTotals[3]}
          monthlyInstallmentsBy6Total={updatedPayinTotals[6]}
          monthlyInstallmentsBy9Total={updatedPayinTotals[9]}
          chosenMsiInstallments={chosenMsiInstallments}
          msiIntallmentsChange={msiIntallmentsChange}
        />
        )}
       {hasCardError ? <span className={css.error}>{error}</span> : null}
       {isOffer ? null : <div className={css.saveForLaterUse}>
         <FieldCheckbox
           className={css.saveForLaterUseCheckbox}
           textClassName={css.saveForLaterUseLabel}
           id="saveAfterOnetimePayment"
           name="saveAfterOnetimePayment"
           label={labelText}
           value="saveAfterOnetimePayment"
           useSuccessColor
         />
         <span className={css.saveForLaterUseLegalInfo}>
           <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
         </span>
       </div>}
     </React.Fragment>
   );
 };
 const PaymentMethodSelector = props => {
   const {
     cardClasses,
     formId,
     changePaymentMethod,
     defaultPaymentMethod,
     handleStripeElementRef,
     hasCardError,
     error,
     paymentMethod,
     intl,
     isOffer,
     speculatedTransaction,
     msiIntallmentsChange,
     chosenMsiInstallments,
     showMsiInstallments
   } = props;


   const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
   const labelText = intl.formatMessage(
     { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
     { last4Digits }
   );

   return (
     <React.Fragment>
       <h3 className={css.paymentHeading}>
       {isOffer ? <FormattedMessage id="StripePaymentForm.payWithHeadingOffer" /> : <FormattedMessage id="StripePaymentForm.payWithHeading" /> }
       </h3>
       <SavedCardDetails
         className={css.paymentMethodSelector}
         card={defaultPaymentMethod.attributes.card}
         onChange={changePaymentMethod}
         speculatedTransaction={speculatedTransaction}
         msiIntallmentsChange={msiIntallmentsChange}
         chosenMsiInstallments={chosenMsiInstallments}
         showMsiInstallments={showMsiInstallments}
       />
       
       {paymentMethod === 'replaceCard' ? (
         <OneTimePaymentWithCardElement
           cardClasses={cardClasses}
           formId={formId}
           handleStripeElementRef={handleStripeElementRef}
           hasCardError={hasCardError}
           error={error}
           label={labelText}
           intl={intl}
           isOffer={isOffer}
           speculatedTransaction={speculatedTransaction}
           chosenMsiInstallments={chosenMsiInstallments}
           msiIntallmentsChange={msiIntallmentsChange}
         />
       ) : null}
     </React.Fragment>
   );
 };
 
 const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
   return selectedPaymentMethod == null && hasDefaultPaymentMethod
     ? 'defaultCard'
     : selectedPaymentMethod == null
     ? 'onetimeCardPayment'
     : selectedPaymentMethod;
 };
 
 const initialState = {
   error: null,
   cardValueValid: false,
   // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
   // Check SavedCardDetails component for more information
   paymentMethod: null,

   isFacturationAddressNeeded: true
 };
 
 /**
  * Payment form that asks for credit card info using Stripe Elements.
  *
  * When the card is valid and the user submits the form, a request is
  * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
  * may ask more details from cardholder if 3D security steps are needed.
  *
  * See: https://stripe.com/docs/payments/payment-intents
  *      https://stripe.com/docs/elements
  */
 class StripePaymentForm extends Component {
   constructor(props) {
     super(props);
     this.state = initialState;
     this.handleCardValueChange = this.handleCardValueChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
     this.paymentForm = this.paymentForm.bind(this);
     this.initializeStripeElement = this.initializeStripeElement.bind(this);
     this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
     this.changePaymentMethod = this.changePaymentMethod.bind(this);
     this.updateParentDisabledValue = this.updateParentDisabledValue.bind(this);
     this.handleCheckboxChange = this.handleCheckboxChange.bind(this); 
     this.finalFormAPI = null;
     this.cardContainer = null;
     this.stripe = null;     
   }
 
   componentDidMount() {
     if (!window.Stripe) {
       throw new Error('Stripe must be loaded for StripePaymentForm');
     }
 
     if (config.stripe.publishableKey) {
       const {
         onStripeInitialized,
         hasHandledCardPayment,
         defaultPaymentMethod,
         loadingData,
       } = this.props;
       this.stripe = window.Stripe(config.stripe.publishableKey, { locale: 'es-419' });
       onStripeInitialized(this.stripe);
 
       if (!(hasHandledCardPayment || defaultPaymentMethod || loadingData)) {
         this.initializeStripeElement();
       }
     }
   }
 
   componentWillUnmount() {
     if (this.card) {
       this.card.removeEventListener('change', this.handleCardValueChange);
       this.card.unmount();
       this.card = null;
     }
   }
 
   initializeStripeElement(element) {
     const elements = this.stripe.elements(stripeElementsOptions);
 
     if (!this.card) {
       this.card = elements.create('card', { style: cardStyles });
       this.card.mount(element || this.cardContainer);
       this.card.addEventListener('change', this.handleCardValueChange);
       // EventListener is the only way to simulate breakpoints with Stripe.
       window.addEventListener('resize', () => {
         if (this.card) {
           if (window.innerWidth < 1024) {
             this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
           } else {
             this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
           }
         }
       });
     }
   }

   updateParentDisabledValue(value){
    if(this.props.submitDisabledStripe !== value){
      this.props.handleSubmitDisabledState(value,'stripe')
    }
   }

   handleCheckboxChange(){
    // this.setState({isFacturationAddressNeeded: !this.props.isFacturationAddressNeeded})
    this.props.setIsFacturationAddressNeeded()
   }
 
    changePaymentMethod(changedTo) {
     if (this.card && changedTo === 'defaultCard') {
       this.card.removeEventListener('change', this.handleCardValueChange);
       this.card.unmount();
       this.card = null;
     }
     this.setState({ paymentMethod: changedTo });
   }
 
   handleStripeElementRef(el) {
     this.cardContainer = el;
     if (this.stripe && el) {
       this.initializeStripeElement(el);
     }
   }
 
   handleCardValueChange(event) {
     const { intl } = this.props;
     const { error, complete } = event;
 
     const postalCode = event.value.postalCode;
     if (complete) {
      logEvent('card info filled');
    }
     if (this.finalFormAPI) {
       this.finalFormAPI.change('postal', postalCode);
     }
 
     this.props.setStripeState({
      error: error ? stripeErrorTranslation(intl, error) : null,
      cardValueValid: complete,
      })

      this.setState(prevState => {
        return {
          error: error ? stripeErrorTranslation(intl, error) : null,
          cardValueValid: complete,
        };
      });
 

   }
   handleSubmit(values) {
     const {
       onSubmit,
       inProgress,
       formId,
       hasHandledCardPayment,
       defaultPaymentMethod,
     } = this.props;
     const { initialMessage } = values;
     const { cardValueValid, paymentMethod } = this.state;
     const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
     const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;
 
     if (inProgress || onetimePaymentNeedsAttention) {
       // Already submitting or card value incomplete/invalid
       return;
     }
 

     const params = {
       message: initialMessage ? initialMessage.trim() : null,
       stripe: this.stripe,
       card: this.card,
       formId,
       formValues: values,
       paymentMethod: getPaymentMethod(
         paymentMethod,
         ensurePaymentMethodCard(defaultPaymentMethod)?.attributes?.stripePaymentMethodId
       ),
     };
     onSubmit(params);
   }
 
   paymentForm(formRenderProps) {
     const {
       className,
       rootClassName,
       inProgress: submitInProgress,
       loadingData,
       formId,
       intl,
       initiateOrderError,
       handleCardPaymentError,
       confirmPaymentError,
       addPaymentMethodError,
       handleCardSetupError,
       invalid,
       handleSubmit,
       form,
       hasHandledCardPayment,
       defaultPaymentMethod,
       isOffer,
       contact,
       speculatedTransaction,
       msiIntallmentsChange,
       chosenMsiInstallments,
       showMsiInstallments
     } = formRenderProps;

     this.finalFormAPI = form;

     const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
     const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
     const billingDetailsKnown = hasHandledCardPayment || ensuredDefaultPaymentMethod;
     const onetimePaymentNeedsAttention = (this.state.paymentMethod !== null && this.state.paymentMethod !== 'defaultCard') && !this.state.cardValueValid;
     const requiresCardValidation = this.state.paymentMethod === null && !ensuredDefaultPaymentMethod?.attributes?.customer;

     const isCardReady = requiresCardValidation ? this.state.cardValueValid : true;

     const submitDisabled = invalid || onetimePaymentNeedsAttention || submitInProgress || !isCardReady;

     this.updateParentDisabledValue(submitDisabled)
     const hasCardError = this.state.error && !submitInProgress;
     const hasPaymentErrors = handleCardPaymentError || confirmPaymentError || addPaymentMethodError || handleCardSetupError;
     const classes = classNames(rootClassName || css.root, className);
     const cardClasses = classNames(css.card, {
       [css.cardSuccess]: this.state.cardValueValid,
       [css.cardError]: hasCardError,
     });


 
     // TODO: handleCardPayment can create all kinds of errors.
     // Currently, we provide translation support for one:
     // https://stripe.com/docs/error-codes

     const piAuthenticationFailure = 'payment_intent_authentication_failure';

     const paymentErrorMessage = handleCardPaymentError
     ? (handleCardPaymentError.code === 'payment_intent_authentication_failure'
       ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentError' })
       : (handleCardPaymentError.code === 'non-credit'
         ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentErrorDebitCard' })
         : (handleCardPaymentError.code === 'payment_intent_invalid_parameter'
           ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentErrorInvalidParameter' })
           : (handleCardPaymentError.code === 'card_wrong_cvv_or_date'
              ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentErrorInvalidValidationParameter' })
              : (handleCardPaymentError.code !== null ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentErrorInvalidValidationParameter' }) : handleCardPaymentError.message)
           )
          )
        )
      )
     : (confirmPaymentError
       ? intl.formatMessage({ id: 'StripePaymentForm.confirmPaymentError' })
       : (addPaymentMethodError
         ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentError' })
         : (handleCardSetupError
           ? handleCardSetupError.message
           : intl.formatMessage({ id: 'StripePaymentForm.genericError' })
           )
         )
       );
   
   


      const billingDetailsNameLabel = intl.formatMessage({
        id: 'StripePaymentForm.billingDetailsNameLabel',
      });
  
      const billingDetailsNamePlaceholder = intl.formatMessage({
        id: 'StripePaymentForm.billingDetailsNamePlaceholder',
      });
  
      // Asking billing address is recommended in PaymentIntent flow.
      // In CheckoutPage, we send name and email as billing details, but address only if it exists.

      const billingAddressToggler = (
        <div className={css.checkbox_container}>
        <FieldCheckbox
          id="facturationCheckbox"
          type="checkbox"
          checked={this.props.isFacturationAddressNeeded}
          onChange={this.handleCheckboxChange}
          className={css.saveForLaterUseCheckbox}
          useSuccessColor
        />
        <label className={css.isFacturationAddressNeededLabel} htmlFor="facturationCheckbox">Misma dirección que la de envío</label>
      </div>
      )
      const billingAddress = (
        <div>        
          {!this.props.isFacturationAddressNeeded && <StripePaymentAddress intl={intl} form={form} fieldId={formId} card={this.card} />}
        </div>
      );
 
      const hasStripeKey = config.stripe.publishableKey;
      const showPaymentMethodSelector = defaultPaymentMethod && ensuredDefaultPaymentMethod.attributes;
      const selectedPaymentMethod = getPaymentMethod(
        this.state.paymentMethod,
        showPaymentMethodSelector
      );
      const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
        selectedPaymentMethod
      );   

     return hasStripeKey ? (
       <Form className={classes} onSubmit={handleSubmit} >
         {billingDetailsNeeded && !loadingData ? (
           <React.Fragment>
              {showPaymentMethodSelector ? (
               <PaymentMethodSelector
                 cardClasses={cardClasses}
                 formId={formId}
                 defaultPaymentMethod={ensuredDefaultPaymentMethod}
                 changePaymentMethod={this.changePaymentMethod}
                 handleStripeElementRef={this.handleStripeElementRef}
                 hasCardError={hasCardError}
                 error={this.state.error}
                 paymentMethod={selectedPaymentMethod}
                 intl={intl}
                 isOffer={isOffer}
                 speculatedTransaction={speculatedTransaction}
                 msiIntallmentsChange={msiIntallmentsChange}
                 chosenMsiInstallments={chosenMsiInstallments}
                 showMsiInstallments={showMsiInstallments}
               />
             ) : (
               <React.Fragment>
                 <OneTimePaymentWithCardElement
                    cardClasses={cardClasses}
                    formId={formId}
                    handleStripeElementRef={this.handleStripeElementRef}
                    hasCardError={hasCardError}
                    error={this.state.error}

                    intl={intl}
                    isOffer={isOffer}
                    speculatedTransaction={speculatedTransaction}
                    chosenMsiInstallments={chosenMsiInstallments}
                    msiIntallmentsChange={msiIntallmentsChange}
                 />

               </React.Fragment>
             )}
             {showOnetimePaymentFields ? (
               <div className={css.paymentAddressField}>
                 <h3 className={css.subTitle}>
                   <FormattedMessage id="StripePaymentForm.billingDetails" />
                 </h3>

                 {billingAddressToggler}
 
                 {!this.props.isFacturationAddressNeeded && <FieldTextInput
                   className={css.field}
                   type="text"
                   id="name"
                   name="name"
                   autoComplete="cc-name"
                   label={billingDetailsNameLabel}
                   placeholder={billingDetailsNamePlaceholder}
                 />}

                 {billingAddress}

               </div>
             ) : null}

            
           </React.Fragment>
         ) : loadingData ? (
           <p className={css.spinner}>
             <IconSpinner />
           </p>
         ) : null}
 
         {initiateOrderError ? (
           <span className={css.errorMessage}>{initiateOrderError.message}</span>
         ) : null}
         {/* {addressContainer} */}
         <div className={css.submitContainer}>

          <span className={hasPaymentErrors ? css.errorMessage : css.errorMessageHidden}>{paymentErrorMessage}</span>
           <PrimaryButton
             className={css.hidden}
             type="submit"
             inProgress={submitInProgress}
             disabled={submitDisabled}
           >
             {billingDetailsNeeded ? (isOffer ? 'Hacer Oferta' : 'Comprar')
               : (isOffer ? 'Confirmar la oferta' : 'Confirmar la compra')}
           </PrimaryButton>
         </div>
       </Form>
       
     ) : (
       <div className={css.missingStripeKey}>
         <FormattedMessage id="StripePaymentForm.missingStripeKey" />
       </div>
     );
   }
 
   render() {
     const { onSubmit, ...rest } = this.props;
     return <FinalForm onSubmit={this.handleSubmit} initialValues={{deliveryOption:'normalDelivery'}} {...rest} render={this.paymentForm} />;
   }
 }
 
 StripePaymentForm.defaultProps = {
   className: null,
   rootClassName: null,
   inProgress: false,
   loadingData: false,
   showInitialMessageInput: true,
   hasHandledCardPayment: false,
   defaultPaymentMethod: null,
   initiateOrderError: null,
   handleCardPaymentError: null,
   confirmPaymentError: null,
   isOffer: false,
 };
 
 StripePaymentForm.propTypes = {
   className: string,
   rootClassName: string,
   inProgress: bool,
   loadingData: bool,
   initiateOrderError: object,
   handleCardPaymentError: object,
   confirmPaymentError: object,
   formId: string.isRequired,
   intl: intlShape.isRequired,
   onSubmit: func.isRequired,
   paymentInfo: string.isRequired,
   authorDisplayName: string.isRequired,
   showInitialMessageInput: bool,
   hasHandledCardPayment: bool,
   defaultPaymentMethod: propTypes.defaultPaymentMethod,
   isOffer: bool,
 };
 
 export default injectIntl(StripePaymentForm, { forwardRef: true });
