import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

const locationLink = (name, image, searchQuery) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <p className={css.aspectText}>{ image }</p>
        </div>
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        {locationLink(
          'Ropa',
          'Ropa',
          '?keywords=ropa'
        )}
        {locationLink(
          'Vestidos',
          'Vestidos',
          '?keywords=vestido'
        )}
        {locationLink(
          'Accesorios',
          'Accesorios',
          '?keywords=accesorio'
        )}
        {locationLink(
          'Zapatos',
          'Zapatos',
          '?keywords=zapato'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
