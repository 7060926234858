import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { CircularProgress } from '@material-ui/core';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { NamedLink, Avatar } from '../../components';

import css from './SectionFollowingLanding.css';

const UserLink = ({ user }) => {
  const displayName = user.attributes.profile.displayName; 
  
  const linkProps = {
    name: 'ProfilePage',
    params: {
      id: user.id.uuid,
    },
    title: `Ver perfil de ${displayName}`,
  };
  return (
    <div className={css.followingItem}>
      <NamedLink {...linkProps}>
          <Avatar className={css.avatar} user={user} disableProfileLink/>
          <p className={css.userLabel}>{ displayName }</p>
      </NamedLink>
    </div>
  );
};

const SectionFollowingLanding = props => {
  const { rootClassName, className, following, inProgress, currentUser, isMobileLayout } = props;

  const user = ensureCurrentUser(currentUser);

  const classes = classNames(rootClassName || css.root, className);

  const seeMoreProps = {
    name: 'ProfilePage',
    params: {
      id: user.id.uuid,
    },
  };

  const sliceLimit = isMobileLayout ? 3 : 8;

  const followingSplice = following.slice(0, sliceLimit);

  return (
    <div className={classes}>
      {following.length ? (
        <h3 className={css.title}><FormattedMessage id="SectionFollowingLanding.title" /> ({following.length}) <NamedLink {...seeMoreProps}>Ver Todo</NamedLink></h3>
      ) : null }
      {!inProgress ? (
        <div className={css.followingContainer}>
          {followingSplice.map((u, i) => (
            <UserLink user={u} key={i}/>
          ))}
        </div>
      ) : (
        <div className={css.loaderContainer}>
          <CircularProgress className={css.loader} color={'inherit'}/>
        </div>
      )}
      
    </div>
  );
};

SectionFollowingLanding.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFollowingLanding.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
}

SectionFollowingLanding.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
};

export default SectionFollowingLanding;
