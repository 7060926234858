import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CropImageButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName ||className);
  return (      
    <button type="button" className={classes} onClick={onClick}>
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 7.75H15.25C15.8023 7.75 16.25 8.19772 16.25 8.75V19.25"/>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 16.25H8.75C8.19772 16.25 7.75 15.8023 7.75 15.25V4.75"/>
        </svg>
    </button>
  );
};

CropImageButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

CropImageButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default CropImageButton;
