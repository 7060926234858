import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, node, object } from 'prop-types';
import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { Form } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './FilterForm.css';


const FilterFormComponent = props => {
  const { autoApply, liveEdit, hasControls, topControls, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (!hasControls && !onChange) {
    throw new Error('FilterForm: if hasControls is false you need to provide onChange function');
  }

  if (hasControls && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'FilterForm: if hasControls is true you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = formState => {
    if (formState.dirty) {
      onChange(formState.values);
    }
  };

  const autoApplyChanges = formState => {
    if (formState.dirty) {
      onSubmit(formState.values);
    }
  }

  const formCallbacks = !hasControls ? { onSubmit: () => null } : { onSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          id,
          handleSubmit,
          onClear,
          style,
          paddingClasses,
          intl,
          children,
        } = formRenderProps;


        const clear = intl.formatMessage({ id: 'FilterForm.clear' });
        const submit = intl.formatMessage({ id: 'FilterForm.submit' });

        const classes = classNames(css.root);

        return (
          <Form
            id={id}
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            style={{ ...style }}
          >
            {hasControls && topControls ? (
              <div className={css.buttonsWrapperTop}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            ) : null}

            {autoApply ? (
              <FormSpy onChange={autoApplyChanges} subscription={{ values: true, dirty: true }} />
            ) : null}
            <div className={classNames(paddingClasses || css.contentWrapper)}>{children}</div>

            {!hasControls ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

FilterFormComponent.defaultProps = {
  liveEdit: false,
  autoApply: false,
  hasControls: false,
  topControls: false,
  style: null,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

FilterFormComponent.propTypes = {
  liveEdit: bool,
  hasControls: bool,
  autoApply: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  style: object,
  children: node.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterForm = injectIntl(FilterFormComponent);

export default FilterForm;
