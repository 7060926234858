/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { array, arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import VisaImg from '../../assets/visa-mastercard.png';
import {
  AuthenticationPageModalWrapper,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import ActionBarMaybe from './ActionBarMaybe';
import CommentsSection from './CommentsSection';
import LikesSection from './LikesSection';
import css from './ListingPage.css';
import {
  fetchTransactionLineItems,
  getListingTransactions,
  loadData,
  setAsSold,
  setInitialValues,
  toggleLike,
  sendEnquiry,
  messageTransactionValidation,
  lowerListingPrice,
} from './ListingPage.duck';
import ReloveSectionImages from './ReloveSectionImages';
import SectionActions from './SectionActions';
import SectionAvatar from './SectionAvatar';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionHeading from './SectionHeading';
import SocialButtons from './SocialButtons';
import SectionInbox from './SectionInbox';
import SectionPriceDiscountMaybe from './SectionPriceDiscountMaybe';

import { getListingById, sendMessageWhatsapp } from '../../util/api';
import { getParamsListing, logEvent } from '../../util/logsEvent';
import SectionProtection from './SectionProtection';
import SectionDetailUser from './SectionDetailUser';
import SectionSocialButtonActions from './SectionSocialButtonActions';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const { UUID, Money } = sdkTypes;


const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.ref = React.createRef();
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      currentImage: '',
      hasPhoneAlert: false,
      isSearching: false,
      isUnavailable: false,
      transactionsFetched: false,
      isSharing: false,
      isHandlingSold: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isDiscountOfferModalOpen: false,

      authContainerOpen: false,

      isOfferModalOpen: false,

      requiresRedirection: false,
      requiresExtraAction: false,
      neededAction: null,

      showWhatsAppChat: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangePicture = this.handleChangePicture.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitNewPrice = this.onSubmitNewPrice.bind(this);

    this.onContactUser = this.onContactUser.bind(this);

    this.handleLoginRedirecting = this.handleLoginRedirecting.bind(this);
    this.triggerExtraAction = this.triggerExtraAction.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.currentUser !== null) {
      const { onGetListingTransactions } = this.props;
      if (!this.state.transactionsFetched) {
        onGetListingTransactions(this.props.currentUser.id.uuid, this.props.params.id);
      }
      this.setState({ transactionsFetched: true });
    }
  }

  async componentDidMount() {
    const { params } = this.props;
    const response = await getListingById({ idListing: params.id });
    const listingData = response;
    // logEvent('product details viewed', getParamsListing(listingData));
    const hideWhatsAppChat = localStorage.getItem('hideWhatsAppChat');
    if (!hideWhatsAppChat || hideWhatsAppChat === 'false') {
      this.setState({ showWhatsAppChat: true });
    } else {
      this.setState({ showWhatsAppChat: false });
    }
  }


  handleClose(){
    localStorage.setItem('hideWhatsAppChat', 'true');
    this.setState({showWhatsAppChat: false})
  };

  handleSubmit(values) {
    const {
      history,
      location,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;

    if (!currentUser) {
      const listingId = new UUID(params.id);
      const listing = getListing(listingId);

      const { bookingDates, bookingData, ...price } = values;

      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 360);
      const today = new Date();
      today.setDate(new Date().getDate() + 1);

      const initialValues = {
        listing,
        bookingData,
        bookingDates: {
          bookingStart: today,
          bookingEnd: tomorrow,
          customPrice: price && price.priceToSent ? price.priceToSent : 1,
          shippingPrice: 109,
        },
        confirmPaymentError: null,
      };
      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
      callSetInitialValues(setInitialValues, initialValues);

      // // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();
      this.setState({
        authContainerOpen: true,
        requiresRedirection: true,
        isOfferModalOpen: false,
      });
    } else {
      const fbq = window.fbq;
      const listingId = new UUID(params.id);
      const listing = getListing(listingId);

      const { bookingDates, bookingData, ...price } = values;

      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 360);
      const today = new Date();
      today.setDate(new Date().getDate() + 1);

      const initialValues = {
        listing,
        bookingData,
        bookingDates: {
          bookingStart: today,
          bookingEnd: tomorrow,
          customPrice: price && price.priceToSent ? price.priceToSent : 1,
          shippingPrice: 109,
        },
        confirmPaymentError: null,
      };
      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
      callSetInitialValues(setInitialValues, initialValues);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();

      // Register Product Checkout in FacebookPixel
      if (typeof fbq === 'function') {
        const trackingObject = {
          content_name: listing.attributes.title,
          content_category: listing.attributes.publicData.category.join(', '),
          content_ids: [listing.id.uuid],
          content_type: 'product',
          order_type: price && price.priceToSent && price.priceToSent !== 1 ? 'Oferta' : 'Compra',
          description: listing.attributes.description,
          offer: ((price?.priceToSent || 1) * listing.attributes.price.amount) / 100,
          value: listing.attributes.price.amount / 100,
          currency: listing.attributes.price.currency,
        };
        fbq('track', 'AddToCart', trackingObject);
      }

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
          {}
        )
      );
    }
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
      onMessageTransactionValidation,
      getListing,
    } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      // callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      this.setState({
        authContainerOpen: true,
        requiresRedirection: false,
        requiresExtraAction: true,
        neededAction: 'contactUser',
      });
      // //history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
    } else {
      const currentListing = ensureListing(getListing(listingId));
      const receiver = currentListing.author.id.uuid;
      const sender = currentUser.id.uuid;
      //send listing so that onEnquiry we can validate that there is a message with this listingId
      // let enquiryParamas = {listing}
      onMessageTransactionValidation(listingId.uuid, receiver, sender)
        .then(txId => {
          if (txId !== null) {
            history.push(
              createResourceLocatorString('MessageCustomerDetailsPage', routes, { id: txId.uuid }, {})
            );
          } else {
            this.setState({ enquiryModalOpen: true });
          }
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    }
  }

  triggerExtraAction(action) {
    const { currentUser, listingId } = this.props;

    switch (action) {
      case 'like':
        // this.props.onToggleLike(currentUser.id.uuid, listingId.uuid);
        break;
      case 'contactUser':
        this.setState({ enquiryModalOpen: true });
        break;
      default:
        break;
    }
  }

  handleLoginRedirecting() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
      onMessageTransactionValidation,
      getListing,
    } = this.props;

    const routes = routeConfiguration();

    const state = { from: `${location.pathname}${location.search}${location.hash}` };
    // history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, currentUser, getListing } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    const currentListing = getListing(listingId);

    const receiver = currentListing.author.id.uuid;
    const sender = currentUser.id.uuid;

    logEvent('send message', {
      ['user id']: currentUser.id.uuid,
      ['user name']: `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`,
      ['seller id']: currentListing.author.id.uuid,
      ['seller name']: `${currentListing.author.attributes.profile?.publicData?.userName ||
        ''} ${currentListing.author.attributes.profile.firstName || ''} ${currentListing.author
        .attributes.profile.lastName || ''}`,
      message,
    });

    onSendEnquiry(listingId.uuid, receiver, sender, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });
        const type = 'message';
        sendMessageWhatsapp({ sender, receiver, currentListing, type });
        // Redirect to MessageCustomerDetailsPage
        history.push(
          createResourceLocatorString('MessageCustomerDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  async onSubmitNewPrice(newPrice) {
    const { params, onLowerListingPrice } = this.props;
    const listingId = new UUID(params.id);
    const listing = await getListingById({ idListing: params.id });

    onLowerListingPrice(listingId, newPrice)
      .then(async txId => {
        const paramsListing = getParamsListing(listing);
        const priceReduce = paramsListing['product price'] - newPrice;
        const priceReducePercentage = (priceReduce * 100) / paramsListing['product price'];

        logEvent('Price Drop', {
          ...paramsListing,
          ['price drop amount']: priceReduce,
          ['price drop in percentage']: priceReducePercentage
        });
        window.location.reload(false);
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  handleChangePicture(image) {
    let state = this.state;
    state.currentImage = image;
    this.setState(state);
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      lowerListingPriceInProgress,
      lowerListingPriceInError,
      onToggleLike,
      onCommentPosted,
      likeInProgress,
      hasTransactions,
      providerHasAddress,
      isAvailable,
      loadingCurrentUser,
      commentUsers,
      reviewsAvg,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      price = null,
      title = '',
      publicData,
      metadata,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);
    const { formattedPrice: formattedOrginalPrice, priceTitle: originalPriceTitle } = priceData(
      new Money(publicData.original_price.amount, publicData.original_price.currency),
      intl
    );

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const handleLoginRedirect = () => {
      const { history, location } = this.props;
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      //history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
      this.setState({ authContainerOpen: !this.state.authContainerOpen });
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const handleToggleLike = e => {
      e.preventDefault();
      if (!!currentUser) {
        if(!likeInProgress){
          onToggleLike(currentUser.id.uuid, listingId.uuid);
        }
      } else {
        const { history, location } = this.props;
        const state = { from: `${location.pathname}${location.search}${location.hash}` };
        history.push(
          createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
          state
        );
      }
    };

    const handleShare = () => {
      if (window.navigator.share) {
        window.navigator
          .share({
            title: window.document.title,
            url: window.location.href,
          })
          .then(() => {})
          .catch();
      } else {
        this.setState({ isSharing: true });
      }
    };

    const focusCommentBox = () => {
      // window.scrollTo(0, this.ref.current.offsetTop);
      // window.scrollTo({ behavior: 'smooth', top: this.ref.current.offsetTop })
      this.ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSharing = option => {
      this.setState({ isSharing: option });
    };

    const isLikedByCurrentUser =
      !!currentUser &&
      !!metadata &&
      Array.isArray(metadata.likedBy) &&
      metadata.likedBy.includes(currentUser.id.uuid);

    const likedBy = !!metadata && Array.isArray(metadata.likedBy) ? metadata.likedBy : [];

    const comments = !!metadata && Array.isArray(metadata.comments) ? metadata.comments : [];

    const priceShipping = 'Envío: $109 (3-5 días desde que se envía)';

    const actionsSection = isAuthenticated ? (
      isAvailable && !publicData.sold && publicData.available !== 'No-Disponible' ? (
        <>
          <SectionActions
          className={css.bookingPanel}
          currentUser={currentUser}
          authorDisplayName={authorDisplayName}
          onManageDisableScrolling={onManageDisableScrolling}
          isAuthenticated={isAuthenticated}
          handleBuySubmit={handleBookingSubmit}
          listing={currentListing}
          isOwnListing={isOwnListing}
          unitType={unitType}
          onSubmit={handleBookingSubmit}
          onSubmitNewPrice={this.onSubmitNewPrice}
          inProgress={this.state.isSearching}
          providerHasAddress={providerHasAddress}
          isUserLoggedIn={true}
          onContactUser={this.onContactUser}
          isDiscountOfferModalOpen={this.state.isDiscountOfferModalOpen}
          onCloseDiscountOfferModal={() => this.setState({ isDiscountOfferModalOpen: false })}
          onOpenDiscountOfferModal={() => this.setState({ isDiscountOfferModalOpen: true })}
          isOfferModalOpen={this.state.isOfferModalOpen}
          onCloseOfferModal={() => this.setState({ isOfferModalOpen: false })}
          onOpenOfferModal={() => this.setState({ isOfferModalOpen: true })}
        />
        <div className={css.msiMessage}>¡Paga a plazos de hasta 9 meses!</div>
        </>
      ) : publicData.sold ? (
        <p className={css.sold}>Artículo vendido</p>
      ) : (
        <p className={css.sold}>Artículo no disponible</p>
      )
    ) : isAvailable && !publicData.sold && publicData.available !== 'No-Disponible' ? (
      <>
        <SectionActions
          className={css.bookingPanel}
          onManageDisableScrolling={onManageDisableScrolling}
          handleBuySubmit={handleBookingSubmit}
          listing={currentListing}
          unitType={unitType}
          onSubmit={handleBookingSubmit}
          onContactUser={this.onContactUser}
          inProgress={this.state.isSearching}
          providerHasAddress={providerHasAddress}
          isUserLoggedIn={false}
          isDiscountOfferModalOpen={this.state.isDiscountOfferModalOpen}
          onCloseDiscountOfferModal={() => this.setState({ isDiscountOfferModalOpen: false })}
          onOpenDiscountOfferModal={() => this.setState({ isDiscountOfferModalOpen: true })}
          isOfferModalOpen={this.state.isOfferModalOpen}
          onCloseOfferModal={() => this.setState({ isOfferModalOpen: false })}
          onOpenOfferModal={() => this.setState({ isOfferModalOpen: true })}
        />
        <div className={css.msiMessage}>¡Paga a plazos de hasta 9 meses!</div>
      </>
    ) : publicData.sold ? (
      <p className={css.sold}>Artículo vendido</p>
    ) : (
      <p className={css.sold}>Artículo no disponible</p>
    );

    const sectionPriceDiscountMaybe =
      isAuthenticated &&
      isAvailable &&
      !publicData.sold &&
      publicData.available !== 'No-Disponible' ? (
        <SectionPriceDiscountMaybe
          intl={intl}
          className={css.bookingPanel}
          currentUser={currentUser}
          authorDisplayName={authorDisplayName}
          onManageDisableScrolling={onManageDisableScrolling}
          isAuthenticated={isAuthenticated}
          handleBuySubmit={handleBookingSubmit}
          listing={currentListing}
          isOwnListing={isOwnListing}
          unitType={unitType}
          onSubmit={handleBookingSubmit}
          onSubmitNewPrice={this.onSubmitNewPrice}
          inProgress={this.state.isSearching}
          providerHasAddress={providerHasAddress}
          isUserLoggedIn={true}
          isDiscountOfferModalOpen={this.state.isDiscountOfferModalOpen}
          onCloseDiscountOfferModal={() => this.setState({ isDiscountOfferModalOpen: false })}
          onOpenDiscountOfferModal={() => {
            this.setState({ isDiscountOfferModalOpen: true });
          }}
          lowerListingPriceInProgress={lowerListingPriceInProgress}
          lowerListingPriceInError={lowerListingPriceInError}
          initialValues={{
            offer: new Money(
              (currentListing.attributes.price.amount * 0.75).toFixed(2),
              config.currency
            ),
          }}
        />
      ) : null;

    const phoneAlertSection = this.state.hasPhoneAlert ? (
      <Modal
        id="phoneAlertSection"
        isOpen={true}
        onClose={() => {
          this.setState({ hasPhoneAlert: false });
        }}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <p>
          Para continuar con la compra, debes ingresar y confirmar tu número de teléfono en
          Configuración de Perfil
        </p>
        <PrimaryButton
          type="button"
          onClick={() => {
            window.location.href = '/account/profile-settings';
          }}
        >
          Configuración del perfil
        </PrimaryButton>
      </Modal>
    ) : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <ActionBarMaybe
                isAvailable={isAvailable}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  edit: listingTab,
                }}
              />
              <div className={css.contentContainer}>
                <div className={classNames(css.left, css.desktopOnly)}>
                  {
                    <AuthenticationPageModalWrapper
                      authContainerOpen={this.state.authContainerOpen}
                      closeAuthModal={() => this.setState({ authContainerOpen: false })}
                      onLoginCompleted={() => {
                        this.handleLoginRedirecting();
                      }}
                      requiresRedirection={this.state.requiresRedirection}
                      extraAction={() => this.triggerExtraAction(this.state.neededAction)}
                      requiresExtraAction={this.state.requiresExtraAction}
                    />
                  }

                  <ReloveSectionImages title={title} listing={currentListing} isMobile={false} />
                  <div className={css.desktopLeftPadding}>
                    {/*<SocialButtons
                      isLiked={isLikedByCurrentUser}
                      likeInProgress={!!likeInProgress}
                      onToggleLike={handleToggleLike}
                      onShare={handleShare}
                      currentUser={currentUser}
                      isSharing={this.state.isSharing}
                      onSharing={handleSharing}
              />*/}
                    <LikesSection likedBy={likedBy} />
                    <SectionSocialButtonActions
                      isLikedByCurrentUser={isLikedByCurrentUser}
                      onClickLike={() => {
                        if (currentUser) {
                          onToggleLike(currentUser.id.uuid, listingId.uuid);
                        } else {
                          const { history, location } = this.props;
                          const state = {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          };
                          //history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
                          this.setState({
                            authContainerOpen: true,
                            requiresRedirection: false,
                            requiresExtraAction: false,
                            neededAction: 'like',
                          });
                        }
                      }}
                      onShare={handleShare}
                      isSharing={this.state.isSharing}
                      onCloseShare={() => this.setState({ isSharing: false })}
                    />
                    <CommentsSection
                      listingId={listingId.uuid}
                      currentUser={currentUser}
                      comments={comments}
                      commentUsers={commentUsers}
                      onCommentPosted={onCommentPosted}
                      loadingCurrentUser={loadingCurrentUser}
                      history={this.props.history}
                      location={this.props.location}
                      setAuthContainerOpen={() =>
                        this.setState({
                          authContainerOpen: true,
                          requiresRedirection: false,
                          requiresExtraAction: false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={css.right}>
                  <div className={css.AvatarInbox}>
                    <SectionAvatar
                      user={currentAuthor}
                      isLikedByCurrentUser={isLikedByCurrentUser}
                      listing={currentListing}
                      params={params}
                      onClickLike={() => {
                        if (currentUser) {
                          onToggleLike(currentUser.id.uuid, listingId.uuid);
                        } else {
                          const { history, location } = this.props;
                          const state = {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          };
                          //history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
                          this.setState({
                            authContainerOpen: true,
                            requiresRedirection: false,
                            requiresExtraAction: false,
                            neededAction: 'like',
                          });
                        }
                      }}
                      likedBy={likedBy}
                    />

                    <SectionInbox
                      listing={currentListing}
                      onContactUser={this.onContactUser}
                      isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                      onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                      sendEnquiryError={sendEnquiryError}
                      sendEnquiryInProgress={sendEnquiryInProgress}
                      onSubmitEnquiry={this.onSubmitEnquiry}
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                      authorDisplayName={authorDisplayName}
                      title={title}
                      isOwnListing={isOwnListing}
                      isLiked={isLikedByCurrentUser}
                      likeInProgress={!!likeInProgress}
                      onToggleLike={handleToggleLike}
                    />
                  </div>

                  <div
                    className={classNames(css.sectionHeading, css.desktopOnly)}
                    style={{ marginBottom: '9.5px' }}
                  >
                    <div className={css.containerTitleHeading}>
                      <h1 className={css.title} style={{ marginBottom: '0px' }}>
                        {richTitle}
                      </h1>
                    </div>
                    <div className={css.prices}>
                      <div className={css.productPrice} title={priceTitle}>
                        {formattedPrice}
                      </div>
                      <div className={css.originalPrice} title={originalPriceTitle}>
                        {formattedOrginalPrice}
                      </div>
                    </div>
                  </div>
                  <div className={css.mobileOnly}>
                    <ReloveSectionImages title={title} listing={currentListing} isMobile={true} />
                    <SectionHeading
                      likedBy={likedBy}
                      priceTitle={priceTitle}
                      formattedPrice={formattedPrice}
                      originalPriceTitle={originalPriceTitle}
                      formattedOriginalPrice={formattedOrginalPrice}
                      richTitle={richTitle}
                      listing={currentListing}
                      onContactUser={this.onContactUser}
                      isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                      onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                      sendEnquiryError={sendEnquiryError}
                      sendEnquiryInProgress={sendEnquiryInProgress}
                      onSubmitEnquiry={this.onSubmitEnquiry}
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                      authorDisplayName={authorDisplayName}
                      title={title}
                      isOwnListing={isOwnListing}
                      priceShipping={priceShipping}
                      isLikedByCurrentUser={isLikedByCurrentUser}
                      onClickLike={() => {
                        if (currentUser) {
                          onToggleLike(currentUser.id.uuid, listingId.uuid);
                        } else {
                          const { history, location } = this.props;
                          const state = {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          };
                          //history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
                          this.setState({
                            authContainerOpen: true,
                            requiresRedirection: false,
                            requiresExtraAction: false,
                            neededAction: 'like',
                          });
                        }
                      }}
                    />

                    {/*  <SocialButtons
                      isLiked={isLikedByCurrentUser}
                      likeInProgress={!!likeInProgress}
                      onToggleLike={handleToggleLike}
                      currentUser={currentUser}
                      onShare={handleShare}
                      isSharing={this.state.isSharing}
                      onSharing={handleSharing}
                      isMobile={true}
                      focusCommentBox={focusCommentBox}
              />
                    <LikesSection likedBy={likedBy} />
                */}
                  </div>
                  <SectionFeaturesMaybe publicData={publicData} description={description} />
                  {sectionPriceDiscountMaybe}

                  {actionsSection}
                  <div className={css.desktopOnly}>
                    <SectionProtection />
                    <SectionDetailUser user={currentAuthor} reviewsAvg={reviewsAvg} />
                  </div>
                  {!hasTransactions && isAvailable && false ? (
                    <div className={css.paymentImages}>
                      <img
                        src={VisaImg}
                        className={css.paymentImagesItem}
                        alt="Paga con Visa y Mastercard"
                      />
                    </div>
                  ) : null}
                  <div className={css.mobileOnly} ref={this.ref}>
                    <SectionProtection />
                    <SectionDetailUser user={currentAuthor} />
                    <SectionSocialButtonActions
                      isLikedByCurrentUser={isLikedByCurrentUser}
                      onClickLike={() => {
                        if (currentUser) {
                          onToggleLike(currentUser.id.uuid, listingId.uuid);
                        } else {
                          const { history, location } = this.props;
                          const state = {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          };
                          //history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
                          this.setState({
                            authContainerOpen: true,
                            requiresRedirection: false,
                            requiresExtraAction: false,
                            neededAction: 'like',
                          });
                        }
                      }}
                      onShare={handleShare}
                    />
                    <CommentsSection
                      listingId={listingId.uuid}
                      currentUser={currentUser}
                      comments={comments}
                      commentUsers={commentUsers}
                      onCommentPosted={onCommentPosted}
                      loadingCurrentUser={loadingCurrentUser}
                      history={this.props.history}
                      location={this.props.location}
                      setAuthContainerOpen={() =>
                        this.setState({
                          authContainerOpen: true,
                          requiresRedirection: false,
                          requiresExtraAction: false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  lowerListingPriceInError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  hasTransactions: false,
  providerHasAddress: false,
  isAvailable: false,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  lowerListingPriceInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  lowerListingPriceInError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onLowerListingPrice: func.isRequired,
  onMessageTransactionValidation: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  hasTransactions: bool,
  providerHasAddress: bool,
  isAvailable: bool,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    queryReviewsError,
    reviewsAvg,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lowerListingPriceInProgress,
    lowerListingPriceInError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    likeInProgress,
    hasTransactions,
    providerHasAddress,
    isAvailable,
    commentUsersIds,
    onSetAsSold,
  } = state.ListingPage;
  const { currentUser, loadingCurrentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const usersIds = commentUsersIds.map(i => ({ id: i, type: 'user' }));
  const commentUsers = getMarketplaceEntities(state, usersIds) || [];

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    queryReviewsError,
    reviewsAvg,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    lowerListingPriceInProgress,
    sendEnquiryError,
    lowerListingPriceInError,
    likeInProgress,
    hasTransactions,
    providerHasAddress,
    isAvailable,
    commentUsers,
    loadingCurrentUser,
    onSetAsSold,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, providerId, customerId, message) =>
    dispatch(sendEnquiry(listingId, providerId, customerId, message)),
  onLowerListingPrice: (listingId, newPrice) => dispatch(lowerListingPrice(listingId, newPrice)),
  onMessageTransactionValidation: (listingId, providerId, customerId) =>
    dispatch(messageTransactionValidation(listingId, providerId, customerId)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onToggleLike: (user, listingId) => dispatch(toggleLike(user, listingId)),
  onCommentPosted: comment => dispatch(addMarketplaceEntities(comment)),
  onGetListingTransactions: (userId, listingId) =>
    dispatch(getListingTransactions(userId, listingId)),
  onSetAsSold: listingId => dispatch(setAsSold(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
