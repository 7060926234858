import classNames from 'classnames';
import createDecorator from 'final-form-calculate';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import React, { Component, useEffect } from 'react';
import { compose } from 'redux';
import { Modal, StripeConnectAccountStatusBox } from '../../../components';
import config from '../../../config';
import { ReloveEditListingForm, ProfileAddressForm, StripeConnectAccountForm } from '../../../forms';
import routeConfiguration from '../../../routeConfiguration';
import { withViewport } from '../../../util/contextHelpers';
import { ensureCurrentUser, ensureListing } from '../../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { propTypes } from '../../../util/types';
import {
  LISTING_PAGE_PARAM_TYPES, LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW
} from '../../../util/urlHelpers';

import moment from 'moment';

import { getIsZipCodeAvailable } from '../../../ducks/Address.duck'
import css from './ReloveEditListingWizard.css';

const { Money } = sdkTypes;

// const RELOVE_COMMISSION_PERCENTAGE = config.marketplaceCommission;
// const RELOVE_BASE_COMMISSION = config.marketplaceBaseCommission;

let RELOVE_COMMISSION_PERCENTAGE;
let RELOVE_BASE_COMMISSION;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

/** FUNCTIONS FOR STRIPE HANDLING **/
// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditListingStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

const RedirectToStripe = ({ redirectFn }) => {
  useEffect(redirectFn('custom_account_verification'), []);
  return <FormattedMessage id="EditListingWizard.redirectingToStripe" />;
};

const calculateProfit = createDecorator(
  {
    field: 'price',
    updates: {
      profit: (priceValue) => {
        if(priceValue){
          if(priceValue.amount < 32500){
            RELOVE_COMMISSION_PERCENTAGE = 0;
            RELOVE_BASE_COMMISSION = 75;
          }
          else if(priceValue.amount >= 32500 && priceValue.amount < 297500){
            RELOVE_COMMISSION_PERCENTAGE = 0.185;
            RELOVE_BASE_COMMISSION = 15;
          }
          else if(priceValue.amount >= 297500 && priceValue.amount < 401500){
            RELOVE_COMMISSION_PERCENTAGE = 0;
            RELOVE_BASE_COMMISSION = 565;
          }
          else {
            RELOVE_COMMISSION_PERCENTAGE = 0.14;
            RELOVE_BASE_COMMISSION = 0;
          }
        }
        return priceValue ? ('$' + Number((priceValue.amount * (1 - RELOVE_COMMISSION_PERCENTAGE) / 100) - RELOVE_BASE_COMMISSION).toFixed(2)) : null;
      }
    }
  }
);

// Create a new or edit listing through EditListingWizard
class ReloveEditListingWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      draftId: null,
      showPayoutDetails: false, // modal is open,
      showAddressModal: false,
      zipIsUnavailable: false
    };
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
    this.handleAddressModalClose = this.handleAddressModalClose.bind(this);
  }

  handlePublishListing(id) {
    const { onPublishListingDraft, currentUser, stripeAccount } = this.props;

    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

        // 
    const addressNotSet = !currentUser.attributes.profile.privateData || !currentUser.attributes.profile.privateData.address;

    if (addressNotSet) {
      this.setState({
        draftId: id,
        showAddressModal: true,
      });
    } else if (!stripeConnected || requirementsMissing) {
      this.setState({
        draftId: id,
        showPayoutDetails: true,
      });
    } else {
      onPublishListingDraft(id);
    }
  }

  /* Opens payoutDetails Modal if returnURL is set */
  componentDidMount() {
    const { stripeOnboardingReturnURL } = this.props;

    if (stripeOnboardingReturnURL === STRIPE_ONBOARDING_RETURN_URL_FAILURE && !this.showPayoutDetails) {
      this.setState({ showPayoutDetails: true });
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  handlePayoutSubmit(values) {
    this.props
      .onPayoutDetailsSubmit(values)
      .then(response => {
        this.props.onManageDisableScrolling('EditListingWizard.payoutModal', false);
      })
      .catch(() => {
        // do nothing
      });
  }

  handleAddressModalClose() {
    this.setState({ showAddressModal: false });
  }

  render() {
    const {
      id, // just a plain id
      className,
      rootClassName,
      params, // From router, page params
      listing, // From parent the listing, has data of current listing if already uploaded
      viewport, // From withViewport, idk
      intl, // From injectIntl, for translations
      images, // Loaded images
      errors, // object with all errors
      fetchInProgress, // From state.stripeConnectAccount, is createStripeAccountInProgress
      payoutDetailsSaveInProgress, // From page state, updateing or creating stripe account
      payoutDetailsSaved, // From page state
      onManageDisableScrolling, // From UI duck, idk
      onPayoutDetailsFormChange, // Clears Stripe error when modifying payout details form
      onGetStripeConnectAccountLink, // From stripeConnectAccount dispatch (getStripeConnectAccountLink(params)), creates the link for stripe create account with it's redirects
      onSaveClientClabeNumber,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      stripeAccountError,
      stripeAccountLinkError,
      currentUser,
      onCreateListingDraft,
      onUpdateListing,
      onChange,
      onPublishListingDraft,
      newListingPublished,
      onImageUpload,
      onRemoveImage,
      updateInProgress,
      onUpdateImageOrder,
      imageOrder,
      onUpdateProfile,
      updateProfileInProgress,
      updateProfileError,
      hasListingPriceBeingDiscounted,
      ...rest
    } = this.props;

    const isNewURI = params.type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isDraftURI = params.type === LISTING_PAGE_PARAM_TYPE_DRAFT;
    const isNewListingFlow = isNewURI || isDraftURI;
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);

    const formDisabled = getAccountLinkInProgress;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const { returnURLType, ...pathParams } = params;
    const pathParamsUpdated = !!this.state.draftId && pathParams.slug === 'draft' ?
      {
        slug: 'draft',
        id: this.state.draftId.uuid,
        type: 'draft',
        edit: 'edit'
      } : pathParams;

    const successURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
      rootURL,
      routes,
      pathParamsUpdated
    );
    const failureURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_FAILURE,
      rootURL,
      routes,
      pathParams
    );

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    // const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;

    const { description, title, price, publicData } = currentListing.attributes;

    const imageIds = images => {
      return images ? images.map(img => img.imageId || img.id) : null;
    };

    const handleAddressSubmit = values => {
      const {
        address,
        addressExtNumber,
        addressIntNumber,
        addressNeighborhood,
        addressZip,
        addressState,
        addressCity,
      } = values;

      const profile = {
        privateData: {
          address: {
            address_line: address,
            ext_number: addressExtNumber,
            int_number: addressIntNumber,
            neighborhood: addressNeighborhood,
            zip: addressZip,
            state: addressState,
            city: addressCity
          }
        }
      };

      this.setState({ zipIsUnavailable: false });

      const self = this;
      getIsZipCodeAvailable(addressZip).then((response) => {
        if (response.available) {
          onUpdateProfile(profile)
            .then(() => {
              self.setState({ showAddressModal: false })
              self.handlePublishListing(self.state.draftId);
            })
            .catch(() => {
              // do nothing
            });
        } else {
          this.setState({ zipIsUnavailable: true });
        }
      }).catch((e) => {
        this.setState({ zipIsUnavailable: true });
      })
    };

    const onCompleteEditListingWizard = (updateValues) => {
      // Normalize images for API call
      const { images: updatedImages, ...otherValues } = updateValues;

      const imageProperty =
        typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
      const updateValuesWithImages = { ...otherValues, ...imageProperty };
      const self = this;
      if (isNewListingFlow) {
        const onUpsertListingDraft = isNewURI
          ? (updateValues) => onCreateListingDraft(updateValues)
          : onUpdateListing;
        const upsertValues = isNewURI
          ? updateValuesWithImages
          : { ...updateValuesWithImages, id: currentListing.id };

        onUpsertListingDraft(upsertValues)
          .then(r => {
            self.handlePublishListing(r.data.data.id);
          })
          .catch(e => {
            // No need for extra actions
          });
      } else {
        updateValuesWithImages.publicData['hasBeenUpdated'] = true;
        updateValuesWithImages.publicData['updateTime'] = moment().format('x');
        const upsertValues = isNewURI
          ? updateValuesWithImages
          : { ...updateValuesWithImages, id: currentListing.id };
        onUpdateListing(upsertValues);
      }
    };

    const {original_price, category, sold, /*available,*/ ...restPublicData } = publicData;

    const formatted_category = category && typeof category[0] != 'undefined' ? category[0] : null ;
    const formatted_subcategory = category && typeof category[1] != 'undefined' ? category[1] : null ;

    const price_formatted = original_price ?
    new Money(original_price.amount, original_price.currency)
    : null;

    const profit = price ? (Number((price.amount * (1 - RELOVE_COMMISSION_PERCENTAGE) / 100) - RELOVE_BASE_COMMISSION).toFixed(2) + ' MXN') : null;

    const formattedPublicData = {
      original_price: price_formatted,
      category: formatted_category,
      subcategory: formatted_subcategory,
      ...restPublicData
    };

    const { privateData } = ensuredCurrentUser.attributes.profile;

    const {
      address_line: address,
      city: addressCity,
      ext_number: addressExtNumber,
      int_number: addressIntNumber,
      neighborhood: addressNeighborhood,
      state: addressState,
      zip: addressZip,
    } = privateData ? privateData.address || {} : {};
    
    let soldValue = sold !== undefined ? sold : false;

    const maxPrice = hasListingPriceBeingDiscounted ? listing.attributes.price : false;

    return (
      <div className={classes}>
        <div className={css.sectionContainer}>
          <ReloveEditListingForm
            className={css.form}
            initialValues={{ 
              title, 
              description, 
              ...formattedPublicData, 
              price, 
              profit, 
              available: formattedPublicData.available ? formattedPublicData.available : 'Disponible'
            }}
            decorators={[calculateProfit]}
            saveActionMsg={isNewListingFlow ? 'Publicar' : 'Actualizar'}
            onSubmit={values => {
              const {
                title,
                description,
                price,
                original_price,
                size,
                category,
                subcategory,
                style,
                color,
                brand,
                use,
                available
              } = values;
              const categoryList = [category, subcategory];
              const sizeList = Array.isArray(size) ? size.map(item => typeof item === 'string'  ? item : item.key ) : [];
              const colorList = Array.isArray(color) ? color.map(item => typeof item === 'string'  ? item : item.key) : [];
              let availableValue = available !== undefined ? available : "Disponible";    
              const updateValues = {
                title: title.trim(),
                description,
                images,
                price,
                publicData: {
                  original_price: { amount: original_price.amount, currency: original_price.currency },
                  category: categoryList.filter(Boolean),
                  size: sizeList,
                  color: colorList,
                  style,
                  brand,
                  use,
                  shippingFee: { amount: 10000, currency: 'MXN' },
                  sold: soldValue,
                  available: availableValue,
                },
              };

              onCompleteEditListingWizard(updateValues);
            }}
            onChange={onChange}
            ready={newListingPublished}
            updated={false}
            disabled={false}
            keepDirtyOnReinitialize
            onImageUpload={onImageUpload}
            onRemoveImage={onRemoveImage}
            updateInProgress={updateInProgress}
            onUpdateImageOrder={onUpdateImageOrder}
            imageOrder={imageOrder}
            images={images}
            fetchErrors={errors}
            hasListingPriceBeingDiscounted={hasListingPriceBeingDiscounted}
            maxPrice={maxPrice}
          />
        </div>
        <Modal
          id="EditListingWizard.addressModal"
          isOpen={this.state.showAddressModal}
          onClose={this.handleAddressModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              Agrega tus datos
            </h1>
            {currentUserLoaded ? (
              <>
                <p className={css.modalMessage}>
                  Para poder calcular el costo de envío necesitamos saber tu dirección y tu número de telefono
                  para mejor comunicación.
                  Si necesitas cambiar los datos más adelante, lo puedes hacer desde tu Configuración de Perfil,
                  en la pestaña de Editar Dirección
                </p>
                <ProfileAddressForm
                  className={css.addressForm}
                  initialValues={{
                    address,
                    addressExtNumber,
                    addressIntNumber,
                    addressNeighborhood,
                    addressZip,
                    addressState,
                    addressCity,
                  }}
                  updateInProgress={updateProfileInProgress}
                  updateProfileError={updateProfileError}
                  zipIsUnavailable={this.state.zipIsUnavailable}
                  onManageDisableScrolling={this.props.onManageDisableScrolling}
                  onSubmit={handleAddressSubmit}
                />
              </>
            ) : null}
          </div>
        </Modal>
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalBankDataInformation}

          usePortal
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
            </h1>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : returnedAbnormallyFromStripe && !stripeAccountLinkError ? (
              <p className={css.modalMessage}>
                <RedirectToStripe redirectFn={handleGetStripeConnectAccountLink} />
              </p>
            ) : (
              <>
                <p className={css.modalMessage}>
                  <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                </p>
                <StripeConnectAccountForm
                  disabled={formDisabled}
                  inProgress={payoutDetailsSaveInProgress}
                  ready={payoutDetailsSaved}
                  currentUser={ensuredCurrentUser}
                  stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                  savedCountry={savedCountry}
                  submitButtonText={intl.formatMessage({
                    id: 'StripePayoutPage.submitButtonText',
                  })}
                  stripeAccountError={stripeAccountError}
                  stripeAccountFetched={stripeAccountFetched}
                  stripeAccountLinkError={stripeAccountLinkError}
                  onChange={onPayoutDetailsFormChange}
                  onSubmit={rest.onPayoutDetailsSubmit}
                  onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                  stripeConnected={stripeConnected}
                  onSaveClientClabeNumber={onSaveClientClabeNumber}
                >
                  {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                    <StripeConnectAccountStatusBox
                      type="verificationNeeded"
                      inProgress={getAccountLinkInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_verification'
                      )}
                    />
                  ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                    <StripeConnectAccountStatusBox
                      type="verificationSuccess"
                      inProgress={getAccountLinkInProgress}
                      disabled={payoutDetailsSaveInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_update'
                      )}
                    />
                  ) : null}
                </StripeConnectAccountForm>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

ReloveEditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  stripeAccount: null,
  stripeAccountFetched: null,
  updateInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountError: null,
  stripeAccountLinkError: null,
};

ReloveEditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    edit: string,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withViewport,
  injectIntl
)(ReloveEditListingWizard);
