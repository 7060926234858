import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../../components'; 
import css from './ReloveEditListingForm.css';

const ReloveDropdownFieldComponent = props => {
  const {
    className,
    id,
    label,
    options,
    input,
    meta,
    onInputChange,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState(input.value || ''); // State to manage the input value

  const hasError = touched && invalid && error;

  const selectClasses = classNames(css.dropdown, {
    [css.dropdownSuccess]: valid,
    [css.dropdownError]: hasError,
  });

  // Update filteredOptions whenever the options prop changes
  useEffect(() => {
    if (options.length === 0 && inputValue !== '') {
      setFilteredOptions([{ key: 'OTRO', label: 'OTRO' }]); // Show 'Otro' if no options are available
    } else {
      setFilteredOptions(options);
    }
  }, [options]);

  const handleInputChange = e => {
    const inputValue = e.target.value;
    setInputValue(inputValue); // Update input value state

    // Call onInputChange prop to fetch data from MeiliSearch
    if (onInputChange) {
      onInputChange(inputValue);
    }

    if (inputValue) {
      const filtered = options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredOptions(filtered.length === 0 ? [{ key: 'OTRO', label: 'OTRO' }] : filtered);
    } else {
      setFilteredOptions(options);
    }

    setShowDropdown(true); // Show dropdown when typing
  };

  const handleOptionClick = option => {
    input.onChange(option.label); // Set the selected option in form state
    setInputValue(option.label); // Update input field with the selected standardized label
    setShowDropdown(false);
  };

  const handleFocus = () => {
    setShowDropdown(true); // Show dropdown on focus
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (showDropdown && filteredOptions.length > 0) {
        // Automatically select the top option if the dropdown is visible and input loses focus
        handleOptionClick(filteredOptions[0]);
      } else {
        input.onBlur(); // Manually trigger the blur event to set `touched`
      }
      setShowDropdown(false);
    }, 200);
  };

  return (
    <section className={className}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className={css.dropdownContainer}>
        <input
          id={id}
          className={selectClasses}
          {...rest}
          onChange={handleInputChange}
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={inputValue}  // Display the typed or selected value
        />
        <ul className={`${css.dropdownList} ${showDropdown ? css.showDropdown : ''}`}>
          {filteredOptions.map(option => (
            <li
              key={option.key}
              className={css.dropdownItem}
              onMouseDown={() => handleOptionClick(option)} // Handle selection
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
      <ValidationError fieldMeta={meta} isAlternativeField={false} />
    </section>
  );
};

const ReloveDropdownField = props => {
  return <Field component={ReloveDropdownFieldComponent} {...props} />;
};

export default ReloveDropdownField;
