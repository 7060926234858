import classNames from 'classnames';
import _ from 'lodash';
import { array, arrayOf, func, node, object, string } from 'prop-types';
import React, { Component } from 'react';
import { FieldCheckbox, ReloveFilterMobile, ReloveFilterPlain } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseSelectFilterOptions } from '../../../util/search';
import css from './ReloveSizeFilter.css';


// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  const groupedOptions = _.groupBy(options, (item) => item.group);

  const renderedOptions = [];
  let [groupName, group] = [null, null]; // This is neccesary because of a bug in ESLint
  for ([groupName, group] of Object.entries(groupedOptions)) {
    const item = (
      <div className={css.sizeGroup} key={groupName}>
        {groupName !== 'undefined' ? <span className={css.groupLabel} >{groupName}</span> : ''}
        <ul className={css.list}>
          {group.map((option, index) => {
            const fieldId = `${id}.${option.key}`;
            return (
              <li key={fieldId} className={css.item}>
                <FieldCheckbox id={fieldId} name={name} label={option.label} value={option.key} textClassName={css.categoryItem} />
              </li>
            );
          })}
        </ul>
      </div>
    );
    renderedOptions.push(item);
  }

  return (
    <fieldset className={className}>
      {renderedOptions}
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class ReloveSizeFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      onSubmit,
      queryParamNames,
      searchMode,
      intl,
      showAsPopup,
      hasControls,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName, searchMode));
    };

    // Remove duplicates from options array using id
    const filterOptions = options.reduce((acc, item) => {
      if (!acc.find(i => i.key === item.key)) {
        acc.push(item);
      }
      return acc;
    }, []);

    return hasControls ? (
      <ReloveFilterPlain
        className={classes}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        twoColumns
        hasControls={hasControls}
        topControls={true}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          name={name}
          id={`${id}-checkbox-group`}
          options={filterOptions}
        />
      </ReloveFilterPlain>
    ) : (
      <ReloveFilterMobile
        className={classes}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        twoColumns
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          name={name}
          id={`${id}-checkbox-group`}
          options={filterOptions}
        />
      </ReloveFilterMobile>
    );
  }
}

ReloveSizeFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

ReloveSizeFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ReloveSizeFilter);
