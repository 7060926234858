import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button, PrimaryButton, ReloveIconComment } from '../../components';

import css from './ListingPage.css';
import modalCss from './../../components/BookingPanel/BookingPanel.css';
import { injectIntl } from '../../util/reactIntl';
import { FormattedMessage } from 'react-intl';
import FieldTextInput from '../../components/FieldTextInput/FieldTextInput';
import cssField from '../../components/FieldTextInput/FieldTextInput.css';
import cssForm from '../../forms/BookingDatesForm/BookingDatesForm.css';
import { Form as FinalForm } from 'react-final-form';
import config from '../../config.js';
import classNames from 'classnames';
import { getParamsListing, logEvent } from '../../util/logsEvent';

const SectionActions = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [minAmountError, setMinAmountError] = useState(false);
  const [maxAmountError, setMaxAmountError] = useState(false);

  const {
    listing,
    onSubmit,
    onManageDisableScrolling,
    isOwnListing,
    submitButtonWrapperClassName,
    inProgress,
    providerHasAddress,
    isUserLoggedIn,
    onOpenDiscountOfferModal,
    onContactUser,

    isOfferModalOpen,
    onCloseOfferModal,
    onOpenOfferModal
  } = props;

  const handleContactUser = () => {
    let user = listing.author
    onContactUser(user);
  }


  const price = listing.attributes.price.amount / 100;

  let customPrice = '';
  let max = parseInt(price);
  let min = config.minimumOffer;

  const inputProp = {
    onChange: e => {
      e.persist();
      customPrice = e.target.value;
    },
    onKeyPress: e => {
      e.persist();
      if (e.which === 13) {
        handleSubmit(e);
        return false;
      }
    },
    onClose: e => {
      console.log('close');
    },
    placeholder: isOwnListing ? 'Ingresa tu nuevo precio' : 'Ingresa una cantidad a ofertar',
    type: 'number',
    name: 'offerQty',
    min: min,
    max: max,
    step: 1,
  };

  const handleSubmit = e => {
    e.persist();
    setMinAmountError(false);
    setMaxAmountError(false);
    let priceToSent = customPrice !== '' ? customPrice : price;
    priceToSent = priceToSent / price;
    if (customPrice < min) {
      setMinAmountError(true);
    } else if (customPrice >= price) {
      setMaxAmountError(true);
    } else {
      logEvent('offer submitted',
      {...getParamsListing(listing), ['offer in percentage']: parseInt((1 - priceToSent)*100) ,['offer amount']: parseInt(customPrice) })
      onSubmit({ priceToSent });
    }
  };

  const submitButtonClasses = classNames(
    submitButtonWrapperClassName || cssForm.submitButtonWrapper
  );

  const handleOption = option => {
    switch (option) {
      case 'buy':
        logEvent('purchase submitted', getParamsListing(listing))
        onSubmit({});
        break;
      case 'offer':
        onOpenOfferModal()
        break;
      default:
        onOpenDiscountOfferModal();
        break;
    }
  };

  const buyBtn = providerHasAddress ? (
    <Button className={css.buyButton} onClick={() => handleOption('buy')} inProgress={inProgress}>
      Comprar
    </Button>
  ) : null;

  return isOwnListing ? null : (
      <div className={css.sectionActions}>
        <Modal
          id="UniqueIdForThisModal"
          isOpen={isOfferModalOpen}
          onClose={() => {
            document.getElementById('offer').value = '';
            onCloseOfferModal()
          }}
          usePortal
          contentClassName={css.modalContent}
          onManageDisableScrolling={onManageDisableScrolling}

          containerClassName={css.container}
          className={css.isOpen}
          scrollLayerClassName={css.scrollLayer}
          isClosedClassName={css.isClosed}
  
          closeButtonClassName={css.close}
        >
          <div>
            <h2 className={modalCss.title}>Haz una oferta</h2>
            <div>
              <FinalForm
                onSubmit={handleSubmit}
                action=""
                method="GET"
                render={() => {
                  return (
                    <div>
                      <FieldTextInput
                        className={cssField.input}
                        input={inputProp}
                        customErrorText={
                          minAmountError
                            ? `El mínimo precio que se puede ofertar es de $${Number(min).toFixed(
                              2
                            )} MXN`
                            : maxAmountError
                              ? `Estas tratando de ofertar un precio mayor al listado`
                              : null
                        }
                        id="offer"
                        name="offer"
                      />
                      <br />
                      <div className={submitButtonClasses}>
                        <PrimaryButton type="button" onClick={handleSubmit}>
                          <FormattedMessage id="BookingDatesForm.requestToBookOffer" />
                        </PrimaryButton>
                      </div>
                    </div>
                  );
                }}
              ></FinalForm>
              <div className={css.requestBookOfferMessage}>
                <FormattedMessage id="BookingDatesForm.requestToBookOfferMessage" />
              </div>
            </div>
          </div>
        </Modal>
        
          {buyBtn}
          <Button
            className={css.bargainButton}
            onClick={() => handleOption('offer')}
            inProgress={inProgress}
          >
            Ofertar
          </Button>
          <Button className={css.chatButton} onClick={handleContactUser} inProgress={inProgress}>
            <ReloveIconComment className={css.chatButtonIcon} />
            <span className={css.textButtonChat} >Chat</span>
          </Button>
      </div>
  )

};


// export default SectionActions;
export default compose(withRouter, injectIntl)(SectionActions);
