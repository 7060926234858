import React, { useEffect } from 'react';
// import { Button } from '@material-ui/core';
import {ModalInMobile} from '..';
import { SuscriptionForm } from '../../forms';
 
import css from './SuscriptionSection.css';
import { logEvent } from '../../util/logsEvent';

const SuscriptionSection = props => {
  const {
    sendEnquiryInProgress,
    onSubmitEnquiry,
    onManageDisableScrolling,
    onCloseEnquiry,
  } = props;

  useEffect(() => {
    logEvent('email promo displayed');
  }, [])
  

  return (
    <div>
      <ModalInMobile
        id="UniqueIdForThisAddressAlert"
        isOpen={true}
        onClose={onCloseEnquiry}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
        >
        <SuscriptionForm
        className={css.enquiryForm}
        submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
        onSubmit={onSubmitEnquiry}
        inProgress={sendEnquiryInProgress}
        onOpenTermsOfService={() => {
          return
        }}
        onOpenPrivacyPolicy={() => {
          return
        }}
        />
      </ModalInMobile>
    </div>
  )
}
export default SuscriptionSection;