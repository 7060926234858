import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmailSuccess.css';

const IconEmailSuccess = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="110" height="110" fill="none" viewBox="0 0 110 110">
      <path fill="url(#paint0_linear)" d="M93.47 36.78c-3.718-9.46-15.172-15.135-24.873-11.38-11.074 4.286-7.402 21.9-17.46 25.462-5.434 1.923-13.467-5.264-18.956-6.366-10.116-2.03-19.273 5.107-21.528 14.991-2.3 10.084 1.39 23.553 12.468 26.29 13.198 3.26 20.072-8.604 31.143-12.01 11.361-3.496 23.994 4.76 33.4-5.285 6.948-7.422 9.488-22.332 5.806-31.701z"/>
      <path fill="#000" d="M89.307 40.94c0-6.569-5.325-11.894-11.894-11.894-5.09 0-9.43 3.198-11.128 7.692H23.274c-1.838 0-3.327 1.49-3.327 3.327v36.824c0 1.837 1.49 3.327 3.327 3.327H81.88c1.837 0 3.326-1.49 3.326-3.327V49.922c2.511-2.18 4.101-5.394 4.101-8.981z" opacity=".1"/>
      <path fill="#fff" d="M81.881 76.057H23.275c-1.837 0-3.327-1.489-3.327-3.326V35.907c0-1.837 1.49-3.327 3.327-3.327H81.88c1.837 0 3.326 1.49 3.326 3.327V72.73c0 1.837-1.489 3.326-3.326 3.326z"/>
      <path fill="#fff" d="M85.207 35.907v14.054c-2.087 1.812-4.811 2.91-7.793 2.91-6.57 0-11.895-5.326-11.895-11.894 0-3.277 1.325-6.245 3.469-8.396H81.88c1.836 0 3.326 1.489 3.326 3.326z"/>
      <path fill="#fff" d="M85.207 35.908v4.193l-29.59 22.73c-.868.668-1.947 1.033-3.04 1.033s-2.172-.365-3.04-1.032L19.947 40.1v-4.193c0-1.838 1.49-3.326 3.327-3.326H81.88c1.836 0 3.326 1.488 3.326 3.326z"/>
      <path fill="#fff" d="M53.59 48.444l31.616 24.287c0 1.837-1.489 3.326-3.326 3.326H23.274c-1.838 0-3.327-1.489-3.327-3.326l31.617-24.287c.597-.459 1.428-.459 2.026 0z"/>
      <path fill="#fff" d="M63.968 56.416l-8.351 6.415c-.868.667-1.948 1.033-3.04 1.033-1.093 0-2.172-.366-3.04-1.033l-8.352-6.415 10.379-7.971c.597-.46 1.428-.46 2.026 0l10.378 7.971z"/>
      <path fill="#fff" d="M51.565 60.193L19.948 35.907c0-1.837 1.49-3.327 3.327-3.327H81.88c1.837 0 3.326 1.49 3.326 3.327L53.591 60.193c-.598.46-1.429.46-2.026 0zM71.696 71.632H66.35c-.46 0-.832-.373-.832-.832v-3.684c0-.46.373-.832.832-.832h5.345c.459 0 .831.372.831.831V70.8c0 .46-.372.832-.831.832zM61.87 68.242h-4.63c-.459 0-.83-.373-.83-.832 0-.459.371-.831.83-.831h4.63c.46 0 .832.372.832.831 0 .46-.373.832-.832.832zM61.87 71.632H45.598c-.46 0-.831-.373-.831-.832 0-.46.372-.831.831-.831H61.87c.459 0 .831.372.831.831 0 .46-.372.832-.831.832z"/>
      <path fill="#2ECC71" d="M77.413 48.677c6.57 0 11.895-5.325 11.895-11.894 0-6.57-5.326-11.895-11.895-11.895-6.57 0-11.894 5.326-11.894 11.895s5.325 11.894 11.894 11.894z"/>
      <path fill="#000" d="M77.413 49.509c7.017 0 12.727-5.709 12.727-12.726 0-7.018-5.71-12.726-12.727-12.726s-12.726 5.709-12.726 12.726c0 4.082 1.934 7.719 4.932 10.049L53.084 59.534c-.298.23-.715.23-1.013 0L20.809 35.52c.187-1.193 1.221-2.108 2.465-2.108h38.148c.459 0 .831-.372.831-.832 0-.459-.372-.831-.831-.831H23.274c-2.293 0-4.158 1.865-4.158 4.158V72.73c0 2.293 1.866 4.158 4.158 4.158H81.88c2.293 0 4.158-1.865 4.158-4.158V50.754c0-.46-.372-.832-.831-.832-.46 0-.832.373-.832.832v20.29L62.603 54.319l8.472-6.508c1.868 1.078 4.031 1.698 6.338 1.698zm-34.861 4.81L20.778 71.044v-33.45L42.551 54.32zm41.79 18.797c-.186 1.193-1.217 2.11-2.462 2.11H23.274c-1.244 0-2.276-.917-2.462-2.11l23.105-17.748 7.14 5.485c.448.343.984.515 1.52.515.537 0 1.073-.171 1.52-.515l7.14-5.486 23.105 17.749zM66.35 36.783c0-6.1 4.963-11.063 11.063-11.063 6.1 0 11.063 4.963 11.063 11.063 0 6.1-4.962 11.063-11.063 11.063-6.1 0-11.063-4.963-11.063-11.063z"/>
      <path fill="#fff" d="M82.857 32.036l-6.702 6.983-4.012-3.36c-.682-.155-1.365.218-1.074.585l4.33 5.486c.284.36 1.228.36 1.513 0l7.02-9.109c.29-.367-.393-.74-1.075-.585z"/>
      <path fill="#00ECA2" d="M65.023 24.82c-.618 0-1.235-.234-1.705-.704-.94-.94-.94-2.47 0-3.41s2.47-.941 3.41 0c.94.94.94 2.47 0 3.41-.47.47-1.088.705-1.705.705zm0-3.158c-.192 0-.383.073-.53.22-.291.29-.291.766 0 1.058.293.291.767.291 1.059 0 .292-.292.292-.767 0-1.059-.146-.146-.338-.219-.53-.219zM46.347 87.63c-.617 0-1.235-.236-1.705-.706-.94-.94-.94-2.47 0-3.41s2.47-.94 3.41 0 .94 2.47 0 3.41c-.47.47-1.087.706-1.705.706zm0-3.159c-.191 0-.383.073-.53.219-.291.292-.291.767 0 1.059.293.291.767.291 1.06 0 .291-.292.291-.767 0-1.059-.147-.146-.338-.219-.53-.219z"/>
      <path fill="#FF7EB8" d="M59.027 26.962c-.213 0-.426-.08-.588-.243l-.992-.992-.992.992c-.325.324-.852.324-1.176 0-.325-.325-.325-.852 0-1.176l1.58-1.58c.324-.325.851-.325 1.176 0l1.58 1.58c.325.325.325.851 0 1.176-.163.162-.375.243-.588.243zM83.137 84.47c-.213 0-.426-.08-.588-.243l-.992-.992-.992.992c-.325.325-.851.325-1.176 0-.325-.325-.325-.851 0-1.176l1.58-1.58c.325-.324.852-.324 1.176 0l1.58 1.58c.325.325.325.852 0 1.176-.162.162-.375.244-.588.244z"/>
      <path fill="#6583FE" d="M39.751 85.26c-.212 0-.425-.08-.588-.243l-.992-.992-.992.992c-.324.325-.851.325-1.176 0-.324-.325-.324-.851 0-1.176l1.58-1.58c.325-.324.852-.324 1.176 0l1.58 1.58c.325.325.325.852 0 1.176-.162.163-.375.244-.588.244z"/>
      <defs>
        <linearGradient id="paint0_linear" x1="81.905" x2="42.986" y1="94.836" y2="43.278" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C6D1FF"/>
          <stop offset=".583" stopColor="#E6D5F4"/>
          <stop offset=".997" stopColor="#FFD9EC"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

IconEmailSuccess.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmailSuccess.propTypes = { rootClassName: string, className: string };

export default IconEmailSuccess;
